import React, { useEffect, useState, useRef } from "react";
// import "./style.css";
import { Table } from "../../components/Client/Table";
import { connect, useDispatch } from "react-redux";
import {
  getClientList,
  clearData,
  getFilteredClients,
  addClient,
  tablePagination,
  clientTableData,
  changePaginationData,
  getClientInfoData,
  clientTableStates,
  searchPagination,
  clearClientMultiSearch
} from "../../redux/actions/client";
import { TableRowSelection } from "antd/lib/table/interface";
import Pagination from "../../components/Pagination/Pagination";
import TopSearch from "../../components/Client/TopSearch";
// import { columns, DataType } from './clientTable';
import { DataGrid, GridCallbackDetails, GridColDef, GridColumns, GridValueGetterParams } from '@mui/x-data-grid';
import { clientColumns } from "../../components/Client/TableParts";

interface TableDataType {
  id: number;
  client_name: string;
  website_url: string;
  industry: string;
  payment_terms: string;
  email: string;
  phone: string;
  category: string;
  ownership: string;
  jobTitle: string;
  linkedIn: string;
  city: string;
  state: string;
  country: string;
  notes: string;
  createdAt: string;
  updatedAt: string;
}

interface Props {
  clientTableStates: Function;
  searchPagination: any;
  client: any;
  getClientList: Function;
  getFilteredClients: Function;
  addClient: Function;
  // bannerList: ClientListModel;
  // removeItem: Function;
  clearData: Function;
  tablePagination: Function;
  pagination: any;
  clientTableData: Function;
  getClientInfoData: Function;
  pagiDetails: any;
  pageSize: any;
  changePaginationData: Function;
  refreshTable: any
}

let timeout: any;

function Client(props: Props) {
  const [clientTab, setClientTab] = useState(null);
  const dispatch = useDispatch();
  //
  // const [clientListData, setClientListData] = useState([]);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  // const [sortedField, setSortedField] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [searchField, setSearchField] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const [searchAny, setSearchAny] = useState(false);
  const [pagiNate, setPaginate] = useState({ limit: 10, page: 1 });
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [firstLoad, setFirstLoad] = useState(true);

  const multipleSearchData = props.client.clientTableStatesData.multileSearchTableData;


  const doMasterReset = () =>{
    
  }

  // useEffect(() => {

  //   if (firstLoad) {
  //     dispatch(clearClientMultiSearch())
  //     setFirstLoad(false)
  //   }

  // }, [props.client.masterReset])

  useEffect(() => {

    document.title = 'Clients - Client Module';

    if (firstLoad) {
      dispatch(clearClientMultiSearch())
      setFirstLoad(false)
    }

  }, [])

  useEffect(() => {

    if (searchInput === "") getTableData();

    if (searchInput !== "") submitSearch(searchInput);

    if (props.client.clientTableStatesData?.isFilterOn?.length > 0) {
      props.clientTableStates({
        ...props.client.clientTableStatesData,
        isFilterOn: [],
      })
    }

    // if (multipleSearchData.length === 0) {

    //   if (searchInput === "") getTableData();

    //   if (searchInput !== "") submitSearch(searchInput);
    // } else {
    //   if (searchInput === "") {
    //     getTableData();
    //   }
    // }
    // 

    return () => {
      props.clearData();
    };
  }, [pagiNate, searchInput, searchVal, props.pagiDetails, props.refreshTable]);

  const getTableData = () => {
    if (searchInput == "") {
      // props.searchPagination({}) 
      // tablePagination([])
    }
    props.getClientInfoData(props.client.pagiDetails.pageSize, props.client.pagiDetails.currentPage)
  }


  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          setSearchAny(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const selectSearchField = (value: any, name: any) => {
    setSearchAny(false);
    // setSearchField(fieldVal);
    setSearchVal(value);
  };

  const handleClick = () => {
    if (!searchAny) {
      document.addEventListener("click", handleClick, false);
    } else {
      document.removeEventListener("click", handleClick, false);
    }
    setSearchAny(!searchAny);
  };


  const submitSearch = (value: any) => {
    // value.preventDefault();

    const body = {
      searchBy: searchField,
      searchRecord: searchInput,
      // limit: 10,
      // page: 1,
      per_page: props.client.pagiDetails.pageSize,
      page: props.client.pagiDetails.currentPage
    }
    if (searchInput !== "") {
      props.getFilteredClients(body);
    }
    else {
      props.getFilteredClients(body);
    }
  };

  const [tableData, setTableData] = useState([]);
  const [startIndex, setStartIndex] = useState<any>(1)
  const [hiddenTableColumns, setHiddenTableColumns] = useState(clientColumns);
  const [customPageSize, setCustomPageSize] = useState(10)


  useEffect(() => {

    if (props.client?.hiddenTableColumns.length > 0) {
      setHiddenTableColumns(props.client?.hiddenTableColumns)
    }

    if (multipleSearchData?.length > 0) {
      setTableData(props.client.clientTableData)
    } else {
      setTableData(props.client.clientTableData)
      // setStartIndex(props?.client?.paginationData?.total_perpage * props?.client?.paginationData?.current_page -
      //   props?.client?.paginationData?.total_perpage +
      //   1)
    }
  }, [props.client.clientTableStatesData.multileSearchTableData, props.client.clientTableData, startIndex, props.client.paginationData, props.client.refreshTable, props.client.hiddenTableColumns])

  // 

  return (
    <>
      <div className="wrapper-table">
        <div className="table-boxs">
          <TopSearch props={{ setSearchField, searchVal, setSearchVal, tableData: tableData, wrapperRef, searchInput, setSearchInput, setSearchAny, submitSearch, selectSearchField, setClientTab, clientTab, getTableData, changePaginationData: props.changePaginationData, setCustomPageSize, isSearched, setIsSearched }} />

          <Table props={{
            tableData: tableData,
            paginationData: props?.client?.paginationData,
            clientTableStatesData: props?.client?.clientTableStatesData,
            hiddenTableColumns: hiddenTableColumns,
            searchInput: searchInput,
            isSearched: isSearched,
            setIsSearched: setIsSearched,
          }} />

          <div className="table-footer">
            <Pagination paginationData={props?.client?.paginationData} customPageSize={customPageSize} setCustomPageSize={setCustomPageSize} searchField={searchField} searchInput={searchInput} />
          </div>
        </div>
      </div>
    </>
  );

}
// ({ pageSize: pageSize, currentPage: currentPage }
const mapStateToProps = (state: any) => ({
  client: state.client,
  pagiDetails: state.client.pagiDetails,
  refreshTable: state.client.refreshTable
  // clientTableStatesData: state.clientTableStatesData
});

const mapDispatchToProps = (dispatch: any) => ({
  getClientList: (pagiNate: any) => dispatch(getClientList(pagiNate)),
  addClient: (data: any) => dispatch(addClient(data)),
  getFilteredClients: (data: any, pagiNate: any) =>
    dispatch(getFilteredClients(data, pagiNate)),
  clearData: () => dispatch(clearData()),
  tablePagination: (data: any) => dispatch(tablePagination(data)),
  clientTableData: (data: any) => dispatch(clientTableData(data)),
  changePaginationData: (data: any) => dispatch(changePaginationData(data)),
  getClientInfoData: (pagesize: any, currpage: any) => dispatch(getClientInfoData(pagesize, currpage)),
  clientTableStates: (data: any) => dispatch(clientTableStates(data)),
  searchPagination: (data: any) => dispatch(searchPagination(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Client);