import { Modal, Select } from "antd";
import notification, { NotificationPlacement } from "antd/lib/notification";
import React, { useEffect, useState } from "react";
import { ImNotification } from "react-icons/im";
import { useSelector } from "react-redux";
import Http from "../../../services/http";
import BellAnimation from "../BellAnimation";
import AddNoteFromNoti from "./AddNoteFromNoti";
import EditNote from "./EditNote";
import MarkAsRead from "./MarkAsRead";

const NotificationPopup = ({ dispatch }: any) => {
  const logindata: any = useSelector((state: any) => state.login);
  const refresh: any = useSelector((state: any) => state.contact);

  const [isNotificationOpen, setIsNotificationOpen] = useState(
    logindata.loginMessage
  );
  const [userList, setUserList]: any = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [notiData, setNotiData] = useState([]);
  const [pause, setPause] = useState(true);

  useEffect(() => {
    setIsNotificationOpen(logindata.loginMessage);
  }, []);

  useEffect(() => {
    notiApi();
  }, [refresh.contactRefresh, selectedUsers]);

  const notiApi = async () => {
    const data: any = await Http.post(
      "users/next-follo-update-notification",
      {
        userIds: selectedUsers,
      },
      false
    );
    setNotiData(data.data);
    if (data.data.length !== 0) {
      if (logindata.isLoading) {
      }
    }
    await Http.get("user/user-list?per_page=1000&page=1", false)
      .then((res: any) => {
        setUserList(res?.data?.data);
      })
      .catch((err) => console.log(err));
  };

  const handleuserSelect = (e: any) => {
    setSelectedUsers(e);
  };

  const notiTitle = (
    <div className="noti-title">
      <ImNotification />
      Today's Meeting Alert!
    </div>
  );

  const dataaa: any = localStorage.getItem("user");
  const isAdmin = JSON.parse(dataaa);

  // const openNotificationModal = (data: any, top: any) => {
  //   const content: any = (
  //     <>
  //       <Select
  //         mode="multiple"
  //         allowClear
  //         style={{ width: "100%" }}
  //         placeholder="Select user to see their notification"
  //         defaultValue={selectedUsers}
  //         onChange={(e: any) => handleuserSelect(e)}
  //         options={userList.map((e: any) => {
  //           return {
  //             label: e.name,
  //             value: e.id,
  //           };
  //         })}
  //       />
  //       <div className="noti-popup">
  //         <div className="popup-card">
  //           {data?.length !== 0 ? (
  //             data?.map((e: any, index: any) => {
  //               index += 1;
  //               const timeN: any = e?.next_follow_up_date
  //                 ? new Date(e?.next_follow_up_date)
  //                 : "";
  //               const timeT: any = e?.time
  //                 ? new Date(`2022-10-24,${e?.time}`)
  //                 : "";

  //               if (timeN == "" && timeT == "") {
  //                 return;
  //               }

  //               return (
  //                 <div className="card">
  //                   <div className="popup-name">
  //                     {
  //                       <p>
  //                         {" "}
  //                         {isAdmin?.role === "0" ? (
  //                           <b>{e?.notesCreatedUser?.name}</b>
  //                         ) : (
  //                           "your"
  //                         )}{" "}
  //                         meeting with{" "}
  //                         <b>
  //                           {" "}
  //                           {e?.notesData?.first_name}{" "}
  //                           {e?.notesData?.last_name
  //                             ? e?.notesData?.last_name
  //                             : ""}{" "}
  //                         </b>
  //                       </p>
  //                     }
  //                     {
  //                       <span className="mark-as-read">
  //                         {" "}
  //                         <MarkAsRead dispatch={dispatch} id={e.id} />
  //                       </span>
  //                     }
  //                   </div>
  //                   <div className="popup-time">
  //                     {
  //                       <p>
  //                         {" "}
  //                         Scheduled at{" "}
  //                         <b>
  //                           {new Intl.DateTimeFormat("en-In", {
  //                             year: "numeric",
  //                             month: "2-digit",
  //                             day: "2-digit",
  //                           }).format(timeN)}{" "}
  //                           ,{" "}
  //                           {new Intl.DateTimeFormat("en-In", {
  //                             hour: "2-digit",
  //                             minute: "2-digit",
  //                             second: "2-digit",
  //                           }).format(timeT)}
  //                           , {e?.timezone ? e?.timezone : ""}
  //                         </b>
  //                       </p>
  //                     }
  //                   </div>
  //                   <div className="popup-message">
  //                     {
  //                       <p>
  //                         {" "}
  //                         <b style={{ color: "black" }}> Note: </b> &nbsp;
  //                         &nbsp;<i>{e.notes}</i>
  //                       </p>
  //                     }
  //                   </div>
  //                   <span className="noti-edit">
  //                     <EditNote row={e} dispatch={dispatch} />
  //                     <AddNoteFromNoti />
  //                   </span>
  //                 </div>
  //               );
  //             })
  //           ) : (
  //             <div className="card">
  //               <div className="popup-name">No Meeting Today</div>
  //             </div>
  //           )}
  //         </div>
  //       </div>
  //     </>
  //   );

  //   const openNotification = (placement: NotificationPlacement) => {
  //     notification.info({
  //       message: `Today's Meeting Alert!`,
  //       description: content,
  //       placement,
  //       duration: 500,
  //       onClose: () => {
  //         setIsNotificationOpen(false);
  //       },
  //     });
  //   };
  //   openNotification(top);
  // };

  return (
    <>
      <Modal
        className="notification"
        title={notiTitle}
        open={isNotificationOpen}
        onOk={() => setIsNotificationOpen(false)}
        onCancel={() => setIsNotificationOpen(false)}
      >
        <>
          {isAdmin?.role === "0" ? (
            <div className="select-div">
              <Select
                mode="multiple"
                showSearch
                filterOption={(input: any, option: any) => {
                  return option.label
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                allowClear
                placeholder="Select user to see their notification"
                defaultValue={selectedUsers}
                onChange={(e: any) => handleuserSelect(e)}
                options={userList.map((e: any) => {
                  return {
                    label: e.name,
                    value: e.id,
                  };
                })}
              />
            </div>
          ) : null}

          <div className="noti-popup">
            <div className="popup-card">
              {notiData?.length !== 0 ? (
                notiData?.map((e: any, index: any) => {
                  index += 1;
                  const timeN: any = e?.next_follow_up_date
                    ? new Date(e?.next_follow_up_date)
                    : "";
                  const timeT: any = e?.time
                    ? new Date(`2022-10-24,${e?.time}`)
                    : "";

                  if (timeN == "" && timeT == "") {
                    return;
                  }

                  return (
                    <div className="card">
                      <div className="popup-name">
                        {
                          <p>
                            {" "}
                            {isAdmin?.role === "0" ? (
                              <b>{e?.notesCreatedUser?.name}</b>
                            ) : (
                              "your"
                            )}{" "}
                            meeting with{" "}
                            <b>
                              {" "}
                              {e?.notesData?.first_name}{" "}
                              {e?.notesData?.last_name
                                ? e?.notesData?.last_name
                                : ""}{" "}
                            </b>
                          </p>
                        }
                        {
                          <span className="mark-as-read">
                            {" "}
                            <MarkAsRead dispatch={dispatch} id={e.id} />
                          </span>
                        }
                      </div>
                      <div className="popup-time">
                        {
                          <p>
                            {" "}
                            Scheduled at{" "}
                            <b>
                              {new Intl.DateTimeFormat("en-In", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }).format(timeN)}{" "}
                              ,{" "}
                              {new Intl.DateTimeFormat("en-In", {
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }).format(timeT)}
                              , {e?.timezone ? e?.timezone : ""}
                            </b>
                          </p>
                        }
                      </div>
                      <div className="popup-message">
                        {
                          <p>
                            {" "}
                            <b style={{ color: "black" }}> Note: </b> &nbsp;
                            &nbsp;<i>{e.notes}</i>
                          </p>
                        }
                      </div>
                      <span className="noti-edit">
                        <EditNote row={e} dispatch={dispatch} />
                        <AddNoteFromNoti dispatch={dispatch} data={e} />
                      </span>
                    </div>
                  );
                })
              ) : (
                <div className="card">
                  <div className="popup-name">No Meeting Today</div>
                </div>
              )}
            </div>
          </div>
        </>
      </Modal>
      <span
        className="noti-icon-multi"
        onClick={() => {
          setIsNotificationOpen(true);
        }}
      >
        {notiData?.length === 0 ? (
          ""
        ) : (
          <span className="number">
            {notiData?.length > 9 ? (
              <span style={{ fontSize: "9px" }}>9+</span>
            ) : (
              notiData?.length
            )}
          </span>
        )}
        {<BellAnimation pause={pause} />}
      </span>
    </>
  );
};

export default NotificationPopup;
