import React, { useEffect, useState } from "react";
import {
  AiFillFileText,
  AiOutlineSearch,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { useTable } from "react-table";
import { DatePicker, Pagination } from "antd";
import { BsGraphUp } from "react-icons/bs";
import Attampmanager from "./Charts/Attampmanager";
import Http from "../../../services/http";
import moment from "moment";
import { IoMdCloseCircle } from "react-icons/io";
import AttampTableNote from "./Models/AttampTableNote";
const { RangePicker } = DatePicker;

const columns: any = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Contact Name",
    accessor: "full_name",
  },
  {
    Header: "Ownership",
    accessor: "ContactownerUser",
  },
  {
    Header: "Count",
    accessor: "Count",
  },
];

function AttampManager() {
  const [gridList, setGridList] = useState(true);
  const [searchByUser, setSearchByUser]: any = useState("");
  const [startDate, setStartDate]: any = useState("");
  const [endDate, setEndDate]: any = useState("");
  const [page, setPage]: any = useState("1");
  const [perPage, setPerPage]: any = useState("10");
  const [attempManagerData, setAttempManagerData]: any = useState([]);
  const [attemptPagination, setAttemptPagination]: any = useState([]);
  const [isTableModel, setIsTableModel] = useState(false);
  const [entireData, setEntireData]: any = useState([]);
  const data: any = attempManagerData ? attempManagerData : [];
  const [sortField, setSortField] = useState("")
  const [sortOrder, setOrder] = useState(true);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  useEffect(() => {
    getData();
  }, [searchByUser, page, startDate, endDate, sortField, sortOrder]);

  const getData = async () => {
    const body: any = {
      searchByUser: searchByUser,
      startDate: startDate,
      endDate: endDate,
      page: page,
      perPage: perPage,
      sortField: sortField,
      sortOrder: sortOrder ? "DESC" : "ASC"
    };
    const result: any = await Http.post(
      "reports/attempt-contact-manager",
      body,
      false
    );
    setAttempManagerData(result.data.data);
    setAttemptPagination(result.data.pagination);
    console.log(attemptPagination);
  };

  const getSetDateData = (e: any, data: any) => {
    setStartDate(data[0]);
    setEndDate(data[1]);
    setPage("1");
  };

  const openBox = (e: any) => {
    setIsTableModel(true);
    setEntireData(e);
  };

  return (
    <>
      <AttampTableNote
        entireData={entireData}
        isTableModel={isTableModel}
        setIsTableModel={setIsTableModel}
      />
      <div className="col">
        <div className="upper-bar" style={{ justifyContent: "center" }}>
          <div></div>
          <p className="title">Attempt Manager</p>
          {/* {gridList ? (
            <BsGraphUp
              className="grid-icon-user"
              onClick={() => setGridList(false)}
            />
          ) : (
            <AiOutlineUnorderedList
              className="grid-icon-user"
              onClick={() => setGridList(true)}
            />
          )} */}
        </div>
        {gridList ? (
          <div className="all-table animate__animated animate__flipInY">
            <div className="top-bar-userwise">
              <div className="search-bar-user-wise">
                <AiOutlineSearch
                  className="search-icon-user-wise"
                  style={{
                    fontSize: "20px",
                    color: "#0E4D92",
                    backgroundColor: "#F0F0F0",
                    height: "100%",
                    marginLeft: "10px",
                  }}
                />
                <input
                  type="text"
                  className="search-wise-user"
                  placeholder="Search..."
                  value={searchByUser}
                  onChange={(e: any) => {
                    setSearchByUser(e.target.value);
                    setPage("1");
                  }}
                />
              </div>
              <div className="forCrossIcon" style={{ position: "relative" }}>
                <RangePicker
                  className="pagi-datepicker"
                  ranges={{
                    Today: [moment(), moment()],
                  }}
                  onChange={getSetDateData}
                />
                {startDate !== "" || endDate !== "" ? (
                  <IoMdCloseCircle
                    style={{
                      pointerEvents: "none",
                      color: "#939393",
                      fontSize: "18px",
                      position: "absolute",
                      top: "7px",
                      right: "7px",
                      cursor: "pointer",
                    }}
                  />
                ) : null}
              </div>
            </div>
            <div className="table-of-userwise">
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // <th {...column.getHeaderProps()}>
                        //   {column.render("Header")}
                        // </th>
                        <th {...column.getHeaderProps()}>
                          {column.id !== "Count" && column.id !== "full_name" && column.id !== "ContactownerUser" ? (
                            <>
                              {sortOrder && sortField === column.id ? (
                                <AiOutlineSortDescending
                                  style={{ cursor: "pointer" }}
                                  onClick={() => { setOrder(!sortOrder); setSortField(column.id) }}
                                />
                              ) : (
                                <AiOutlineSortAscending
                                  style={{ cursor: "pointer" }}
                                  onClick={() => { setOrder(!sortOrder); setSortField(column.id) }}
                                />
                              )}
                            </>
                          ) : null}

                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className="tr-for-totalNextFollow"
                      >
                        {row.cells.map((cell: any) => {
                          if (cell.column.id === "full_name") {
                            return (
                              <td
                                {...cell.getCellProps()}
                                style={{ padding: "5px 17px" }}
                              >
                                {cell.row.original.first_name +
                                  " " +
                                  cell.row.original.last_name}
                                {
                                  cell?.row?.original?.attemptManager.length > 0 &&
                                  <AiFillFileText
                                    onClick={() =>
                                      openBox(cell?.row?.original?.attemptManager)
                                    }
                                    className="icon-for-totalNextFollow"
                                    style={{ cursor: "pointer" }}
                                  />
                                }
                              </td>
                            );
                          } else if (cell.column.id === "ContactownerUser") {
                            return (
                              <td
                                {...cell.getCellProps()}
                                style={{ padding: "5px 17px" }}
                              >
                                {cell.row.original?.ContactownerUser?.name}
                              </td>
                            );
                          }
                          else if (cell.column.id === "Count") {
                            return (
                              <td
                                {...cell.getCellProps()}
                                style={{ padding: "5px 17px" }}
                              >
                                {cell?.row?.original?.attemptManager?.length}
                              </td>
                            );
                          }
                          else {
                            return (
                              <td
                                {...cell.getCellProps()}
                                style={{ padding: "5px 17px" }}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="footer-bar-userwise">
              <Pagination
                className="pagi-user-wise"
                current={attemptPagination?.current_page}
                total={attemptPagination?.total_records}
                onChange={(e: any) => setPage(e)}
                showSizeChanger={false}
              />
            </div>
          </div>
        ) : (
          <Attampmanager
            attempManagerData={attempManagerData}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
          />
        )}
      </div>
    </>
  );
}

export default AttampManager;
