import React, { useEffect, useState } from "react";
import exportFromJSON from "export-from-json";
import { Button, Checkbox, Modal } from "antd";
import { access } from "fs";
import Http from "../../services/http";

const ExportModal = () => {
  const [clientData, setClientData] = useState([]);
  const [exportModal, setExportModal] = useState(false);

  useEffect(() => {
    getClientData();
  }, []);

  const getClientData = async () => {
    const result: any = await Http.get(`users/getallclient`, false);
    setClientData(result.list);
  };

  const [clientColumns, setClientColumns] = useState([
    {
      header: "ID",
      accessor: "id", // accessor is the "key" in the data
      isSelected: false,
    },
    {
      header: "Client Name",
      accessor: "client_name",
      isSelected: false,
    },
    {
      header: "Website",
      accessor: "website_url",
      isSelected: false,
    },
    {
      header: "Category",
      accessor: "category",
      isSelected: false,
    },
    {
      header: "Industry",
      accessor: "industry",
      isSelected: false,
    },
    {
      header: "City",
      accessor: "city",
      isSelected: false,
    },
    {
      header: "State",
      accessor: "state",
      isSelected: false,
    },
    {
      header: "Country",
      accessor: "country",
      isSelected: false,
    },
    {
      header: "Payment Terms",
      accessor: "payment_terms",
      isSelected: false,
    },
    {
      header: "OwnerShip",
      accessor: "ClientownersUser",
      isSelected: false,
    },
    {
      header: "Created By",
      accessor: "createUser",
      isSelected: false,
    },
    {
      header: "Created At",
      accessor: "createdAt",
      isSelected: false,
    },
    {
      header: "Updated At",
      accessor: "updatedAt",
      isSelected: false,
    },
    {
      header: "Notes",
      accessor: "notes",
      isSelected: false,
    },
    {
      header: "Client Status",
      accessor: "client_status",
      isSelected: false,
    },
  ]);

  const selectAllColumn = (ele: any) => {
    let newClomnArr: any = [];
    clientColumns.map((e: any) => {
      if (ele.target.checked) {
        if (!e.isSelected) {
          e.isSelected = true;
        } else if (e.isSelected) {
          e.isSelected = true;
        }
      } else if (!ele.target.checked) {
        if (!e.isSelected) {
          e.isSelected = false;
        } else if (e.isSelected) {
          e.isSelected = false;
        }
      }
      newClomnArr.push(e);
    });
    setClientColumns(newClomnArr);
  };

  const date = new Date();
  const dateName = `ClientData_${date.getDate()}-${date.getMonth()}-${date.getFullYear()}/time_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;

  const exportColumn = (ele: any) => {
    let newColumnArr: any = [];
    clientColumns.map((e: any) => {
      if (e.accessor === ele.target.value) {
        e.isSelected = !e.isSelected;
        newColumnArr.push(e);
      } else {
        newColumnArr.push(e);
      }
    });
    setClientColumns(newColumnArr);
  };

  const FilteredData = clientData.map((e: any) => {
    let obj: any = {};
    clientColumns.map((item: any) => {
      if (item.isSelected) {
        if (item.header === "OwnerShip" || item.header === "Created By") {
          obj[item.header] = e[item.accessor]?.name;
        } else {
          obj[item.header] = e[item.accessor];
        }
      }
    });
    return obj;
    // {
    //   client_name: e.client_name,
    //   ownership: e.ClientownersUser?.name,
    //   created_by: e.createUser?.name,
    //   website_url: e.website_url,
    //   city: e.city,
    //   state: e.state,
    //   country: e.country,
    //   industry: e.industry,
    //   payment_terms: e.payment_terms,
    //   client_status: e.client_status,
    //   category: e.category,
    //   createdAt: e.createdAt,
    //   updatedAt: e.updatedAt,
    //   notes: e.notes,
    // };
  });

  const data = FilteredData;
  const fileName = dateName;
  const exportType = "csv";

  const exportToExcel = () => {
    exportFromJSON({ data, fileName, exportType });
    setExportModal(false);
    setClientColumns([
      {
        header: "ID",
        accessor: "id", // accessor is the "key" in the data
        isSelected: false,
      },
      {
        header: "Client Name",
        accessor: "client_name",
        isSelected: false,
      },
      {
        header: "Website",
        accessor: "website_url",
        isSelected: false,
      },
      {
        header: "Category",
        accessor: "category",
        isSelected: false,
      },
      {
        header: "Industry",
        accessor: "industry",
        isSelected: false,
      },
      {
        header: "City",
        accessor: "city",
        isSelected: false,
      },
      {
        header: "State",
        accessor: "state",
        isSelected: false,
      },
      {
        header: "Country",
        accessor: "country",
        isSelected: false,
      },
      {
        header: "Payment Terms",
        accessor: "payment_terms",
        isSelected: false,
      },
      {
        header: "OwnerShip",
        accessor: "ClientownersUser",
        isSelected: false,
      },
      {
        header: "Created By",
        accessor: "createUser",
        isSelected: false,
      },
      {
        header: "Created At",
        accessor: "createdAt",
        isSelected: false,
      },
      {
        header: "Updated At",
        accessor: "updatedAt",
        isSelected: false,
      },
      {
        header: "Notes",
        accessor: "notes",
        isSelected: false,
      },
    ]);
  };

  const openExportModal = () => {
    setExportModal(true);
  };

  return (
    <div className="export-comp">
      <button
        style={{ backgroundColor: "white", color: "#0e4d92" }}
        className="ExportModal"
        type="button"
        onClick={openExportModal}
      >
        Export
      </button>
      <Modal
        destroyOnClose
        title="Contact Export"
        className="export-modal"
        // style={{ width: 1000 }}
        visible={exportModal}
        onOk={() => setExportModal(false)}
        onCancel={() => setExportModal(false)}
      >
        <div className="export-checkbox">
          <div className="selectAll-box">
            <label htmlFor="selectAll">Select All</label>
            <input
              id="selectAll"
              type="checkBox"
              onClick={(ele) => selectAllColumn(ele)}
            />
          </div>
          <div className="scroll-div" style={{height:'300px', overflowY: 'scroll'}}>
            {clientColumns.map((e: any) => {
              return (
                <div
                  style={{
                    padding: "0.4rem",
                    margin: "0.5rem",
                    border: "0px solid #222",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 2px 5px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Checkbox
                    value={e.accessor}
                    checked={e.isSelected}
                    style={{ display: "flex", margin: "0px" }}
                    className="test"
                    onChange={exportColumn}
                  >
                    {e.header}
                  </Checkbox>
                </div>
              );
            })}
          </div>
        </div>
        <div className="exp-btn" style={{justifyContent: 'end', display: 'flex'}}>
          <Button
            className="global-antd-btn export-btn"
            onClick={exportToExcel}
          >
            Export
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default ExportModal;
