import { MinusSquareOutlined, PlusCircleOutlined, PlusSquareOutlined } from '@ant-design/icons';
import React, { useState } from 'react'
import Http from '../../../services/http';
import ClientActionBTn from './ClientActionBTn';
import { clientColumns, contactColumns, ContactTableHead } from '../TableParts';
import { getClientColumnData } from '../../../services/client';
import ClientNotesModal from './ClientNotesModal';
import HyperLinkClient from './HyperLinkClient';
import { useEffect } from 'react';

const ClientMainBody = ({ data, clientDefaultCheckedList }: any) => {
    const [innerRow, SetInnerRow] = useState<{ [key: string]: any }>([]);
    const [selectRow, SetSelectRow] = useState(null);
    const [iconDisplay, setIconDisplay] = useState(false)
    const [iconNumber, setIconNumber] = useState(null)


    const getContactData = (client_id: any) => {
        Http.post("users/getcontact?per_page=100&page=1", {
            client_id: 1,
        }, false).then((res: any) => {
            const { data } = res.data;
            SetInnerRow(data);
        });
    };

    const display = (key: any) => {
        setIconDisplay(true)
        setIconNumber(key)
    }





    return (
        <>
            <tbody style={{ color: "#0E4D92" }} id="clientMainTableBody" >
                {data?.length == 0 ? (
                    <tr>
                        <td colSpan={5} style={{ backgroundColor: "#edeaea", fontWeight: "600" }}>No Data Found</td>
                    </tr>
                ) :
                    (
                        data?.map((row: any, index: any) => {
                            let uniqueIndex = index;
                            return (
                                <>
                                    <tr key={index} className="client-body-tr" onMouseEnter={() => display(index)} onMouseLeave={() => setIconDisplay(false)}>

                                        {
                                            // Object.keys(row).map((key, index) => {
                                            getClientColumnData().map((e: { accessor: any }) => {
                                                let key = e.accessor;
                                                let isShowTd = false;

                                                if (!clientDefaultCheckedList.includes(key)) {
                                                    return (
                                                        <>
                                                        </>
                                                    )
                                                }

                                                if (key === "id") {
                                                    // 
                                                    return (
                                                        <td
                                                            key={index}
                                                            onClick={() => {
                                                                // 

                                                                if (selectRow !== uniqueIndex) {
                                                                    SetSelectRow(null);
                                                                    SetSelectRow(uniqueIndex);
                                                                    getContactData(row[key]);
                                                                } else {
                                                                    getContactData(row[key]);
                                                                    SetSelectRow(null);
                                                                }
                                                            }}
                                                        >
                                                            {selectRow === uniqueIndex ? (
                                                                <>
                                                                    {/* <MinusSquareOutlined /> */}
                                                                    {row[key]}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {/* <PlusSquareOutlined /> */}
                                                                    {row[key]}
                                                                </>
                                                            )}
                                                        </td>
                                                    );
                                                }

                                                if (key === "client_name") {
                                                    return (
                                                        <>
                                                            <td>
                                                                {row?.client_name}
                                                                {<HyperLinkClient item={{ row, iconDisplay, iconNumber, index }} />}
                                                            </td>
                                                        </>
                                                    )
                                                }
                                                if (key === "website_url") {
                                                    const web = () => {
                                                        if (row[key].substring(0, 8) === 'https://') {
                                                            return (
                                                                row[key].substring(8,)
                                                            )
                                                        } else if (row[key].substring(0, 7) === 'http://') {
                                                            return (
                                                                row[key].substring(7,)
                                                            )
                                                        }
                                                        return row[key]
                                                    }
                                                    return (
                                                        <>
                                                            <td>
                                                                <a style={{ color: "#0E4D92" }} href={row[key]} target='_' >{web()}</a>
                                                            </td>
                                                        </>
                                                    )
                                                }
                                                if (key === "action") {
                                                    return (
                                                        <>
                                                            <td style={{ width: '30px' }}>
                                                                <ClientActionBTn row={row} />
                                                            </td>
                                                        </>
                                                    );
                                                }
                                                if (key === "ClientownersUser") {
                                                    return (
                                                        <>
                                                            {row[key]?.name ? (
                                                                <td key={index}>{row[key]?.name}</td>
                                                            ) : (
                                                                <td>--</td>
                                                            )}
                                                        </>
                                                    );
                                                }
                                                if (key === "createUser") {
                                                    return (
                                                        <>
                                                            {row[key]?.name ? (
                                                                <td key={index}>{row[key]?.name}</td>
                                                            ) : (
                                                                <td>--</td>
                                                            )}
                                                        </>
                                                    );
                                                }
                                                if (key === "notes") {

                                                    return (
                                                        <>
                                                            <td key={index} style={{ maxWidth: "200px", overflow: "hidden" }}>{
                                                                row?.ClietnNotes?.length > 0 ?
                                                                    <>
                                                                        {/* Changes 24-03-2023  */}
                                                                        <ClientNotesModal data={row?.ClietnNotes} clientName={row?.client_name} />...
                                                                        {row?.ClietnNotes[row?.ClietnNotes?.length - 1]?.notes}

                                                                    </>
                                                                    :
                                                                    "--"
                                                            }
                                                            </td>
                                                        </>
                                                    )
                                                }
                                                if (e.accessor === "createdAt" || e.accessor === "updatedAt" && row[e.accessor] !== "") {

                                                    const time = new Date(row[e.accessor])

                                                    return (
                                                        <>
                                                            <td key={index}>{new Intl.DateTimeFormat('en-In', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(time)}
                                                            </td>
                                                        </>
                                                    )
                                                }
                                                return (
                                                    <>
                                                        <td key={index}>{row[key] ? row[key] : "--"}</td>
                                                    </>
                                                );
                                            })
                                        }
                                    </tr>

                                    {/* {innerRow && selectRow === uniqueIndex ? (
                                    <td
                                        colSpan={10}
                                        style={{ width: "1000px", paddingLeft: "1rem" }}
                                    >
                                        <table className="inner-table-client">
                                            <ContactTableHead />
                                            <tbody
                                                style={{
                                                    height: "60px",
                                                    overflow: "scroll",
                                                    width: "1000px",
                                                    paddingLeft: "1rem",
                                                }}
                                            >
                                                {innerRow &&
                                                    uniqueIndex === selectRow &&
                                                    innerRow.map((newData: any) => {
                                                        return (
                                                            <>
                                                                <tr>
                                                                    {
                                                                        // Object.keys(newData).map((key, index) => {
                                                                        contactColumns.map((e: any) => {
                                                                            let key = e.accessor;

                                                                            let isShowTd = false;
                                                                            contactColumns.map((col: any) => {
                                                                                if (col.accessor === key) {
                                                                                    isShowTd = true;
                                                                                }
                                                                            });

                                                                            if (isShowTd) {
                                                                                return (
                                                                                    <td key={index}>{newData[key]}</td>
                                                                                );
                                                                            }
                                                                        })
                                                                    }
                                                                </tr>
                                                            </>
                                                        );
                                                    })}
                                            </tbody>
                                        </table>
                                    </td>
                                ) : (
                                    ""
                                )} */}
                                </>
                            );
                        })
                    )
                }
            </tbody>
        </>
    );
};


export default ClientMainBody
