import { Button, Form, message, Modal } from "antd";
import Table from "react-bootstrap/Table";
import React, { useState } from "react";
import { BiCommentDots } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import Http from "../../../services/http";
import { updateClientNewNotes } from "../../../services/client";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch } from "react-redux";
import { refreshTable } from "../../../redux/actions/client";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { confirm } = Modal;

const ClientNotesModal = ({ data, clientName }: any) => {
  const dispatch = useDispatch();
  const [noteModal, setNoteModal] = useState(false);

  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const [isNoteTableModel, setIsNoteTableModel] = useState(false);

  const [itemForUpdate, setItemForUpdate] = useState<any>("");

  const [note, setNote] = useState("");
  const addNote = (item: any) => {
    setItemForUpdate(item);
    setNote(item?.notes);
    setIsNoteOpen(true);
  };

  const clearNoteFields = () => {
    // setIsNoteOpen(false)
    setNote("");
    setItemForUpdate("");
  };
  const saveNotes = () => {
    updateClientNewNotes(itemForUpdate?.id, note)
      .then(({ success = false }: any) => {
        if (success) {
          message.success("Client Note Added Succeessfully", 2);
        } else {
          message.error("Something went wrong, Note does not saved!", 2);
        }
      })
      .catch((e: any) => {
        message.error("Something went wrong, Note does not saved!", 2);
      });
    setIsNoteOpen(false);
    dispatch(refreshTable());
  };

  const deleteNote = (id: any) => {
    confirm({
      title: `Are you sure you want to delete this note?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      className: "clientDeletebtn",
      maskClosable: true,
      async onOk() {
        const result = Http.delete(`users/deleted-client-notes/${id}`);
        dispatch(refreshTable());
      },
      onCancel() {

      },
    });
  };

  const addNotes = (
    <Modal
      title="Edit Note"
      open={isNoteOpen}
      onOk={() => setIsNoteOpen(false)}
      onCancel={() => setIsNoteOpen(false)}
      className="temp_box_3"

    >
      <div className="cForm">
        <Form onFinish={saveNotes}>
          <div className="form-row " style={{ justifyContent: 'center' }}>
            <div className="form-group note-group" style={{ marginBottom: "0px" }}>
              <Form.Item label="Edit Note" required>
                <TextArea
                  className="input-field textarea"
                  // type="text"
                  rows={6}
                  required
                  value={note}
                  onChange={(e: any) => setNote(e.target.value)}
                />
              </Form.Item>
            </div>
          </div>
          <div className="form-row btn-group" style={{ justifyContent: 'center' }}>
            <div
              className="form-group btn-group"
              style={{ marginTop: "0px !important" }}
            >
              <div className="form-btn-group">
                <Form.Item>
                  <Button
                    className="global-antd-btn"
                    type="primary"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                  <Button
                    className="global-antd-btn"
                    onClick={clearNoteFields}
                    type="primary"
                    style={{
                      backgroundColor: "grey",
                      border: "none",
                      marginLeft: "35px",
                    }}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );

  return (
    <>
      <BiCommentDots
        onClick={() => setNoteModal(true)}
        style={{ color: "green", cursor: "pointer" }}
      />
      <Modal
        className="contact-modal-div note-modal"
        destroyOnClose
        title={`All Client Notes Of ${clientName}`} // Changes // 24-03-2023
        visible={noteModal}
        onCancel={() => setNoteModal(false)}
        style={{ fontWeight: "700" }}
      >
        <div className="note-div">
          <Table striped="columns">
            <thead>
              <tr>
                <th>No</th>
                <th>Notes</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Created By</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item: any, index: any) => {
                index += 1;
                const timeA = new Date(item.createdAt);
                const timeU = new Date(item.updatedAt);
                return (
                  <tr>
                    <td>{index}</td>
                    <td>{item?.notes}</td>
                    <td>{new Intl.DateTimeFormat('en-In', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timeA)}</td>
                    <td>{new Intl.DateTimeFormat('en-In', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(timeU)}</td>
                    <td>{item?.clientNotesCreatedUser?.name}</td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "#0e4d92",
                        }}
                      >
                        <FiEdit
                          onClick={() => {
                            addNote(item);
                          }}
                          style={{
                            fontSize: "20px",
                            margin: "0px 2.5px 0px 2.5px",
                            cursor: "pointer",
                          }}
                        />
                        <AiOutlineDelete
                          onClick={() => {
                            deleteNote(item.id);
                          }}
                          style={{
                            fontSize: "22px",
                            margin: "0px 2.5px 0px 2.5px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Modal>
      {addNotes}
    </>
  );
};

export default ClientNotesModal; // Changes 24-03-2023
