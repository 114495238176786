export const firstMenu = [
  {
    id: 1,
    name: "Search Any",
    value: "",
  },
  {
    id: 2,
    name: "Client ID",
    value: "id",
  },
  {
    id: 3,
    name: "Client Name",
    value: "client_name",
  },
  {
    id: 4,
    name: "Client Status",
    value: "client_status",
  },
  {
    id: 5,
    name: "Website Url",
    value: "website_url",
  },
  {
    id: 6,
    name: "Industry",
    value: "industry",
  },
  {
    id: 7,
    name: "Category",
    value: "category",
  },
];

export const secondMenu = [

  {
    id: 1,
    name: "Ownership",
    value: "ownership",
  },
  {
    id: 2,
    name: "City",
    value: "city",
  },
  {
    id: 3,
    name: "State",
    value: "state",
  },
  {
    id: 4,
    name: "Country",
    value: "country",
  },
  {
    id: 5,
    name: "Notes",
    value: "notes",
  },
  {
    id: 6,
    name: "Created By",
    value: "createUser",
  },
];

export const firstActionMenu = [
  {
    name: "Add Note",
    key: "note",
    value: "",
  },
  {
    name: "Add to Folder",
    key: "addFolder",
    value: "",
  },
  {
    name: "Mark as Favorite",
    key: "favorite",
    value: "",
  },
  {
    name: "Send Email",
    key: "email",
    value: "",
  },
  {
    name: "Delete",
    key: "delete",
    value: "",
  },
  {
    name: "Add to spin list",
    key: "spinList",
    value: "",
  },
  {
    name: "Tag to job",
    key: "tagJob",
    value: "",
  },
  {
    name: "Share Profiles",
    key: "shareProfile",
    value: "tag",
  },
  {
    name: "Schedule call",
    key: "scheduleCall",
    value: "",
  },
];

export const secondActionMenu = [
  {
    name: "Add Tag",
    key: "addTag",
    value: "",
  },
  {
    name: "Add to Bench",
    key: "addBench",
    value: "",
  },
  {
    name: "Remove Favorite Tag",
    key: "removeFavorite",
    value: "",
  },
  {
    name: "Submit to job",
    key: "submitJob",
    value: "",
  },
  {
    name: "Change owership",
    key: "changeOwership",
    value: "",
  },
  {
    name: "Add to Group",
    key: "addToGroup",
    value: "",
  },
  {
    name: "Marco",
    key: "marco",
    value: "",
  },
  {
    name: "Share a job",
    key: "shareJob",
    value: "",
  },
  {
    name: "Archive",
    key: "archive",
    value: "",
  },
];

export const mainSearchAny = [
  {
    name: "Job Posting",
    value: "",
  },
  {
    name: "Clients",
    value: "",
  },
  {
    name: "Client/Client Contracts",
    value: "",
  },
  {
    name: "Vendor/Vendor Cortracts",
    value: "",
  },
  {
    name: "TalentBench",
    value: "",
  },
  {
    name: "Lead/Lead Contacts",
    value: "",
  },
  {
    name: "Placements",
    value: "",
  },
];

export const firstAddView = [
  {
    name: "Create View",
    value: "",
  },
];

export const secondAddView = [
  {
    name: "Create Folder",
    value: "",
  },
];

export const listOfColumns = [
  {
    name: "Client ID",
    value: "",
  },
  {
    name: "Client Name",
    value: "",
  },
  {
    name: "Client Last Name",
    value: "",
  },
  {
    name: "Email Address",
    value: "",
  },
  {
    name: "Mobile Number",
    value: "",
  },
  {
    name: "Linkedin Profile URL",
    value: "",
  },
  {
    name: "Work Authorization",
    value: "",
  },
  {
    name: "City",
    value: "",
  },
  {
    name: "State",
    value: "",
  },
  {
    name: "Country",
    value: "",
  },
  {
    name: "Source",
    value: "",
  },
  {
    name: "Experience",
    value: "",
  },
  {
    name: "Client Status",
    value: "",
  },
  {
    name: "Job Title",
    value: "",
  },
  {
    name: "Gender",
    value: "",
  },
];

export const listOfCol2 = [
  "Client ID",
  "Client Name",
  "Email Address",
  "Linkedin Profile URL",
  "Work Authorization",
  "City",
  "State",
  "Country",
  "Source",
  "Experience",
  "Client Status",
  "Created By",
  "Created on",
  "Gender",
];
