import { Button, message, Modal } from 'antd';
import React, { useState } from 'react'
import { AiOutlineDownload } from 'react-icons/ai';
import * as XLSX from "xlsx";
import Http from '../../services/http';
import { CircularProgress } from '@mui/material';
import { contactRefresh } from '../../redux/actions/contacts';
import { useDispatch } from 'react-redux';

const ImportModal = (props: any) => {
  const dispatch = useDispatch();
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState("");
  const [errorStatus, setErrorStatus] = useState([]);
  const [isImportLoaderOn, setIsImportLoaderOn] = useState(false);
  // submit
  const [excelData, setExcelData] = useState(null);
  // it will contain array of objects

  // handle File
  const fileType = ['text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',];
  const handleFile = (e: any) => {

    let selectedFile = e.target.files[0];
    if (selectedFile) {
      // 
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e: any) => {
          setExcelFileError(selectedFile.name);
          setExcelFile(e.target.result);
        }
      }
      else {
        setExcelFileError('Please select only .xls and .csv file');
        setExcelFile(null);
      }
    }
    else {

    }
  }

  // submit function
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: 'buffer' });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data: any = XLSX.utils.sheet_to_json(worksheet);
      const ImpData: any = JSON.stringify(data)
      // const ImpData:any = data

      setIsImportLoaderOn(true)

      const result: any = await Http.post("users/contact-import", ImpData, false)

      setIsImportLoaderOn(false)

      setErrorStatus(result.error);
      setExcelData(ImpData);
      if (result.error.length === 0) {
        props.setIsImportOpen(false)
        message.success(result.message)
      } else {
        message.success(result.message)
      }

      dispatch(contactRefresh());

    }
    else {
      setExcelData(null);
    }
  }

  const downloadTemplate = () => {


  };

  return (
    <>
      <Modal
        destroyOnClose
        title="Contact Import"
        className='importClient'
        // style={{ width: 1000 }}
        visible={props.isImportOpen}
        onOk={() => props.setIsImportOpen(false)}
        onCancel={() => props.setIsImportOpen(false)
        }>
        <div className="container">

          <div className='template_excel'>
            {/* <Button className='global-antd-btn' onClick={() => downloadTemplate()}>Download Template    <AiOutlineDownload style={{fontSize:"22px", marginLeft:"5px", marginTop:"-5px"}} /> </Button> */}
            <a href="/template/contact_import_template.xlsx">Download Template<AiOutlineDownload style={{ fontSize: "22px", marginLeft: "5px", marginTop: "-5px" }} /></a>
          </div>

          <div className='form'>
            <form className='form-group' autoComplete="off"
              onSubmit={handleSubmit}>
              {/* <label><h5>Upload Excel file</h5></label> */}
              <br></br>
              <div className='draganddrop'>
                <label htmlFor="rrr">Drag File Here To Import <br /> OR <br /> <i>  Browse</i> <br /> <span> (only .xls and .csv file allowed )</span> </label>
                <input type='file' id='rrr' className='form-control draganddropinner'
                  onChange={handleFile} required></input>
              </div>
              {excelFileError && <div className={excelFileError === "Please select only .xls and .csv file" ? "text-danger" : 'text-grey'}
                style={{ marginTop: 5 + 'px' }}>

                {
                  isImportLoaderOn ? <CircularProgress /> :
                    excelFileError
                }

              </div>}
              <Button htmlType='submit' className='global-antd-btn import-submit-btn'>Submit</Button>
              {
                errorStatus?.length === 0 ? "" :
                  <div className='error-div'>
                    {errorStatus?.map((item: any, index: any) => {
                      const newIndex: any = index + 1
                      return (
                        <p>{`${newIndex}) ${item}`}</p>
                      );
                    })}
                  </div>
              }
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ImportModal