import { Button, Form, Input, message, Modal, Select } from "antd";
import { RiFileEditLine } from "react-icons/ri";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { contactRefresh } from "../../../redux/actions/contacts";
import { updateContactNewNotes } from "../../../services/contacts";
import "./Edit.css";

const EditNote = ({ dispatch, row }: any) => {
  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const [note, setNote] = useState("");
  const [nextFollowUpdate, setNextFollowUpdate] = useState("");
  const [nextFollowTime, setNextFollowTime] = useState("");
  const [nextFollowZone, setNextFollowZone] = useState([
    "IST",
    "HST",
    "AKST",
    "PST",
    "MST",
    "CST",
    "EST",
  ]);
  const [addedTimeZone, setAddedTimeZone] = useState(nextFollowZone[0]);

  const [selectedStandardComment, setSelectedStandardComment] = useState("");

  const [standardComment, setStandardComment] = useState([
    "Follow-up",
    "Follow-up on a specific Date/Time",
    "Hung-up",
    "Manager with Active Requirements",
    "Connected Manager",
    "Voicemail",
    "DND",
    "Number not in service",
    "Wants to stick with standard Channel",
    "Wrong Number",
    "Handles Offshore Requirements",
    "No number given",
    "Not involved in hiring",
    "Call Later",
    "Call is not going through",
    "No Longer with company",
    "Reference",
  ]);

  const [noteLables, setNoteLables] = useState([]);
  const [selectedNoteLables, setSelectedNoteLables] = useState([]);
  const [noteSource, setnoteSource]: any = useState("");



  useEffect(() => {
    setNote(row?.notes);
    setNoteLables(row?.noteLables?.split(','))
    setNextFollowUpdate(row?.next_follow_up_date);
    setNextFollowTime(row?.time);
    setAddedTimeZone(row?.timezone);
    // setNoteLables(row?.noteLables);
    setnoteSource(row?.noteSource);
  }, [isNoteOpen]);

  const clearNoteFields = () => {
    // setIsNoteOpen(false)
    setNote("");
    setNoteLables([])
    setNextFollowUpdate("");
    setNextFollowTime("");
    setAddedTimeZone(nextFollowZone[0]);
    setSelectedStandardComment("");
  };

  const handleChangeForLabel = (value: any) => {
    setNoteLables(value);
  };

  const updateNotes = () => {

    updateContactNewNotes(
      row?.id,
      note,
      nextFollowUpdate,
      nextFollowTime,
      addedTimeZone,
      noteLables,
      selectedStandardComment,
      noteSource,
    )
      .then(({ success = false }: any) => {
        if (success) {
          message.success("Contact Note Added Successfully", 2);
          setIsNoteOpen(false);
          dispatch(contactRefresh());
        } else {
          message.error("Something went wrong, Note does not saved!", 2);
        }
      })
      .catch((e: any) => {
        message.error("Something went wrong, Note does not saved!", 2);
      });
  };

  const optionsForLable: any = [];
  const lables: any = [
    "Follow-Up",
    "Email Follow-Up",
    "Submission",
    "Interview Scheduled",
    "Interview Done",
    "Introduction",
  ];

  useEffect(() => {
    if (noteLables?.length === 0) return
    setSelectedNoteLables(noteLables)
  }, [noteLables])

  lables.map((lab: any) => {
    optionsForLable.push({
      value: lab,
      label: lab,
    });
  });

  return (
    <>
      <span style={{ cursor: "pointer" }} onClick={() => setIsNoteOpen(true)}>
        <RiFileEditLine style={{ color: "green", fontSize: "18px" }} />
      </span>
      <Modal
        title={`Edit Note For Contact (${row?.notesData?.first_name} ${row?.notesData?.last_name})`}
        open={isNoteOpen}
        onOk={() => setIsNoteOpen(false)}
        onCancel={() => setIsNoteOpen(false)}
      >
        <div className="cForm">
          <Form onFinish={updateNotes}>
            <div className="form-row">
              <div
                className="form-group note-group"
                style={{
                  marginBottom: "0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Form.Item label="Add Note" required>
                  <TextArea
                    required
                    rows={5}
                    className="input-field textarea"
                    value={note}
                    onChange={(e: any) => setNote(e.target.value)}
                  />
                </Form.Item>
                <div
                  className="radio-div"
                  style={{
                    justifyContent: "space-between",
                    padding: "0px 20px",
                    marginTop: "14px",
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #d9d9d9",
                    marginLeft: "5px",
                    height: "120px",
                    width: "226px",
                    borderRadius: "8px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <div>
                    <label htmlFor="incoming" style={{ cursor: "pointer" }}>
                      Incoming
                    </label>{" "}
                    <br />
                    <input
                      type="radio"
                      name="call"
                      id="incoming"
                      style={{ cursor: "pointer" }}
                      checked={noteSource == "incoming" ? true : false}
                      value="incoming"
                      required
                      onChange={(e: any) => setnoteSource(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="outgoing" style={{ cursor: "pointer" }}>
                      Outgoing
                    </label>{" "}
                    <br />
                    <input
                      type="radio"
                      name="call"
                      required
                      id="outgoing"
                      checked={noteSource == "outgoing" ? true : false}
                      value="outgoing"
                      style={{ cursor: "pointer" }}
                      onChange={(e: any) => setnoteSource(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div
                className="form-group"
                style={{ display: "flex", marginBottom: "0px" }}
              >
                <Form.Item
                  label="Next Follow-up Date"
                  style={{ display: "flex", marginBottom: "0px" }}
                >
                  <ul
                    style={{ display: "flex", gap: "7px", marginBottom: "0px" }}
                  >
                    <li>
                      <Input
                        // required
                        style={{
                          marginBottom: "0px",
                          width: "max-content !important",
                        }}
                        className="input-field"
                        type="date"
                        defaultValue=""
                        value={nextFollowUpdate}
                        onChange={(e: any) =>
                          setNextFollowUpdate(e.target.value)
                        }
                      />
                    </li>
                    <li>
                      <Input
                        // required
                        style={{
                          marginBottom: "0px",
                          width: "max-content !important",
                        }}
                        className="input-field"
                        type="time"
                        defaultValue=""
                        value={nextFollowTime}
                        onChange={(e: any) => setNextFollowTime(e.target.value)}
                      />
                    </li>
                    <li>
                      <Select
                        value={addedTimeZone}
                        style={{
                          marginBottom: "0px",
                          minWidth: "90px",
                          maxWidth: "max-content !important",
                        }}
                        onChange={(value: any) => setAddedTimeZone(value)}
                      >
                        {nextFollowZone?.map((option) => {
                          return (
                            <Select.Option
                              selected={option == addedTimeZone ? true : false}
                              value={`${option}`}
                            >
                              {option.toUpperCase()}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </li>
                  </ul>
                </Form.Item>
              </div>
              <div
                className="form-group"
                style={{ display: "flex", marginBottom: "0px" }}
              >
                <Form.Item
                  label="Change Startdard Comment"
                  style={{ display: "flex", marginBottom: "0px", width: "250px" }}
                >
                  <ul
                    style={{ display: "flex", gap: "7px", marginBottom: "0px" }}
                  >
                    <li>
                      <Select
                        showSearch
                        value={selectedStandardComment}
                        style={{
                          marginBottom: "0px",
                          width: "250px",
                          // maxWidth: "max-content !important",
                        }}
                        onChange={(value: any) =>
                          setSelectedStandardComment(value)
                        }
                      >
                        {/* <Select.Option value="" selected={true}>--</Select.Option> */}
                        {standardComment.map((option) => {
                          return (
                            <Select.Option value={`${option}`}>
                              {option.toUpperCase()}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </li>
                  </ul>
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Label"
                  style={{ display: "flex", marginBottom: "0px" }}
                >
                  <ul
                    style={{ display: "flex", gap: "7px", marginBottom: "0px" }}
                  >
                    <li>
                      <Select
                        className="label-scrool2"
                        mode="tags"
                        style={{
                          width: "200px",
                          maxHeight: "50px",
                          overflowY: "scroll",
                        }}
                        onChange={handleChangeForLabel}
                        tokenSeparators={[","]}
                        value={noteLables}
                        options={optionsForLable}
                      />
                    </li>
                  </ul>
                </Form.Item>
              </div>
            </div>
            <div className="form-row btn-group">
              <div
                className="form-group btn-group"
                style={{ marginTop: "0px !important" }}
              >
                <div className="form-btn-group">
                  <Form.Item>
                    <Button
                      className="global-antd-btn"
                      type="primary"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                    <Button
                      className="global-antd-btn"
                      onClick={clearNoteFields}
                      type="primary"
                      style={{
                        backgroundColor: "grey",
                        border: "none",
                        marginLeft: "35px",
                      }}
                    >
                      Clear
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default EditNote;
