import React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "antd";
import { forgotPassword, userLogin } from "../../../redux/actions/login";
import { LoginModel } from "../../../redux/models/index";
import { connect } from "react-redux";
import "./style.css";
import { LockOutlined } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import { UserOutlined } from "@ant-design/icons";
const bgImg = require("../../../assets/Img/bg.png");
const userImg = require("../../../assets/Img/userImg.png");
const MaskImage = require("../../../assets/images/MaskGroup2.png");
const googleImage = require("../../../assets/images/google-logo.png");

interface LoginProps {
  login: LoginModel;
  userLogin: Function;
  forgotPassword: Function;
}

function Login({ login, userLogin, forgotPassword }: LoginProps) {
  const navigate = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const submitLogin = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    var model: any = {};
    model.email = email;
    model.password = password;
    userLogin(model);
  };

  return (
    <>
      <div className="wrapper">
        <div className="signup-block">
          <div className="signup-form-block">
            <div className="signup-left-block">
              <div className="wise-img">
                {/* <img src="img/real-logo.png" width="150" alt="wiseskulls-img" /> */}
                <img src={MaskImage} alt="wiseskulls-img" />
              </div>
            </div>
            <div className="signup-right-block">
              <div className="signup-wrapper">
                <div className="sign-text">
                  <h3>Log in</h3>
                </div>
                <div className="form">
                  <form onSubmit={submitLogin}>
                    <div className="input">
                      <input
                        required
                        placeholder="name@domain.com"
                        type="email"
                        id="email"
                        className="input-btn"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="input">
                      <input
                        required
                        type="password"
                        placeholder="*********"
                        className="input-btn"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    <div className="form-link">
                      <div className="check-box-rem">
                      </div>
                    </div>
                    <Button
                      className="signin-btn"
                      loading={login.isLoading}
                      htmlType="submit"
                    >
                      Log in
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: any) => ({
  login: state.login,
});

const mapDispatchToProps = (dispatch: any) => ({
  userLogin: (data: any) => dispatch(userLogin(data)),
  forgotPassword: (data: any) => dispatch(forgotPassword(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
// export default Login;
