import { Modal } from "antd";
import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { TbFileDatabase } from "react-icons/tb";
import { getClientColumnData } from "../../../services/client";
import { getContactColumnData } from "../../../services/contacts";

const HyperLink = ({item}: any) => {
  const [hyperModal, setHyperModal] = useState(false);



  return (
    <>
      <span
        style={{ cursor: "pointer", opacity: item.iconDisplay && item.iconNumber === item.index ? 1 : 0 }}
        onClick={() => {
          setHyperModal(true);
        }}
      >
        {<TbFileDatabase style={{ marginBottom: "5px" }} />}
      </span>
      <Modal
        destroyOnClose
        title={item?.row?.client_name}
        className="importClient"
        // style={{ width: 1000 }}
        visible={hyperModal}
        onOk={() => setHyperModal(false)}
        onCancel={() => setHyperModal(false)}
      >
        <Table striped="columns"  className="preview-table">
          <thead>
            <tr>
              <th>No</th>
              <th>Accessor</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            {getClientColumnData().map((e: any, index: any) => {
              const AllData =
                e.accessor === "ClientownersUser" || e.accessor === "createUser"
                  ? item?.row?.[e.accessor]?.name
                  : e.accessor === "notes"
                  ? item?.row?.ClietnNotes[0]?.notes
                  : item?.row?.[e.accessor];
              if (e.accessor === "action") {
                return;
              }
              index += 1;
              return (
                <tr>
                  <td>{`${index})`}</td>
                  <td  className="preview-header"> <b> {e.header}</b></td>
                  <td>
                    {AllData === null || AllData === undefined ? "--" : AllData === '' ? '--' : AllData}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal>
    </>
  );
};

export default HyperLink;
