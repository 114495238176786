import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getContactColumnData } from "../../../services/contacts";
import ContactHeadModel from "./ContactHeadModel";

const ContactsTableHead = ({
  tableData,
  page,
  perPage,
  setPerPage,
  setPage,
  searchVal,
  isSearched,
  setIsSearched,
}: any) => {
  const userSettings: any = JSON.parse(
    localStorage.getItem("UserContactSettings") || ""
  );
  const [visibleHeadModel, setVisibleHeadModel] = useState("");

  const isMasterResetStart = isSearched;
  const setIsMasterResetStart = setIsSearched;

  const dispatch = useDispatch();
  return (
    <thead style={{ zIndex: "1" }} className="contact_thead">
      {getContactColumnData()?.map((item: any, index: any) => {
        if (userSettings?.showOnlyThisColumns?.includes(item.accessor)) {
          return (
              <ContactHeadModel
                props={{
                  item,
                  index,
                  tableData,
                  dispatch,
                  page,
                  perPage,
                  setPerPage,
                  setPage,
                  visibleHeadModel,
                  setVisibleHeadModel,
                  searchVal,
                  isMasterResetStart,
                  setIsMasterResetStart,
                }}
                key={index}
              />
          );
        }
      })}
    </thead>
  );
};
export default ContactsTableHead;
