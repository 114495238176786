import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import { Button, Form, Input, message, Modal, Select, Tooltip } from "antd";
import {
  ExclamationCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { MdPostAdd } from "react-icons/md";
import Http from "../../services/http";
import { useDispatch } from "react-redux";
import { contactRefresh } from "../../redux/actions/contacts";
import TextArea from "antd/lib/input/TextArea";
import { addContactNewNotes } from "../../services/contacts";
import PhoneInput from "react-phone-number-input";

const { confirm } = Modal;

const ContactActionBtn = ({ item }: any) => {
  const dispatch = useDispatch();
  const [editContact, setEditContact] = useState(false);
  const [noteSource, setnoteSource]: any = useState("");
  const [conatctList, setConatctList] = useState({
    client_name: item?.clientName?.client_name,
    first_name: item?.first_name,
    last_name: item?.last_name,
    title: item?.title,
    contact_time_zone: item?.contact_time_zone,
    phone_1: item?.phone_1,
    phone_2: item?.phone_2,
    phone_3: item?.phone_3,
    email: item?.email,
    alternate_email: item?.alternate_email,
    city: item?.city,
    state: item?.state,
    country: item?.country,
    time_zone: item?.time_zone,
    unit: item?.unit,
    end_client: item?.end_client,
    skills_support: item?.skills_support,
    last_follow_up_date: item?.last_follow_up_date,
    next_follow_up_date: item?.next_follow_up_date,
    standard_comment: item?.standard_comment,
    sales_comments: item?.sales_comments,
    status: item?.status,
    lead_source: item?.lead_source,
    linkedIn_profile: item?.linkedIn_profile,
    ownership: item?.ContactownerUser?.name,
    additional_notes: item?.additional_notes,
  });

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [ownership, setOwnership] = useState([]);
  const [timeZone, setTimeZone] = useState([
    "IST",
    "HST",
    "AKST",
    "PST",
    "MST",
    "CST",
    "EST",
  ]);
  const [addedTimeZone, setAddedTimeZone] = useState(timeZone[0]);
  // const timeZone = ["IST", "EST", "GST"]
  const [selectedStandardComment, setSelectedStandardComment] = useState("");

  const [standardComment, setStandardComment] = useState([
    "Follow-up",
    "Follow-up on a specific Date/Time",
    "Hung-up",
    "Manager with Active Requirements",
    "Connected Manager",
    "Voicemail",
    "DND",
    "Number not in service",
    "Wants to stick with standard Channel",
    "Wrong Number",
    "Handles Offshore Requirements",
    "No number given",
    "Not involved in hiring",
    "Call Later",
    "Call is not going through",
    "No Longer with company",
    "Reference",
  ]);

  const [leadSourceArray, setLeadSourceArray] = useState([
    "LinkedIn",
    "Zoominfo",
    "Internal Data",
    "Client Reference",
    "Internal Team's Referance",
  ]);
  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const [nextFollowZone, setNextFollowZone] = useState([
    "IST",
    "HST",
    "AKST",
    "PST",
    "MST",
    "CST",
    "EST",
  ]);
  const [note, setNote] = useState("");
  const [nextFollowUpdate, setNextFollowUpdate] = useState("");
  const [nextFollowTime, setNextFollowTime] = useState("");
  const [reportingManager, setReportingManager] = useState(
    item?.ReportingManagerData?.id
  );
  const [reportingManagerFromApi, setReportingManagerFromApi] = useState([]);
  const [reportingManagerLoading, setReportingManagerLoading] = useState(false);

  const [noteLables, setNoteLables] = useState([]);

  const userData = JSON.parse(localStorage.getItem("user")!);

  useEffect(() => {
    if (editContact) getData();
  }, [editContact]);

  const getData = async () => {
    const user: any = await Http.get(
      `user/user-list?per_page=1000&page=1`,
      false
    );
    const ownerships = user.data.data.map((owner: any) => {
      return { name: owner.name, id: owner.id, isActive: owner.isActive };
    });
    //
    setOwnership(ownerships);
    const counrty: any = await Http.get(`users/country_list`, false);
    const countries = counrty.countries.map((item: any) => {
      return { name: item.name, id: item.id };
    });
    //

    setCountry(countries);
    //
    setReportingManagerLoading(true);
    const result: any = await Http.post(
      `users/contact-reporting-manager`,
      { reportingId: item.client_id },
      false
    );
    setReportingManagerFromApi(result.data);
    setReportingManagerLoading(false);
  };

  const countryChange = async (e: any) => {
    const states: any = await Http.post(
      `users/get_states_by_country`,
      {
        country_id: e,
      },
      false
    );
    const state = states.states.map((item: any) => {
      return { name: item.name, id: item.id };
    });
    setState(state);

    const filtredArray: any = country.filter((elem: any) => {
      return elem.id === e;
    });
    setConatctList({ ...conatctList, country: filtredArray[0]?.name });
  };

  const changeState = async (e: any) => {
    const city: any = await Http.post(
      `users/get_cities_by_state`,
      {
        state_id: e,
      },
      false
    );

    const citys = city.city.map((item: any) => {
      return { name: item.name, id: item.id };
    });
    setCity(citys);

    const filterdArray2: any = state.filter((elem: any) => {
      return elem.id == e;
    });

    setConatctList({ ...conatctList, state: filterdArray2[0].name });
  };

  const clearFields = () => {
    setNote("");
    setNextFollowUpdate("");
    setNextFollowTime("");
    setSelectedStandardComment("");
    setConatctList({
      client_name: "",
      first_name: "",
      last_name: "",
      title: "",
      contact_time_zone: "",
      phone_1: "",
      phone_2: "",
      phone_3: "",
      email: "",
      alternate_email: "",
      city: "",
      state: "",
      country: "",
      time_zone: "",
      unit: "",
      end_client: "",
      skills_support: "",
      last_follow_up_date: "",
      next_follow_up_date: "",
      standard_comment: "",
      sales_comments: "",
      status: "",
      lead_source: "",
      linkedIn_profile: "",
      ownership: "",
      additional_notes: "",
    });
    setReportingManager("");
    clearNoteFields();
  };

  const editClientForm = (item: any) => {
    setEditContact(true);
    setConatctList({
      client_name: item?.clientName?.client_name,
      first_name: item?.first_name,
      last_name: item?.last_name,
      title: item?.title,
      contact_time_zone: item?.contact_time_zone,
      phone_1: item?.phone_1,
      phone_2: item?.phone_2,
      phone_3: item?.phone_3,
      email: item?.email,
      alternate_email: item?.alternate_email,
      city: item?.city,
      state: item?.state,
      country: item?.country,
      time_zone: item?.time_zone,
      unit: item?.unit,
      end_client: item?.end_client,
      skills_support: item?.skills_support,
      last_follow_up_date: item?.last_follow_up_date,
      next_follow_up_date: item?.next_follow_up_date,
      standard_comment: item?.standard_comment,
      sales_comments: item?.sales_comments,
      status: item?.status,
      lead_source: item?.lead_source,
      linkedIn_profile: item?.linkedIn_profile,
      ownership: item?.ContactownerUser?.id,
      additional_notes: item?.additional_notes,
    });
  };

  const formSubmit = async () => {
    const result: any = await Http.put(
      `users/contactUpdate/${item.id}`,
      { ...conatctList, reportingManager: reportingManager },
      false
    );
    if (result.success) {
      dispatch(contactRefresh());
      setEditContact(false);
    }
  };

  const deleteAllMessage = () => {
    return (
      <p>
        Are you sure you want to delete{" "}
        <b style={{ color: "red" }}> {item.clientName?.client_name} </b> Contact
        ?
      </p>
    );
  };

  const confirmDelete = () => {
    confirm({
      title: deleteAllMessage(),
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      className: "clientDeletebtn",
      maskClosable: true,
      async onOk() {
        Http.delete(`users/contactDelete/${item.id}`);
        dispatch(contactRefresh());
      },
      onCancel() {},
    });
  };

  const clearNoteFields = () => {
    setSelectedStandardComment("");
    setNote("");
    setNextFollowUpdate("");
    setNoteLables([]);
    setnoteSource("");
    setAddedTimeZone("IST"); // 24-03-2023 //Changes
    setIsNoteOpen(false);
  };

  const saveNotes = () => {
    addContactNewNotes(
      item.id,
      note,
      nextFollowUpdate,
      nextFollowTime,
      addedTimeZone,
      noteLables,
      selectedStandardComment,
      noteSource
    )
      .then(({ success = false }: any) => {
        if (success) {
          message.success("Contact Note Added Succeessfully", 2);
          clearNoteFields();
          dispatch(contactRefresh());
        } else {
          message.error("Something went wrong, Note does not saved!", 2);
        }
      })
      .catch((e: any) => {
        message.error("Something went wrong, Note does not saved!", 2);
      });
  };

  const optionsForLable: any = [];
  const lables: any = [
    "Follow-Up",
    "Email Follow-Up",
    "Submission",
    "Interview Scheduled",
    "Interview Done",
    "Introduction",
  ];

  lables.map((lab: any) => {
    optionsForLable.push({
      value: lab,
      label: lab,
    });
  });

  const handleChangeForLabel = (value: any) => {
    setNoteLables(value);
  };

  const addNotes = (
    <Modal
      title={`Add Note For (${item?.first_name} ${item?.last_name})`} // Changes By Akram For Abnormal Behavior
      open={isNoteOpen}
      onOk={clearNoteFields} // Changes By Akram For Abnormal Behavior
      onCancel={clearNoteFields}
      className="addNotesTeemps"
    >
      <div className="cForm">
        <Form onFinish={saveNotes}>
          <div className="form-row">
            <div
              className="form-group note-group"
              style={{
                marginBottom: "0px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Form.Item label="Add Note" required>
                <TextArea
                  required
                  rows={5}
                  className="input-field textarea"
                  // type="text"
                  value={note}
                  onChange={(e: any) => setNote(e.target.value)}
                />
              </Form.Item>
              <div
                className="radio-div"
                style={{
                  justifyContent: "space-between",
                  padding: "0px 20px",
                  marginTop: "14px",
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #d9d9d9",
                  marginLeft: "5px",
                  height: "120px",
                  width: "226px",
                  borderRadius: "8px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "76px",
                    right: "182px",
                    display: "flex",
                    alignItems: "center",
                    color: "#043c79",
                    fontSize: "14px",
                  }}
                >
                  {" "}
                  <span
                    style={{
                      color: "#ff4d4f",
                      fontSize: "19px",
                      fontFamily: "SimSun, sans-serif",
                      marginTop: "7px",
                      marginRight: "7px",
                    }}
                  >
                    *
                  </span>{" "}
                  Note Source:
                </div>
                <div>
                  <label htmlFor="incoming" style={{ cursor: "pointer" }}>
                    Incoming
                  </label>{" "}
                  <br />
                  <input
                    type="radio"
                    name="call"
                    id="incoming"
                    style={{ cursor: "pointer" }}
                    required
                    checked={noteSource == "incoming" ? true : false}
                    value={"incoming"}
                    onChange={(e: any) => setnoteSource(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="outgoing" style={{ cursor: "pointer" }}>
                    Outgoing
                  </label>{" "}
                  <br />
                  <input
                    type="radio"
                    required
                    name="call"
                    id="outgoing"
                    checked={noteSource == "outgoing" ? true : false}
                    value={"outgoing"}
                    style={{ cursor: "pointer" }}
                    onChange={(e: any) => setnoteSource(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div
              className="form-group"
              style={{ display: "flex", marginBottom: "10px" }}
            >
              <Form.Item
                label="Next Follow-up Date"
                style={{ display: "flex", marginBottom: "0px" }}
                // required
              >
                <ul
                  style={{ display: "flex", gap: "7px", marginBottom: "0px" }}
                >
                  <li>
                    <Input
                      // required
                      className="input-field"
                      style={{
                        marginBottom: "0px",
                        width: "max-content !important",
                      }}
                      type="date"
                      value={nextFollowUpdate}
                      onChange={(e: any) => setNextFollowUpdate(e.target.value)}
                    />
                  </li>
                  <li>
                    <Input
                      // required
                      style={{
                        marginBottom: "0px",
                        width: "max-content !important",
                      }}
                      className="input-field"
                      type="time"
                      value={nextFollowTime}
                      onChange={(e: any) => setNextFollowTime(e.target.value)}
                    />
                  </li>
                  <li>
                    <Select
                      value={addedTimeZone}
                      style={{
                        marginBottom: "0px",
                        minWidth: "90px",
                        maxWidth: "max-content !important",
                      }}
                      onChange={(value: any) => setAddedTimeZone(value)}
                    >
                      {timeZone.map((option) => {
                        return (
                          <Select.Option
                            selected={option == addedTimeZone ? true : false}
                            value={`${option}`}
                          >
                            {option.toUpperCase()}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </li>
                </ul>
              </Form.Item>
            </div>

            <div
              className="form-group"
              style={{ display: "flex", marginBottom: "0px" }}
            >
              <Form.Item
                label="Change Startdard Comment"
                style={{ display: "flex", marginBottom: "0px" }}
              >
                <ul
                  style={{ display: "flex", gap: "7px", marginBottom: "0px" }}
                >
                  <li>
                    <Select
                      showSearch
                      value={selectedStandardComment}
                      style={{
                        marginBottom: "0px",
                        minWidth: "260px",
                        maxWidth: "max-content !important",
                      }}
                      onChange={(value: any) =>
                        setSelectedStandardComment(value)
                      }
                    >
                      <Select.Option value={""}>Select</Select.Option>
                      {standardComment.map((option) => {
                        return (
                          <Select.Option value={`${option}`}>
                            {option.toUpperCase()}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </li>
                  {/* <li>
                    <Select
                      mode="tags"
                      style={{
                        width: "200px",
                      }}
                      onChange={handleChangeForLabel}
                      tokenSeparators={[","]}
                      options={optionsForLable}
                    />
                  </li> */}
                </ul>
              </Form.Item>
              <Form.Item
                label="Label"
                style={{ display: "flex", marginBottom: "0px" }}
              >
                <ul
                  style={{ display: "flex", gap: "7px", marginBottom: "0px" }}
                >
                  <li>
                    <Select
                      className="label-scrool"
                      mode="tags"
                      style={{
                        width: "200px",
                      }}
                      value={noteLables}
                      onChange={handleChangeForLabel}
                      tokenSeparators={[","]}
                      options={optionsForLable}
                    />
                  </li>
                </ul>
              </Form.Item>
            </div>
          </div>
          <div className="form-row btn-group">
            <div
              className="form-group btn-group"
              style={{ marginTop: "0px !important" }}
            >
              <div className="form-btn-group">
                <Form.Item>
                  <Button
                    className="global-antd-btn"
                    type="primary"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                  <Button
                    className="global-antd-btn"
                    onClick={clearFields}
                    type="primary"
                    style={{
                      backgroundColor: "grey",
                      border: "none",
                      marginLeft: "35px",
                    }}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );

  //

  return (
    <>
      <Button
        className="edit-delete-btn edit-btn"
        onClick={() => editClientForm(item)}
      >
        <FiEdit style={{ fontSize: "20px", margin: "0px 2.5px 0px 2.5px" }} />
      </Button>
      {userData.role === "0" && (
        <Button className="edit-delete-btn" onClick={() => confirmDelete()}>
          <AiOutlineDelete
            style={{ fontSize: "25px", margin: "0px 2.5px 0px 2.5px" }}
          />
        </Button>
      )}
      {/* <Tooltip title="Add note" placement="right"> */}
      <Button
        className="add-notes-btn edit-delete-btn"
        onClick={() => setIsNoteOpen(true)}
      >
        <MdPostAdd
          style={{
            cursor: "pointer",
            fontSize: "25px",
            margin: "0px 2.5px 0px 2.5px",
          }}
        />
      </Button>
      {/* </Tooltip> */}
      <Modal
        className="contact-modal-div"
        destroyOnClose
        title={`Edit Contact Of (${conatctList.first_name} ${conatctList.last_name})`}
        visible={editContact}
        onCancel={() => setEditContact(false)}
      >
        <div className="cContactForm">
          <Form onFinish={formSubmit}>
            <div className="form-row">
              <div className="form-group">
                <Form.Item label="Client Name" required>
                  <Input
                    className="input-field"
                    type="text"
                    required
                    value={conatctList.client_name}
                    readOnly={true}
                  />
                </Form.Item>
              </div>
              <div className="form-group">
                <Form.Item label="First Name" required>
                  <Input
                    className="input-field"
                    type="text"
                    required
                    value={conatctList.first_name}
                    onChange={(e: any) => {
                      const result = e.target.value.replace(
                        /[^a-z, 1-9]/gi,
                        ""
                      );
                      setConatctList({
                        ...conatctList,
                        first_name: result,
                      });
                    }}
                  />
                </Form.Item>
              </div>
              <div className="form-group">
                <Form.Item label="Last Name">
                  <Input
                    className="input-field"
                    type="text"
                    value={conatctList.last_name}
                    onChange={(e: any) => {
                      const result = e.target.value.replace(
                        /[^a-z, 1-9]/gi,
                        ""
                      );
                      setConatctList({
                        ...conatctList,
                        last_name: result,
                      });
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <Form.Item label="Ownership" required>
                  <Select
                    value={conatctList?.ownership}
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(e: any) =>
                      setConatctList({ ...conatctList, ownership: e })
                    }
                  >
                    {/* {ownership?.map((item: any) => {
                      return (
                        <Select.Option value={item.id}>
                          {item.name}
                        </Select.Option>
                      );
                    })} */}
                    {ownership.map((item: any) => {
                      if (item?.isActive === 1) {
                        return (
                          <Select.Option required value={item.id}>
                            {item.name}
                          </Select.Option>
                        );
                      }
                    })}
                  </Select>
                </Form.Item>
              </div>

              <div className="form-group">
                <Form.Item label="Contact Time Zone">
                  <Select
                    value={conatctList?.contact_time_zone}
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(e: any) =>
                      setConatctList({ ...conatctList, contact_time_zone: e })
                    }
                  >
                    {nextFollowZone.map((item: any) => {
                      return (
                        <Select.Option required value={item}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>

              <div className="form-group">
                <Form.Item label="Phone Number 1" required>
                  <PhoneInput
                    required
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    maxLength="15"
                    value={conatctList.phone_1}
                    onChange={(val: any) => {
                      setConatctList({
                        ...conatctList,
                        phone_1: val,
                      });
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <Form.Item label="Phone Number 2">
                  <PhoneInput
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    maxLength="15"
                    value={conatctList.phone_2}
                    onChange={(val: any) => {
                      setConatctList({
                        ...conatctList,
                        phone_2: val,
                      });
                    }}
                  />
                </Form.Item>
              </div>
              <div className="form-group">
                <Form.Item label="Phone Number 3">
                  <PhoneInput
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    maxLength="15"
                    value={conatctList.phone_3}
                    onChange={(val: any) => {
                      setConatctList({
                        ...conatctList,
                        phone_3: val,
                      });
                    }}
                  />
                </Form.Item>
              </div>
              <div className="form-group">
                <Form.Item label="Email" required>
                  <Input
                    className="input-field"
                    type="email"
                    required
                    value={conatctList.email}
                    onChange={(e: any) =>
                      setConatctList({ ...conatctList, email: e.target.value })
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <Form.Item label="Alternate Email">
                  <Input
                    className="input-field"
                    type="al_email"
                    value={conatctList.alternate_email}
                    onChange={(e: any) =>
                      setConatctList({
                        ...conatctList,
                        alternate_email: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </div>
              <div className="form-group">
                <Form.Item label="Country">
                  <Select
                    showSearch
                    placeholder="Select"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    value={conatctList.country}
                    onChange={(e: any) => countryChange(e)}
                  >
                    <Select.Option value={""}>Select</Select.Option>
                    {country?.map((item: any) => {
                      //
                      return (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="form-group">
                <Form.Item label="State">
                  <Select
                    showSearch
                    placeholder="Select"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    defaultValue={conatctList.state}
                    onChange={(e: any) => changeState(e)}
                  >
                    <Select.Option value={""}>Select</Select.Option>
                    {state.map((item: any) => {
                      return (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <Form.Item label="City">
                  <Select
                    showSearch
                    placeholder="Select"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    value={conatctList.city}
                    onChange={(e: any) =>
                      setConatctList({ ...conatctList, city: e })
                    }
                  >
                    <Select.Option value={""}>Select</Select.Option>
                    {city.map((item: any) => {
                      return (
                        <Select.Option key={item.id} value={item.name}>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="form-group">
                <Form.Item label="Title">
                  <Input
                    className="input-field"
                    type="text"
                    value={conatctList.title}
                    onChange={(e: any) =>
                      setConatctList({ ...conatctList, title: e.target.value })
                    }
                  />
                </Form.Item>
              </div>
              <div className="form-group">
                <Form.Item label="Unit">
                  <Input
                    className="input-field"
                    type="text"
                    placeholder=""
                    value={conatctList.unit}
                    onChange={(e: any) =>
                      setConatctList({ ...conatctList, unit: e.target.value })
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <Form.Item label="End Client">
                  <Input
                    className="input-field"
                    type="text"
                    placeholder=""
                    value={conatctList.end_client}
                    onChange={(e: any) =>
                      setConatctList({
                        ...conatctList,
                        end_client: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </div>
              <div className="form-group">
                <Form.Item label="Skills Support">
                  <Input
                    className="input-field"
                    type="text"
                    placeholder=""
                    value={conatctList.skills_support}
                    onChange={(e: any) =>
                      setConatctList({
                        ...conatctList,
                        skills_support: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </div>
              <div className="form-group">
                <Form.Item label="Lead Source">
                  <Select
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={conatctList.lead_source}
                    onChange={(e: any) =>
                      setConatctList({ ...conatctList, lead_source: e })
                    }
                  >
                    <Select.Option value={""}>Select</Select.Option>
                    {leadSourceArray.map((item: any) => {
                      return (
                        <Select.Option key={item.id} value={item}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <Form.Item label="LinkedIn Profile">
                  <Input
                    className="input-field"
                    type="url"
                    placeholder="Starts with 'https://'"
                    value={conatctList.linkedIn_profile}
                    onChange={(e: any) =>
                      setConatctList({
                        ...conatctList,
                        linkedIn_profile: e.target.value,
                      })
                    }
                  />
                </Form.Item>
              </div>
              <div className="form-group">
                <Form.Item label="Standard Comment">
                  <Select
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={conatctList.standard_comment}
                    onChange={(e: any) =>
                      setConatctList({ ...conatctList, standard_comment: e })
                    }
                  >
                    <Select.Option value={""}>Select</Select.Option>
                    {standardComment.map((item: any) => {
                      return (
                        <Select.Option key={item.id} value={item}>
                          {item}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div className="form-group">
                <Form.Item label="Reporting Manager">
                  <Select
                    showSearch
                    placeholder="Select"
                    optionFilterProp="children"
                    loading={reportingManagerLoading}
                    // filterOption={(input: any, option: any) =>
                    //   option.children.toLowerCase().includes(input.toLowerCase())
                    // }
                    // filterSort={(optionA, optionB) =>
                    //   optionA.children
                    //     .toLowerCase()
                    //     .localeCompare(optionB.children.toLowerCase())
                    // }
                    value={
                      reportingManager !== item?.ReportingManagerData?.id
                        ? reportingManager
                        : `${
                            item?.ReportingManagerData?.first_name
                              ? item?.ReportingManagerData?.first_name
                              : ""
                          } ${
                            item?.ReportingManagerData?.last_name
                              ? item?.ReportingManagerData?.last_name
                              : "NA"
                          }-(${
                            item?.ReportingManagerData?.email
                              ? item?.ReportingManagerData?.email
                              : ""
                          })`
                    }
                    onChange={(e: any) => setReportingManager(e)}
                  >
                    <Select.Option value={""}>Select</Select.Option>
                    {reportingManagerFromApi?.map((item: any) => {
                      return (
                        <Select.Option
                          key={item.id}
                          value={item.id}
                          title={item.email}
                        >
                          <b>
                            {item.first_name} {item.last_name}
                          </b>
                          &nbsp;-( {item.email} )
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="form-row" style={{ justifyContent: "flex-end" }}>
              <div className="form-row btn-group">
                <div className="form-group btn-group">
                  <div className="form-btn-group">
                    <Form.Item label="">
                      <Button
                        className="global-antd-btn"
                        type="primary"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                      <Button
                        className="global-antd-btn"
                        onClick={clearFields}
                        type="primary"
                        style={{
                          backgroundColor: "grey",
                          border: "none",
                          marginLeft: "35px",
                        }}
                      >
                        Clear
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      {addNotes}
    </>
  );
};

export default ContactActionBtn;
