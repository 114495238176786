import { Button, Form, Modal, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { BiTransfer } from "react-icons/bi";
import * as XLSX from "xlsx";
import Http from "../../../services/http";
import { AiOutlineDownload } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { contactRefresh } from "../../../redux/actions/contacts";

const TrasferOwnership = () => {
  const [isModelTrasfer, setIsModelTrasfer]: any = useState(false);
  const [excelFileName, setExcelFileName]: any = useState("");
  const [excelFile, setExcelFile]: any = useState(null);
  const [sendOwnerId, setSendOwnerId] = useState("");
  const [userList, setUserList] = useState([]);
  const [isImportLoaderOn, setIsImportLoaderOn] = useState(false);
  const [deletedEmail, setDeletedEmail] = useState([]);
  const [isDeleteLoaderOn, setIsDeleteLoaderOn] = useState(false);
  const [fileData, setFileData]: any = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    const result: any = await Http.get(
      `user/user-list?per_page=${1000}&page=${1}`,
      true
    );
    setUserList(result.data.data);
  };

  const readTheFile = (e: any) => {
    // setFileData(e.target.files[0])
    const fileType = [
      "text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    let selectedFile: any = e.target.files[0];

    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader: any = new FileReader();
        reader.readAsArrayBuffer(selectedFile);

        reader.onload = (e: any) => {
          setExcelFileName(selectedFile.name);
          setExcelFile(e.target.result);
        };
      } else {
        setExcelFileName("Please select only .xls and .csv file");
        setExcelFile(null);
      }
    } else {
    }
  };

  const submitForm = async () => {
    if (!excelFile) return message.error("Please select any xls file");
    if (!sendOwnerId)
      return message.error("Please select any one from ownership");

    if (excelFile !== null) {
      const workbook: any = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data: any = XLSX.utils.sheet_to_json(worksheet);

      setIsImportLoaderOn(true);
      const allEmail = data.map((e: any) => e?.email);
      const body: any = {
        email: allEmail,
        ownership: sendOwnerId,
      };
      const result: any = await Http.post(
        "users/bulkOwnershipUpdate",
        body,
        false
      );

      if (result.success) {
        setIsModelTrasfer(false);
        setExcelFile(null);
        setSendOwnerId("");
        dispatch(contactRefresh());
        // const inputEle: any = document.getElementById("bulk-update-file-input-form");
        // inputEle?.reset();
      }

      setIsImportLoaderOn(false);
    }
  };

  const handleBulkDelete = () => {
    if (!excelFile) return message.error("Please select any xls file");
    if (excelFile !== null) {
      const workbook: any = XLSX.read(excelFile, { type: "buffer" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data: any = XLSX.utils.sheet_to_json(worksheet);
      setIsDeleteLoaderOn(true);
      const allEmail = data.map((e: any) => e?.email);
      const body: any = {
        email: allEmail,
      };
      Http.post("users/multipleEmailDelete", body, true)
        .then((res: any) => {
          setIsDeleteLoaderOn(false);
          setDeletedEmail([]);
        })
        .catch((err: any) => {
          setIsDeleteLoaderOn(false);
        });
    }
  };

  return (
    <>
      <BiTransfer
        style={{
          fontSize: "22px",
          marginRight: "5px",
          color: "#0e4d92",
          cursor: "pointer",
        }}
        onClick={() => setIsModelTrasfer(true)}
      />
      <Modal
        title="Bulk Action"
        open={isModelTrasfer}
        onCancel={() => setIsModelTrasfer(false)}
        // style={{height:"300px"}}
      >
        <div className="main-div-trasfer">
          <span>
            <h5
              style={{
                textAlign: "center",
                width: "100%",
                backgroundColor: "gray",
                color: "white",
                borderRadius: "8px",
                fontSize: "20px",
                padding: "5px 0px",
              }}
            >
              Bulk Transfer
            </h5>
          </span>
          <div className="upload-file-trasfer">
            <Form onFinish={submitForm} id="bulk-update-file-input-form">
              <div className="form-group-trasfer">
                <input
                  type="file"
                  // value={fileData}
                  onChange={readTheFile}
                />
              </div>
              <div className="form-group-trasfer" style={{ width: "50%" }}>
                <Form.Item
                  label="Ownership"
                  rules={[
                    { required: true, message: "Please input your username!" },
                  ]}
                  required
                >
                  <Select
                    showSearch
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    value={sendOwnerId}
                    onChange={(e: any) => setSendOwnerId(e)}
                  >
                    {userList.map((item: any) => {
                      if (item.isActive === 1) {
                        return (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        );
                      }
                    })}
                  </Select>
                </Form.Item>
              </div>
              <div
                className="form-group-trasfer"
                style={{ textAlign: "center" }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isImportLoaderOn}
                  style={{
                    background: "#043c79",
                    color: "white",
                    width: "80px",
                    padding: "5px",
                    borderRadius: "4px",
                    textAlign: "center",
                    marginBottom: "15px",
                  }}
                >
                  Submit
                </Button>
                <div style={{ position: "absolute", right: "3%", top: "25%" }}>
                  <div
                    className="download_template"
                    style={{ marginTop: "15px" }}
                  >
                    <a href="/template/bulk-ownership-transfer-template.xlsx">
                      Download Excel Template
                      <AiOutlineDownload
                        style={{
                          fontSize: "22px",
                          marginLeft: "5px",
                          marginTop: "-5px",
                        }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="main-div-trasfer">
          <span>
            <h5
              style={{
                textAlign: "center",
                width: "100%",
                backgroundColor: "gray",
                color: "white",
                borderRadius: "8px",
                fontSize: "20px",
                padding: "5px 0px",
              }}
            >
              Bulk Delete
            </h5>
          </span>
          <div className="upload-file-trasfer">
            <Form.Item
              label="Owner Email"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
              required
            >
              <div className="form-group-trasfer">
                <input
                  type="file"
                  // value={fileData}
                  onChange={readTheFile}
                />
              </div>
            </Form.Item>
            <div className="form-group-trasfer" style={{ textAlign: "center" }}>
              <Button
                type="primary"
                onClick={() => handleBulkDelete()}
                loading={isDeleteLoaderOn}
                style={{
                  background: "rgb(255,77,79)",
                  color: "white",
                  width: "80px",
                  padding: "5px",
                  borderColor: "gray",
                  borderRadius: "4px",
                  textAlign: "center",
                  marginBottom: "15px",
                }}
              >
                Delete
              </Button>
              <div style={{ position: "absolute", right: "3%", bottom: "25%" }}>
                <div
                  className="download_template"
                  style={{ marginTop: "15px" }}
                >
                  <a href="/template/bulk-ownership-delete-template.xlsx">
                    Download Excel Template
                    <AiOutlineDownload
                      style={{
                        fontSize: "22px",
                        marginLeft: "5px",
                        marginTop: "-5px",
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default TrasferOwnership;
