import { loginAPI, forgotAPI } from "../../services/login";
import { setUser } from "./user";
import { message } from "antd";
import { useHistory } from "react-router-dom";

export enum LoginType {
  USER_LOGIN = "USER_LOGIN",
  USER_LOGOUT = "USER_LOGOUT",
  START_USER_LOGIN = "START_USER_LOGIN",
  USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILED = "USER_LOGIN_FAILED",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  START_FORGOT_PASSWORD = "START_FORGOT_PASSWORD",
  FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILED = "FORGOT_PASSWORD_FAILED",
  LOGIN_NOTIFICATION = "LOGIN_NOTIFICATION"
}

const startUserLogin = () => (dispatch: any) => {
  dispatch({
    type: LoginType.START_USER_LOGIN,
  });
};

const userLoginFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: LoginType.USER_LOGIN_FAILED,
    data: { msg },
  });
};

const userLoginSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: LoginType.USER_LOGIN_SUCCESS,
    data,
  });
};

export const userLogin =
  ({ email, password }: any) =>
  async (dispatch: any) => {
    try {
      dispatch(startUserLogin());
      const result: any = await loginAPI({ email, password });
      localStorage.setItem("user", JSON.stringify(result.data));
      localStorage.setItem(
        "access_token",
        JSON.stringify(result.data.access_token)
      );
      localStorage.setItem("dataByOwnership", `{"OwnerUser": false}`);

      localStorage.setItem("isSearchedByOwnership", "false");

      const userSettingKey: any[] = [];

      dispatch(loginNotification())
      setTimeout(() => {
        if (result.success) {
          dispatch(setUser(result.data));
          message.success("Login successfully!");
          const { userSetting } = result?.data;

          userSetting.forEach((setting: any) => {
            localStorage.setItem(setting.setting_name, setting.setting_data);
            // userSettingKey.push(setting.setting_name)
          });
          if (!userSettingKey.includes("UserContactSettings")) {
            localStorage.setItem(
              "UserContactSettings",
              `{ "showOnlyThisColumns": ["id", "clientName", "first_name", "last_name", "title", "ReportingManagerData", "contact_time_zone", "phone_1", "phone_2", "phone_3", "email", "alternate_email", "city", "state", "country", "unit", "end_client", "skills_support", "last_follow_up_date", "next_follow_up_date", "standard_comment", "lead_status", "lead_source", "linkedIn_profile", "ContactownerUser", "ContactcreatUser", "updatedAt", "createdAt", "additional_notes", "action"] }`
            );
          }

          if (!userSettingKey.includes("UserContactColumn")) {
            localStorage.setItem(
              "UserContactColumn",
              `[
          {
            "header": "Contact ID",
            "accessor": "id",
            "isFilter": true
          },
          {
            "header": "Client Name",
            "accessor": "clientName",
            "isFilter": true
          },
          {
            "header": "First Name",
            "accessor": "first_name",
            "isFilter": true
          },
          {
            "header": "Last Name",
            "accessor": "last_name",
            "isFilter": true
          },
          {
            "header": "Title",
            "accessor": "title",
            "isFilter": true
          },
          {
            "header": "Reporting Manager",
            "accessor": "ReportingManagerData",
            "isFilter": false
          },
          {
            "header": "Notes",
            "accessor": "additional_notes",
            "isFilter": false
          },
          {
            "header": "Next Follow-up date",
            "accessor": "next_follow_up_date",
            "isFilter": true
          },
          {
            "header": "Last Follow-up date",
            "accessor": "last_follow_up_date",
            "isFilter": true
          },
          {
            "header": "Contact Time Zone",
            "accessor": "contact_time_zone",
            "isFilter": false
          },
          {
            "header": "Phone 1",
            "accessor": "phone_1",
            "isFilter": true
          },
          {
            "header": "Phone 2",
            "accessor": "phone_2",
            "isFilter": true
          },

          {
            "header": "Phone 3",
            "accessor": "phone_3",
            "isFilter": true
          },
          {
            "header": "Email",
            "accessor": "email",
            "isFilter": true
          },
          {
            "header": "Alternate Email",
            "accessor": "alternate_email",
            "isFilter": true
          },
          {
            "header": "City",
            "accessor": "city",
            "isFilter": true
          },
          {
            "header": "State",
            "accessor": "state",
            "isFilter": true
          },
          {
            "header": "Country",
            "accessor": "country",
            "isFilter": true
          },
          {
            "header": "Unit",
            "accessor": "unit",
            "isFilter": true
          },
          {
            "header": "End Client",
            "accessor": "end_client",
            "isFilter": true
          },
          {
            "header": "Skills Supported",
            "accessor": "skills_support",
            "isFilter": true
          },
          {
            "header": "Standard Comments",
            "accessor": "standard_comment",
            "isFilter": true
          },
          {
            "header": "Lead Status",
            "accessor": "lead_status",
            "isFilter": true
          },
          {
            "header": "Lead Source",
            "accessor": "lead_source",
            "isFilter": true
          },
          {
            "header": "LinkedIn Profile",
            "accessor": "linkedIn_profile",
            "isFilter": true
          },
          {
            "header": "Ownership",
            "accessor": "ContactownerUser",
            "isFilter": true
          },
          {
            "header": "Created On",
            "accessor": "createdAt",
            "isFilter": true
          },
          {
            "header": "Updated On",
            "accessor": "updatedAt",
            "isFilter": true
          },
          {
            "header": "Created By",
            "accessor": "ContactcreatUser",
            "isFilter": true
          },
          {
            "header": "Action",
            "accessor": "action"
          }
        ]`
            );
          }

          if (!userSettingKey.includes("clientDefaultCheckedList")) {
            localStorage.setItem(
              "clientDefaultCheckedList",
              `["id", "client_name", "website_url", "category", "industry", "city", "state", "country", "payment_terms", "client_status", "ClientownersUser", "createUser", "createdAt", "updatedAt", "notes", "action"]`
            );
          }

          if (!userSettingKey.includes("ClientColumns")) {
            localStorage.setItem(
              "ClientColumns",
              `[{ "header": "ID", "accessor": "id", "isFilter": true }, { "header": "Client Name", "accessor": "client_name", "isFilter": true }, { "header": "Website", "accessor": "website_url", "isFilter": true }, { "header": "Category", "accessor": "category", "isFilter": true }, { "header": "Industry", "accessor": "industry", "isFilter": true }, { "header": "City", "accessor": "city", "isFilter": true }, { "header": "State", "accessor": "state", "isFilter": true }, { "header": "Country", "accessor": "country", "isFilter": true }, { "header": "Notes", "accessor": "notes", "isFilter": false }, { "header": "Payment Terms", "accessor": "payment_terms", "isFilter": true }, { "header": "Client Status", "accessor": "client_status", "isFilter": true }, { "header": "OwnerShip", "accessor": "ClientownersUser", "isFilter": true }, { "header": "Created By", "accessor": "createUser", "isFilter": true }, { "header": "Created At", "accessor": "createdAt", "isFilter": true }, { "header": "Updated On", "accessor": "updatedAt", "isFilter": true }, { "header": "Action", "accessor": "action" }]`
            );
          }

          dispatch(userLoginSuccess(result.data));
        } else {
          // 

          message.error(result.message);
          dispatch(userLoginFailed(result.message));
        }
      }, 2300);
    } catch (e: any) {
      // 

      setTimeout(() => {
        message.error(e?.data?.message);
        dispatch(userLoginFailed(e?.data?.message));
      }, 2300);
    }
  };

const loginNotification = () => (dispatch:any) => {
  dispatch ({
    type: LoginType.LOGIN_NOTIFICATION
  })
}   

const startForgotPassword = () => (dispatch: any) => {
  dispatch({
    type: LoginType.FORGOT_PASSWORD,
  });
};

const forgotPasswordFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: LoginType.FORGOT_PASSWORD_FAILED,
    data: { msg },
  });
};

const forgotPasswordSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: LoginType.FORGOT_PASSWORD_SUCCESS,
    data,
  });
};

export const forgotPassword =
  ({ email }: any) =>
  async (dispatch: any) => {
    try {
      // 
      // const history = useHistory();

      dispatch(startForgotPassword());
      const result: any = await forgotAPI({ email });
      // 

      // localStorage.setItem("user", JSON.stringify(result.data));
      // setTimeout(() => {
      if (result.success) {
        // dispatch(setUser(result.data));
        message.success(result.message);
        // history.push('/');
        dispatch(forgotPasswordSuccess(result.data));
      } else {
        // 

        message.error(result.message);
        dispatch(forgotPasswordFailed(result.message));
      }
      // }, 2300);
    } catch (e: any) {
      console.error(e);
      message.error(e.message);
      dispatch(userLoginFailed(e.message));
    }
  };
