import React, { useEffect, useState } from "react";
import {
  AiOutlineSearch,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { useTable } from "react-table";
import { DatePicker, Pagination } from "antd";
import { BsGraphUp } from "react-icons/bs";
import Labelcountchart from "./Charts/Labelcountchart";
import Http from "../../../services/http";
import { useDispatch, useSelector } from "react-redux";
import { getLebelCount } from "../../../redux/actions/report";
import { Label } from "@mui/icons-material";
import moment from "moment";
import { IoMdCloseCircle } from "react-icons/io";
import { LoadingOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;

// const data: any = [
//   {
//     col1: "1",
//     col2: "Anwar Shaikh ",
//     col3: "110",
//   },
//   {
//     col1: "1",
//     col2: "Anwar Shaikh ",
//     col3: "110",
//   },
// ];

const columns: any = [
  {
    Header: "Index",
    accessor: "id",
  },
  {
    Header: "Label",
    accessor: "label",
  },
  {
    Header: "Count",
    accessor: "count",
  },
];

function LabelPlus() {
  const dispatch: any = useDispatch();
  const userData: any = useSelector((state: any) => state.report.userData);
  const lebelCountData: any = useSelector(
    (state: any) => state?.report?.lebelCount
  );
  const [sortOrder, setOrder] = useState(true);
  const [objDate, setObjDate] = useState({
    createdAt: ["", ""],
  });
  const [gridList, setGridList] = useState(true);
  const [datePicker, setDatePicker]: any = useState(["", ""]);
  const [searchData, setSearchData]: any = useState("");
  const [tableData, setTableData] = useState(false);
  const labelData: any = lebelCountData.map((e: any, index: any) => {
    return { id: index + 1, label: e.label, count: e.count };
  });

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: labelData });

  useEffect(() => {
    dispatch(getLebelCount({ createdAt: datePicker, value: searchData, sortOrder:sortOrder ? "DESC" : "ASC", sortField:"noteLables" }));
  }, [datePicker, searchData, sortOrder]);

  useEffect(() => {
    if (lebelCountData?.length !== "0") {
      setTableData(true);
    }
  }, []);

  const dateFun = (e: any, ele: any) => {
    setDatePicker(ele);
  };

  return (
    <>
      <div className="col">
        <div className="upper-bar">
          <div></div>
          <p className="title">Label + Count</p>
          {gridList ? (
            <BsGraphUp
              className="grid-icon-user"
              onClick={() => setGridList(false)}
            />
          ) : (
            <AiOutlineUnorderedList
              className="grid-icon-user"
              onClick={() => setGridList(true)}
            />
          )}
        </div>
        {gridList ? (
          <div className="all-table animate__animated animate__flipInY">
            <div className="top-bar-userwise">
              {/* <div className="search-bar-user-wise">
                <AiOutlineSearch
                  className="search-icon-user-wise"
                  style={{
                    fontSize: "20px",
                    color: "#0E4D92",
                    backgroundColor: "#F0F0F0",
                    height: "100%",
                    marginLeft: "10px",
                  }}
                />
                <input
                  type="text"
                  className="search-wise-user"
                  placeholder="Search..."
                  value={searchData}
                  onChange={(e: any) => setSearchData(e.target.value)}
                />
              </div> */}
              {/* <div className="forCrossIcon" style={{ position: "relative" }}>
                <RangePicker
                  className="pagi-datepicker"
                  ranges={{
                    Today: [moment(), moment()],
                  }}
                  onChange={dateFun}
                />
                {datePicker[0] !== "" || datePicker[1] !== "" ? (
                  <IoMdCloseCircle
                    style={{
                      pointerEvents: "none",
                      color: "#939393",
                      fontSize: "18px",
                      position: "absolute",
                      top: "7px",
                      right: "7px",
                      cursor: "pointer",
                    }}
                  />
                ) : null}
              </div> */}
            </div>
            <div className="table-of-userwise">
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column: any) => {
                        return (
                          <th {...column.getHeaderProps()}>
                            {column.id === "label" ? <>
                            {sortOrder ? (
                              <AiOutlineSortDescending
                                style={{ cursor: "pointer" }}
                                onClick={() => setOrder(!sortOrder)}
                              />
                            ) : (
                              <AiOutlineSortAscending
                                style={{ cursor: "pointer" }}
                                onClick={() => setOrder(!sortOrder)}
                              />
                            )}
                            </> : null}
                            
                            {column.render("Header")}
                          </th>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                {tableData ? (
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                ) : (
                  <table>
                    <tbody>
                      <tr
                        style={{
                          borderBottom: "none",
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <LoadingOutlined
                          style={{
                            fontSize: 35,
                            color: "#0E4D92",
                          }}
                          spin
                        />
                      </tr>
                    </tbody>
                  </table>
                )}
              </table>
            </div>
            <div className="footer-bar-userwise">
              {/* <Pagination
                className="pagi-user-wise"
                defaultCurrent={1}
                total={50}
              /> */}
            </div>
          </div>
        ) : (
          <Labelcountchart
            userData={userData}
            setDatePicker={setDatePicker}
            datePicker={datePicker}
            lebelCountData={lebelCountData}
          />
        )}
      </div>
    </>
  );
}

export default LabelPlus;
