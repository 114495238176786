import React, { useState } from "react";
import Lottie from "lottie-react";
import BellAnimationJ from "../../assets/animations/46-notification-bell-solid-edited (1).json";

const BellAnimation = ({ pause }: any) => {

  // 
  
  return (
    <Lottie
      style={{ height: "30px", width: "30px" }}
      animationData={BellAnimationJ}
      loop={2}
    />
  );
};

export default BellAnimation;
