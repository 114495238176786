import { ExclamationCircleOutlined, PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, message, Modal, Popover, Select, Space } from "antd";
import { Tooltip } from "@mui/material";
import TextArea from "antd/lib/input/TextArea";
import { FiEdit } from "react-icons/fi";
import { MdPostAdd } from "react-icons/md";
import { AiOutlineDelete } from "react-icons/ai";
import React, { useEffect, useRef, useState } from "react";
import Http from "../../../services/http";
import { refreshTable } from "../../../redux/actions/client";
import { useDispatch } from "react-redux";
import "./client_modal.css";
import { addClientNewNotes } from "../../../services/client";
const { confirm } = Modal;
let index = 0;

const ClientActionBTn = ({ row }: any) => {
  const [editClient, setEditClient] = useState(false);
  const [clientList, setClinetList] = useState({
    client_name: row.client_name,
    website_url: row.website_url,
    ownership: row.ownership,
    country: row.country,
    state: row.state,
    city: row.city,
    payment_terms: row.payment_terms,
    category: row.category,
    client_status: row.client_status,
    industry: row.industry,
    notes: row.notes,
    document: "",
  });

  const dispatch = useDispatch();

  const clearFields = () => {
    setClinetList({
      client_name: "",
      website_url: "",
      ownership: "",
      country: "",
      state: "",
      city: "",
      payment_terms: "",
      category: "",
      client_status: "",
      industry: "",
      notes: clientList?.notes,
      document: "",
    });
  };

  const [userList, setUserList] = useState([]);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [industry, setIndustry] = useState(["IT", "Finance", "Retail"]);
  const [name, setName] = useState("");
  const [amount, setAmount] = useState(["Net10", "Net20"]);
  const [cName, setCName] = useState("");
  const [category, setCategory] = useState(["Direct", "Tier 1"]);
  const [cStatusName, setCStatusName] = useState("");
  const [clientStatus, setClientStatus] = useState([
    "Negotiating",
    "Active",
    "Inactive",
  ]);
  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const inputRef = useRef(null);

  const [note, setNote] = useState("");

  useEffect(() => {
    if (editClient) {
      getdata();
    } else {
      // dispatch(refreshTable)
    }
  }, [editClient]);



  const getdata = async () => {
    const data: any = await Http.get(
      "user/user-list?per_page=1000&page=1",
      false
    );
    setUserList(data.data.data);
    const countries: any = await Http.get("users/country_list", false);
    setCountry(countries.countries);
  };

  const onNameChange = (event: any) => {
    setName(event.target.value);
    setCName(event.target.value);
    setCStatusName(event.target.value);
  };

  const addAmount = (e: any) => {
    e.preventDefault();
    setAmount([...amount, name || `New item ${index++}`]);
    setName("");
    setCName("");
  };

  const addCategory = (e: any) => {
    e.preventDefault();
    setCategory([...category, cName || `New item ${index++}`]);
  };

  const addClient = (e: any) => {
    setClientStatus([...clientStatus, cStatusName || `New item ${index++}`]);
  };

  const countryChange = async (e: any) => {
    const states: any = await Http.post(
      "users/get_states_by_country",
      {
        country_id: e,
      },
      false
    );
    setState(states.states);

    const filtredArray: any = country.filter((elem: any) => {
      return elem.id === e;
    });
    setClinetList({ ...clientList, country: filtredArray[0]?.name });
  };

  const changeState = async (e: any) => {
    const city: any = await Http.post(
      "users/get_cities_by_state",
      {
        state_id: e,
      },
      false
    );
    setCity(city.city);

    const filterdArray2: any = state.filter((elem: any) => {
      return elem.id == e;
    });
    setClinetList({ ...clientList, state: filterdArray2[0].name });
  };

  const formSubmit = async (e: any) => {
    Http.put(`users/update_client/${row.id}`, clientList, true).then((res: any) => {
      if (res.success) {
        dispatch(refreshTable());
        setEditClient(false);
      }
    });
  };

  const EditClient = (row: any) => {
    setEditClient(true);
    setClinetList({
      client_name: row.client_name,
      website_url: row.website_url,
      ownership: row.ownership,
      country: row.country,
      state: row.state,
      city: row.city,
      payment_terms: row.payment_terms,
      category: row.category,
      client_status: row.client_status,
      industry: row.industry,
      notes: row.notes,
      document: "",
    });
  };

  const confirmDelete = () => {
    confirm({
      title: `Are you sure you want to delete '${row.client_name}' contact?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      className: "clientDeletebtn",
      maskClosable: true,
      async onOk() {
        const result: any = await Http.delete(`users/delete_client/${row.id}`);
        dispatch(refreshTable());
      },

      onCancel() {
      },
    });
  };

  const clearNoteFields = () => {
    // setIsNoteOpen(false)
    setNote("")
  }

  const saveNotes = () => {
    addClientNewNotes(row.id, note).then(({ success = false }: any) => {
      if (success) {
        message.success("Client Note Added Succeessfully", 2)
        setIsNoteOpen(false)
        setNote("")
        clearNoteFields();
        dispatch(refreshTable())
      } else {
        message.error("Something went wrong, Note does not saved!", 2)
      }
    }).catch((e: any) => {
      message.error("Something went wrong, Note does not saved!", 2)
    })
  }


  const addNotes = (
    <Modal title={`Add Note For (${row.client_name})`} open={isNoteOpen} onOk={() => setIsNoteOpen(false)} onCancel={() => setIsNoteOpen(false)}>
      {/* Changes // 24-03-2023 */}
      <div className="cForm">
        <Form onFinish={saveNotes}>
          <div className="form-row" style={{ justifyContent: 'center' }}>
            <div className="form-group note-group" style={{ marginBottom: "0px" }}>
              <Form.Item label="Add Note" required>
                <TextArea
                  className="input-field textarea"
                  // type="text"
                  required
                  rows={5}
                  value={note}
                  onChange={(e: any) =>
                    setNote(e.target.value)
                  }
                />
              </Form.Item>
            </div>
          </div>
          <div className="form-row btn-group" style={{ justifyContent: 'center' }}>
            <div className="form-group btn-group" style={{ marginTop: "0px !important" }}>
              <div className="form-btn-group">
                <Form.Item>
                  <Button className="global-antd-btn" type="primary" htmlType="submit">
                    Save
                  </Button>
                  <Button
                    className="global-antd-btn"
                    onClick={clearNoteFields}
                    type="primary"
                    style={{
                      backgroundColor: "grey",
                      border: "none",
                      marginLeft: "35px",
                    }}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );

  return (
    <>
      <Button
        className="edit-delete-btn edit-btn"
        onClick={() => {
          EditClient(row);
        }}
      >
        <FiEdit style={{ fontSize: "20px", margin: "0px 2.5px 0px 2.5px" }} />
      </Button>
      {/* <Button onClick={() => confirmDelete()} className="edit-delete-btn">
        <AiOutlineDelete
          style={{ fontSize: "25px", margin: "0px 2.5px 0px 2.5px" }}
        />
      </Button> */}
      {/* <Tooltip title="Add note" placement="right"> */}
      <Button className="add-notes-btn edit-delete-btn" onClick={() => setIsNoteOpen(true)}>
        <MdPostAdd style={{ cursor: "pointer", fontSize: '25px', margin: "0px 2.5px 0px 2.5px" }} />
      </Button>
      {/* </Tooltip> */}
      <Modal
        className="add-client-modal"
        title={`Edit Client ${row?.client_name}`} // Changes // 24-03-2023
        visible={editClient}
        onCancel={() => setEditClient(false)}
      >
        <>
          <div className="cForm">
            <Form onFinish={formSubmit}>
              <div className="form-row">
                <div className="form-group">
                  <Form.Item label="Client" required>
                    <Input
                      required
                      className="input-field"
                      type="text"
                      value={clientList.client_name}
                      onChange={(e) => {
                        const result = e.target.value.replace(/[^a-z, 1-9]/gi, '')
                        setClinetList({
                          ...clientList,
                          client_name: result,
                        })
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item label="Website" required>




                    <Input
                      required
                      className="input-field"
                      type="url"
                      placeholder="https://www.example.com"
                      // pattern="*"
                      value={clientList.website_url}
                      onChange={(e) => {
                        let val = e.target.value.toLowerCase().replace("http://", "https://")
                        if (val == "https:/") {
                          val = "https://"
                        }

                        if (val == "http:/") {
                          val = "http://"
                        }

                        if (!val.includes(`https://`) && val != "") {
                          val = "https://" + e.target.value
                        }

                        setClinetList({
                          ...clientList,
                          website_url: val,
                        }
                        )
                      }
                      }
                    />
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item label="Ownership" required>
                    <Select
                      value={clientList.ownership}
                      showSearch
                      placeholder="Select"
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      onChange={(e) =>
                        setClinetList({ ...clientList, ownership: e })
                      }
                    >
                      {userList?.map((item: any) => {
                        return (
                          <Select.Option value={item.id}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <Form.Item label="Country">
                    <Select
                      showSearch
                      placeholder="Select"
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      value={clientList.country}
                      onChange={(e) => {
                        countryChange(e);
                      }}
                    >
                      <Select.Option value={""}>Select</Select.Option>
                      {country.map((item: any) => {
                        return (
                          <Select.Option value={item.id}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item label="State">
                    <Select
                      showSearch
                      placeholder="Select"
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      value={clientList.state}
                      onChange={(e) => {
                        changeState(e);
                      }}
                    >
                      <Select.Option value={""}>Select</Select.Option>
                      {state.map((item: any) => {
                        return (
                          <Select.Option value={item.id}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item label="City">
                    <Select
                      showSearch
                      placeholder="Select"
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      value={clientList.city}
                      onChange={(e) =>
                        setClinetList({ ...clientList, city: e })
                      }
                    >
                      <Select.Option value={""}>Select</Select.Option>
                      {city.map((item: any) => {
                        return (
                          <Select.Option value={item.name}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <Form.Item label="Payment Terms">
                    <Select
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                      placeholder="Select"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          <Space
                            style={{
                              padding: "0 8px 4px",
                            }}
                          >
                            <Input
                              placeholder="Please enter item"
                              ref={inputRef}
                              value={name}
                              onChange={onNameChange}
                            />
                            <Button
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={addAmount}
                            ></Button>
                          </Space>
                        </>
                      )}
                      value={clientList.payment_terms}
                      onChange={(e) =>
                        setClinetList({ ...clientList, payment_terms: e })
                      }
                    >
                      <Select.Option value={""}>Select</Select.Option>
                      {amount.map((item, index) => (
                        <Select.Option key={index} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item label="Category">
                    <Select
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                      placeholder="Select"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          <Space
                            style={{
                              padding: "0 8px 4px",
                            }}
                          >
                            <Input
                              placeholder="Please enter item"
                              ref={inputRef}
                              value={name}
                              onChange={onNameChange}
                            />
                            <Button
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={addCategory}
                            ></Button>
                          </Space>
                        </>
                      )}
                      value={clientList.category}
                      onChange={(e) =>
                        setClinetList({ ...clientList, category: e })
                      }
                    >
                      <Select.Option value={""}>Select</Select.Option>
                      {category.map((item) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
                <div className="form-group">
                  <Form.Item label="Client Status">
                    <Select
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                      placeholder="Select"
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider
                            style={{
                              margin: "8px 0",
                            }}
                          />
                          <Space
                            style={{
                              padding: "0 8px 4px",
                            }}
                          >
                            <Input
                              placeholder="Please enter item"
                              ref={inputRef}
                              value={name}
                              onChange={onNameChange}
                            />
                            <Button
                              type="text"
                              icon={<PlusOutlined />}
                              onClick={addClient}
                            ></Button>
                          </Space>
                        </>
                      )}
                      value={clientList.client_status}
                      onChange={(e) =>
                        setClinetList({ ...clientList, client_status: e })
                      }
                    >
                      <Select.Option value={""}>Select</Select.Option>
                      {clientStatus.map((item) => (
                        <Select.Option key={item} value={item}>
                          {item}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="form-row two-row-group">
                <div className="form-group">
                  <Form.Item label="Industry">
                    <Select
                      showSearch
                      placeholder="Select"
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children
                          .toLowerCase()
                          .localeCompare(optionB.children.toLowerCase())
                      }
                      value={clientList.industry}
                      onChange={(e) =>
                        setClinetList({ ...clientList, industry: e })
                      }
                    >
                      <Select.Option value={""}>Select</Select.Option>
                      {industry.map((item) => {
                        return (
                          <Select.Option value={item}>{item}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="form-row btn-group">
                <div className="form-group btn-group">
                  <div className="form-btn-group">
                    <Form.Item>
                      <Button className="global-antd-btn" type="primary" htmlType="submit">
                        Submit
                      </Button>
                      <Button
                        className="global-antd-btn"
                        onClick={clearFields}
                        type="primary"
                        style={{
                          backgroundColor: "grey",
                          border: "none",
                          marginLeft: "35px",
                        }}
                      >
                        Clear
                      </Button>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </>
      </Modal>
      {
        addNotes
      }
    </>
  );
};

export default ClientActionBTn;
