import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getClientColumnData } from "../../../services/client";
import { clientColumns } from "../TableParts";
import ClientHeadModel from "./ClientHeadModel";

const ClientTableHead = ({
  clientData,
  clientTableStatesData,
  clientDefaultCheckedList,
  searchInput,
  isSearched,
  setIsSearched,
}: any) => {
  const dispatch = useDispatch();
  const [visibleHeadModel, setVisibleHeadModel] = useState("");
  const isMasterResetStart = isSearched;
  const setIsMasterResetStart = setIsSearched;
  return (
    <thead>
      <tr className="client-th-tr">

        {getClientColumnData()?.map((item: any, index: any) => {
          // if (!hiddenTableColumns?.includes(item.accessor)) {
          // 

          // return(<td>Test</td>)

          // return (
          if (clientDefaultCheckedList.includes(item.accessor)) {
            return (
              <>
                <ClientHeadModel
                  item={item}
                  index={index}
                  clientData={clientData}
                  clientTableStatesData={clientTableStatesData}
                  setVisibleHeadModel={setVisibleHeadModel}
                  visibleHeadModel={visibleHeadModel}
                  dispatch={dispatch}
                  searchInput={searchInput}
                  isMasterResetStart={isSearched}
                  setIsSearched={setIsMasterResetStart}
                />
              </>
            );
          }
        })}
      </tr>
    </thead>
  );
};

export default ClientTableHead;
