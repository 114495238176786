import { ContactsListModal } from "../models";
import { ContactType } from "../actions/contacts";

const contactSearchParams = {
  searchBy: "",
  searchRecord: "",
  id: [],
  clientName: [],
  first_name: [],
  last_name: [],
  title: [],
  phone_1: [],
  phone_2: [],
  phone_3: [],
  email: [],
  alternate_email: [],
  city: [],
  state: [],
  country: [],
  time_zone: [],
  unit: [],
  end_client: [],
  lead_status: [],
  skills_support: [],
  last_follow_up_date: [],
  next_follow_up_date: [],
  standard_comment: [],
  sales_comments: [],
  createdAt: [],
  updatedAt: [],
  status: [],
  lead_source: [],
  linkedIn_profile: [],
  ContactownerUser: [],
  ContactcreatUser: [],
  additional_notes: [],
  isMultiSearch: [],
}

export const initState: ContactsListModal = {
  getAllContact: [],
  getPaginationData: {},
  contactRefresh: true,
  contactTableSearchData: { ...contactSearchParams },
  ContactIsChecked: [],
  checkSelectAll: false,
  pageSize: 10,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
  switch (action.type) {
    case ContactType.GET_ALL_CONTACT: {
      return {
        ...state,
        getAllContact: action.data,
      };
    }

    case ContactType.SEARCH_CONTACTS: {
      // Here is a patch for data object please solved it ASAP.

      return {
        ...state,
        getAllContact: {
          data: { data: action.data },
        },
      };
    }

    case ContactType.CLEAR_CONTACT_REFRESH_SEARCH: {
      return {
        ...state,
        contactTableSearchData: {
          ...state.contactTableSearchData,
          id: [],
          clientName: [],
          first_name: [],
          last_name: [],
          title: [],
          phone_1: [],
          phone_2: [],
          phone_3: [],
          email: [],
          alternate_email: [],
          city: [],
          state: [],
          country: [],
          time_zone: [],
          unit: [],
          end_client: [],
          skills_support: [],
          last_follow_up_date: [],
          next_follow_up_date: [],
          standard_comment: [],
          sales_comments: [],
          lead_status: [],
          createdAt: [],
          updatedAt: [],
          status: [],
          lead_source: [],
          linkedIn_profile: [],
          ContactownerUser: [],
          ContactcreatUser: [],
          additional_notes: [],
          isMultiSearch: [],
        },
      };
    }

    case ContactType.Is_MultiSearch: {
      return {
        ...state,
        contactTableSearchData: {
          ...state.contactTableSearchData,
          isMultiSearch: action.data,
        }
      };
    }

    case ContactType.SET_MUlTI_SEARCH_DATA: {
      // Here is a patch for data object please solved it ASAP.
      return {
        ...state,
        getAllContact: {
          data: action.data,
        },
      };
    }

    case ContactType.CONTACT_TABLE_SEARCH_DATA: {
      return {
        ...state,
        contactTableSearchData: action.data,
      };
    }
    case ContactType.SET_PAGINATION: {
      // 
      return {
        ...state,
        getPaginationData: action.data,
      };
    }

    case ContactType.CONTACT_REFRESH: {
      return {
        ...state,
        contactRefresh: !state.contactRefresh,
      };
    }
    case ContactType.SET_IS_FILTER_ON: {
      return {
        ...state,
        contactTableSearchData: {
          ...state.contactTableSearchData,
          isMultiSearch: action.data,
        }
      };
    }

    case ContactType.SET_PAGE_SIZE: {
      return {
        ...state,
        pageSize: action.data,
      };
    }

    case ContactType.CONTACT_IS_CHECKED: {

      return {
        ...state,
        ContactIsChecked: action.data
      }
    }

    case ContactType.CHECK_SELECT_ALL: {

      return {
        ...state,
        checkSelectAll: action.data
      }
    }

    default:
      return state;

  }
};
