import { AppstoreAddOutlined } from "@ant-design/icons";
import { Button, Checkbox, Modal } from "antd";
import { Tooltip } from "@mui/material";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { contactRefresh } from "../../../redux/actions/contacts";
import { saveUserSetting } from "../../../services/contacts";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./ConatctTopSerach.css";
import SortableList from "./SortableList";
import { actions } from "react-table";
import { AiOutlineDrag } from "react-icons/ai";

const HideContactColumn = () => {
  const [isSelectAllClicked, setIsSelectAllClicked] = useState(false);
  const userSettings: any = JSON.parse(
    localStorage.getItem("UserContactSettings") || ""
  );
  const ContactColumn: any = JSON.parse(
    localStorage.getItem("UserContactColumn") || ""
  );

  const dispatch = useDispatch();
  const defaultCheckedList: any = userSettings?.showOnlyThisColumns;

  const [checkedList, setCheckedList] = useState(defaultCheckedList);
  const [ContactColumns, setContactColumns] = useState(ContactColumn);
  const [dragedContactColumn, setDragedContactColumn] = useState(ContactColumn)

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (checkedList.length != ContactColumns.length) {
      setIsSelectAllClicked(false)
    } else {
      setIsSelectAllClicked(true)
    }
  }, [ContactColumns, checkedList, isModalOpen]);

  const showModal = () => {
    setIsModalOpen(true);
  };
  let showOnlyThisColumns: any = [];

  const handleOk = () => {
    setIsModalOpen(false);
    const settings = {
      ...userSettings,
      showOnlyThisColumns: checkedList,
    };

    saveUserSetting("UserContactSettings", settings);
    saveUserSetting("UserContactColumn", dragedContactColumn);
    dispatch(contactRefresh());
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const selectAll = (e: any) => {
    if (e.target.checked) {
      const allColumns: any = ContactColumns.map((head: any) => head.accessor);
      setCheckedList(allColumns);
      setIsSelectAllClicked(true);
    } else {
      const allColumns: any = ContactColumns.map((head: any) => {
        if (head.accessor == "id" || head.accessor == "action") {
          return head.accessor;
        }
      }).filter((val: any) => val);
      setIsSelectAllClicked(false);
      setCheckedList(allColumns);
    }
  };

  const selectionChanged = (list: any) => {
    if (list.target.value !== "id" || list.target.value !== "action") {
      if (!checkedList.includes(list.target.value)) {
        setCheckedList([...checkedList, list.target.value]);
      } else {
        const temp = checkedList.filter((e: any) => e !== list.target.value);
        setCheckedList(temp);
      }
    }
  };


  function handleOnDragEnd(result: any) {
    if (result.draggableId === "id" || result.draggableId === "action") return;
    if (result.destination.index === 0 || result.destination.index === ContactColumns.length - 1) return;
    if (!result.destination) return;
    const items = Array.from(dragedContactColumn);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setDragedContactColumn(items)
  }

  return (
    <>
      <Tooltip title="Show/Hide Table Columns" placement="top" arrow>
        <AppstoreAddOutlined onClick={showModal} />
      </Tooltip>
      <Modal
        title="Show/Hide Table Columns"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        className="hide-column"
      >
        <Checkbox
          className="select-all"
          onChange={selectAll}
          checked={isSelectAllClicked}
        >
          Select All
        </Checkbox>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="characters">
            {(provided: any) => (
              <ul
                className="characters"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {dragedContactColumn.map(({ header, accessor }: any, index: any) => {

                  const isDragDisabled = accessor == "id" || accessor == "action" ? true : false;

                  const isChecked = checkedList?.includes(accessor)
                    ? true
                    : false;
                  return (
                    <Draggable
                      key={accessor}
                      draggableId={accessor}
                      index={index}
                    >
                      {(provided: any) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >

                          {
                            <div
                              style={{ padding: "0.4rem", margin: "0.5rem", border: "0px solid #222", boxShadow: "rgba(0, 0, 0, 0.35) 0px 2px 5px", display: "flex", justifyContent: "space-between" }}
                            >
                              <Checkbox
                                value={accessor}
                                checked={isChecked}
                                onChange={selectionChanged}
                                disabled={isDragDisabled}
                              >
                                {header}
                              </Checkbox>
                              {
                                isDragDisabled ? '' :

                                  <span style={{ justifySelf: "end", color: "#111" }}>
                                    <AiOutlineDrag />
                                  </span>
                              }
                            </div>
                          }
                        </li>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
        <div className="contact_hide_show" style={{ display: 'flex', justifyContent: 'space-around', marginTop: '0px' }}>
          <Button className="global-antd-btn" onClick={handleOk}>
            Submit
          </Button>
          <Button className="global-antd-btn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default HideContactColumn;
