import { Button, Form, Input, Modal, Select } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Http from "../../../services/http";

const UserModal = (props: any) => {
  const [name, setName] = useState(props.edituserdetails.name)
  const [email, setEmail] = useState(props.edituserdetails.email)
  const [userRole, setUserRole] = useState(props.edituserdetails.role)
  const [number, setNumber] = useState(props.edituserdetails.number)

  useEffect(() => {
    setName(props.edituserdetails.name)
    setEmail(props.edituserdetails.email)
    setUserRole(props.edituserdetails.role)
    setNumber(props.edituserdetails.number)
  }, [props.edituserdetails])

  const [role, setRole] = useState([
    {
      id: "0",
      name: "Admin",
    },
    {
      id: "1",
      name: "Team Lead",
    },
    {
      id: "2",
      name: "Account Manager",
    },
  ]);
  const handleOk = () => {
    props.setEditModal(false);
  };

  const handleCancel = () => {
    props.setEditModal(false);
  };

  const token = localStorage.getItem("access_token") || "";
  const userFormSubmit = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        authorization: JSON.parse(token),
        // "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    Http.put(`user/update_user/${props.edituserdetails.id}`, {
      name: name,
      email: email,
      role: userRole,
      number: number,
    }, true).then(async (result: any) => {
      if (result.success) {
        props.setEditModal(false);
      }
    }).catch((err) => {
      // 
    });
  };

  return (
    <>
      <Modal
        className="editForm"
        title={`Edit User (${name})`} // Changes // 23-03-2023
        visible={props.Editmodal}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
      >
        <div className="user-modal-div-form">
          <Form onFinish={userFormSubmit}>
            <div className="user-form">
              <div className="form-row-user">
                <div className="user-row-div">
                  <Form.Item label="Name">
                    <Input
                      className="input-field"
                      type="text"
                      placeholder="Enter UserName"
                      defaultValue={name}
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value)
                      }}
                    />
                  </Form.Item>
                </div>
                <div className="user-row-div">
                  <Form.Item label="Email">
                    <Input
                      className="input-field"
                      type="email"
                      placeholder="Enter Email ID"
                      defaultValue={email}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="form-row-user">
                <div className="user-row-div">
                  <Form.Item label="Role">
                    <Select
                      defaultValue={
                        props.edituserdetails.role === "0"
                          ? "Admin"
                          : props.edituserdetails.role === "1"
                            ? "Team Lead"
                            : "Account Manager"
                      }
                      value={userRole}
                      onChange={(e: any) => {
                        setUserRole(e)
                      }}
                    >
                      {role.map((item: any) => {
                        return (
                          <Select.Option value={item.id}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div className="user-row-div">
                  <Form.Item label="Phone">
                    <Input
                      className="input-field"
                      type="text"
                      placeholder="Enter Phone Number"
                      defaultValue={number}
                      value={number}
                      onChange={(e) => {
                        setNumber(e.target.value)
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="form-row-user">
                <div className="form-row-user user-btn-group">
                  <Form.Item>
                    <Button className="global-antd-btn" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default UserModal;
