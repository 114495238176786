import React from 'react';
import {Route} from 'react-router-dom';

function PublicRoute(props: any) {
	let { to, path, user, component: Component } = props;
	return (
		<Route path={path} exact component={Component} ></Route>
	);
}

export default PublicRoute;



