import {
  CloseCircleOutlined,
  DownOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "../../pages/client/client.css";
import { SearchOutlined } from "@mui/icons-material";
import { Dropdown, Button, Space, Modal, Upload, message, Switch } from "antd";
import { Tooltip } from "@mui/material";
import { TiExport } from "react-icons/ti";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import ClientFormComponent from "../FormCom/ClientFormComponent";
import ClientContact from "../ClientContact/ClienContact";
import { firstMenu, secondMenu } from "../common/common";
import ImportModal from "./ImportModal";
import ExportModal from "./ExportModal";
import {
  clearClientMultiSearch,
  doMasterReset,
  refreshTable,
} from "../../redux/actions/client";
import ClientHideShowTableColmns from "./ClientTableParts/ClientHideShowTableColmns";
import { clientColumns } from "./TableParts";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineClear } from "react-icons/ai";
import { BiRefresh } from "react-icons/bi";
import { useDetectClickOutside } from "react-detect-click-outside";

const TopSearch = ({ props }: any) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchInputValue, setSearchInputValue] = useState("");

  const [isClientModelVisible, setIsClientModelVisible] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const [clientId, setClientId] = useState("");

  const [showMasterResetBtn, setShowMasterResetBtn] = useState(false);

  const [visibleDropdown, setVisibleDropdown] = useState(false);

  const [visibleReset, setVisibleReset] = useState(false);

  const dispatch = useDispatch();

  const searchedByOwnership: any = localStorage.getItem(
    "isSearchedByOwnership"
  );

  let isSearchedByOwnership: any = false;

  if (searchedByOwnership == "true") {
    isSearchedByOwnership = true;
  } else {
    isSearchedByOwnership = false;
  }

  const isFilterOn: any = useSelector(
    (state: any) => state.client?.clientTableStatesData?.isFilterOn
  );
  const clientTableStatesData: any = useSelector(
    (state: any) => state.client?.clientTableStatesData
  );

  useEffect(() => {
    if (isFilterOn?.length > 0) {
      props.setSearchInput("");
      setSearchInputValue("");
      setSearchTerm("");
      setShowMasterResetBtn(true)
    } else {
      setShowMasterResetBtn(false)
    }
  }, [clientTableStatesData]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      dispatch(clearClientMultiSearch());
      if (searchTerm.length > 0) {
        //   props.submitSearch()
        props.setSearchInput(searchTerm);
      } else {
        props.setSearchInput("");
        // dispatch(refreshTable)
      }
      // Send Axios request here
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const menu = (
    <div className="dropdown-content-any-btn">
      <div className="dropdown-content-list-1">
        <ul>
          {firstMenu.map((ele: any) => {
            return (
              <li key={ele.id}>
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    setVisibleDropdown(!visibleDropdown);
                    props.selectSearchField(ele.value, ele.name);
                    props.setSearchField(ele.value);
                  }}
                >
                  {ele.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="dropdown-content-list-2 dropdown-content-list-bd-lf">
        <ul>
          {secondMenu.map((ele: any) => {
            return (
              <li key={ele.id}>
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    setVisibleDropdown(!visibleDropdown);
                    props.selectSearchField(ele.value, ele.name);
                    props.setSearchField(ele.value);
                  }}
                >
                  {ele.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
  const checkOuterClick = (e: any) => {
    setVisibleDropdown(false);
  };

  const ref: any = useDetectClickOutside({
    onTriggered: checkOuterClick,
    triggerKeys: ["Enter", "Escape"],
  });

  const importMenu = (
    <>
      <ul className="import-export-btn">
        <li>
          {/* <Link to="/client/import">Import</Link> */}
          <ImportModal />
        </li>
        <li>
          <ExportModal />
        </li>
      </ul>
    </>
  );

  const allViews = (
    <div
      className="dropdown-content dw-for-all-views"
      style={{ display: "none" }}
    >
      <div className="dropdown-content-list dw--list-for-all-views">
        <ul>
          <Button type="primary" onClick={() => setIsClientModelVisible(true)}>
            Client
          </Button>
        </ul>
      </div>
      <div className="modal-for-create-view">
        <Modal
          className="add-client-modal"
          destroyOnClose
          title="Add Client"
          style={{ width: 1000 }}
          visible={isClientModelVisible}
          onOk={() => setIsClientModelVisible(false)}
          onCancel={() => setIsClientModelVisible(false)}
          okText="Save"
          cancelText="Cancel"
        >
          {" "}
          <div className="temp-form">
            <ClientFormComponent
              setIsContactModalOpen={setIsContactModalOpen}
              setIsClientModelVisible={setIsClientModelVisible}
              setClientTab={props.setClientTab}
              setClientId={setClientId}
              clientId={clientId}
            />
          </div>
        </Modal>
        <div className="contact-modal">
          <Modal
            className="contact-modal-div"
            destroyOnClose
            visible={isContactModalOpen}
            title="Add Contact"
            onOk={props.handleContactOk}
            onCancel={() => setIsContactModalOpen(false)}
            style={{ width: 1000 }}
          >
            <ClientContact
              setIsModalOpen={setIsContactModalOpen}
              getTableData={props.getTableData}
              setClientTab={props.setClientTab}
              clientTab={props.clientTab}
              setClientId={setClientId}
              clientId={clientId}
            />
          </Modal>
        </div>
      </div>
      <div className="dropdown-content-list dw--list-for-all-views">
        <ul>
          <Button type="primary" onClick={() => setIsContactModalOpen(true)}>
            Contact
          </Button>
        </ul>
      </div>
    </div>
  );

  const onChange = (checked: boolean) => {
    // dispatch(setSearchByOwmership())
    props.setCustomPageSize(10);
    if (checked) {
      localStorage.setItem("isSearchedByOwnership", "true");
      isSearchedByOwnership = true;
    } else {
      localStorage.setItem("isSearchedByOwnership", "false");
      isSearchedByOwnership = false;
    }
    dispatch(clearClientMultiSearch());
    dispatch(refreshTable());
    // window.location.reload();
  };

  const masterReset = () => {
    props.setSearchInput("");
    props.setSearchVal("");
    setSearchInputValue("");
    setSearchTerm("");
    props.setIsSearched(!props.isSearched);
    dispatch(doMasterReset());
    dispatch(refreshTable());
  };

  return (
    <div className="search-bar">
      <div className="search-bar-left">
        <div className="search-any-btn" ref={props.wrapperRef}>
          <form
            ref={ref}
            className="input-box"
            onSubmit={(e) => e.preventDefault()}
          >
            <SearchOutlined
              style={{
                fontSize: "35px",
                color: "#0e4d92",
                paddingLeft: "15px",
              }}
            />
            <input
              type="text"
              name="search"
              // tabIndex={1}
              // defaultValue=""
              value={searchInputValue}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setSearchInputValue(e.target.value);
                // props.submitSearch
              }}
              // onChange={(e) => setSearchTerm(e.target.value)}
              // onKeyUp={props.submitSearch}

              placeholder="Search Client"
              required
            />
            {props.searchInput !== "" && (
              <CloseCircleOutlined
                onClick={(e: any) => {
                  props.selectSearchField("");
                  // setSearchTerm("")
                  props.setSearchInput("");
                  setSearchInputValue("");

                  props.selectSearchField("", "");
                  props.setSearchField("");
                  // props.getClientList({limit:10, page:1});
                  props.changePaginationData({
                    currentPage: 1,
                    pageSize: 10,
                  });
                  // props.submitSearch(e);
                }}
                className="filter-btn"
              />
            )}
            <Dropdown overlay={menu} visible={visibleDropdown}>
              <Button
                onClick={() => setVisibleDropdown(!visibleDropdown)}
                style={{ textTransform: "capitalize" }}
              >
                <Space onClick={() => setVisibleDropdown(!visibleDropdown)}>
                  {/* {props.searchVal
                    ? props.searchVal.replace("_", " ")
                    : "Search Any"} */}

                  {props.searchVal == "createUser"
                    ? "Created By"
                    : props.searchVal === "id"
                      ? "Client ID"
                      : props.searchVal
                        ? props.searchVal.replace("_", " ")
                        : "Search Any"}

                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </form>
        </div>
      </div>

      <div className="top-search-group">
        <>
          {showMasterResetBtn ? (
            <Tooltip title={"Reset All Filters"} placement="top" arrow>
              <div className="reset_btn_div">
                <BiRefresh
                  onClick={() => {
                    masterReset();
                  }}
                  style={{
                    display: "flex",
                    color: "#0E4D92",
                    fontSize: "1.45rem",
                    // paddingBottom: "6px",
                    margin: "5px 0.7rem 0rem 0rem",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            ""
          )}
        </>

        <div className="button-right">
          {/* <Tooltip title="Import/Export Excel" placement="top" arrow> */}
          <div className="top-three-btn">
            <Dropdown
              className="first-of-top-three"
              overlay={importMenu}
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <div
                    className="import-export-deopdown"
                    style={{ gap: "none" }}
                  >
                    <TiExport style={{ color: "#0e4d92", fontSize: "20px" }} />
                    <ExpandMoreIcon style={{ color: "#0e4d92" }} />
                  </div>
                </Space>
              </a>
            </Dropdown>
          </div>
          {/* </Tooltip> */}
        </div>
        <div className="clients-btn">
          <>
            {allViews}
            <div>
              <Tooltip title="Add Client" placement="top" arrow>
                <button
                  className="th-title-btn"
                  onClick={() => setIsClientModelVisible(true)}
                >
                  New
                </button>
              </Tooltip>
            </div>
          </>
        </div>
        <div
          className="clients-btn"
          style={{
            display: "flex",
            color: "#0E4D92",
            fontSize: "1.35rem",
            paddingBottom: "6px",
            margin: "6px 12px 0rem 0px",
            alignItems: "center",
          }}
        >
          <ClientHideShowTableColmns dispatch={dispatch} />
        </div>
        <Tooltip
          title={!isSearchedByOwnership ? "Show All Data" : "Show Owner Data"}
          placement="top"
          arrow
        >
          <Switch
            style={{
              display: "flex",
              color: "#0E4D92",
              fontSize: "1.35rem",
              paddingBottom: "6px",
              margin: "0rem 2rem 0rem 0.1rem",
              alignItems: "center",
            }}
            checked={isSearchedByOwnership}
            onChange={onChange}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default TopSearch;