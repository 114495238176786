import './style.css';
import React, { useState } from 'react';
import { queryAllByAltText } from '@testing-library/react';
import { Router, useLocation, useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
// import { message } from 'antd';
import { Button, message } from 'antd';
const MaskImage = require("../../../assets/images/MaskGroup2.png")

function Resetpassword() {
    const navigate = useHistory();
    const [password, setPassword] = useState('');
    const [cnfPassword, setCnfPassword] = useState('');
    const [isPassMatch, setIsPassMatch] = useState(true);
    const search = useLocation().search;

    const checkPassword = () => {

        if (password !== cnfPassword) {
            message.error('Password not matched');
            setIsPassMatch(false);
        } else {
            setIsPassMatch(true);
        }
    }

    const resetPassword = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        const token = new URLSearchParams(search).get("token");
        const data = {
            password,
            token: token
        }
        // 
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        fetch('https://api.wiseskulls.com/api/users/resetPassword', requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.success === true) {
                    // toast.success('Password reset successfully', {
                    //     position: "top-center"
                    // });
                    message.success('Password reset successfully');
                    setTimeout(() => {
                        navigate.push('/');
                    }, 1000);
                } else {
                    message.error(data.message)
                    // toast.error(data.error, {
                    //     position: "top-center"
                    // });
                }
            });
    }

    return (
        <div className="wrapper">
            <div className="signup-block">
                <div className="signup-form-block">
                    <div className="signup-left-block">
                        <div className="wise-img">
                            <img src={MaskImage} alt="wiseskulls-img" />
                        </div>
                        <div className="ats-text">
                            <p>ATS</p>
                        </div>
                    </div>
                    <div className="signup-right-block">
                        <div className="signup-wrapper">
                            <div className="sign-text sign-text-bold">
                                <h3>Reset Your Password</h3>
                            </div>
                            <form onSubmit={resetPassword}>
                                <div className="form">
                                    <div className="input">
                                        <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="New Password" required />
                                    </div>
                                    <div className="input">
                                        <input type="password" onChange={(e) => setCnfPassword(e.target.value)} onBlur={checkPassword} placeholder="Confirm Password" required />
                                    </div>
                                    {/* {!isPassMatch && <p>Password not match</p>} */}
                                    <Button className="signin-btn" htmlType='submit'>Submit</Button>
                                </div>
                                {/* <div className="btn-class"> */}
                                {/* <Button className="signin-btn" htmlType='submit'>Submit</Button> */}
                                {/* </div> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Resetpassword;
