import React, { useEffect, useState } from "react";
import {
  AiOutlineSearch,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
  AiOutlineUnorderedList,
} from "react-icons/ai";
import { useTable } from "react-table";
import { DatePicker, Pagination } from "antd";
import { BsGraphUp } from "react-icons/bs";
import Norecordchart from "./Charts/Norecordchart";
import Http from "../../../services/http";
import moment from "moment";
import { IoMdCloseCircle } from "react-icons/io";
const { RangePicker } = DatePicker;

// const data: any = [
//   {
//     col1: "1",
//     col2: "Anwar Shaikh ",
//     col3: "Akram Shaikh",
//     col4: "2023-02-24",
//     col5:"Temp",
//     col6:"Temp",
//   },
//   {
//     col1: "1",
//     col2: "Anwar Shaikh ",
//     col3: "Akram Shaikh",
//     col4: "2023-02-24",
//     col5:"Temp",
//     col6:"Temp",
//   },
//   {
//     col1: "1",
//     col2: "Anwar Shaikh ",
//     col3: "Akram Shaikh",
//     col4: "2023-02-24",
//     col5:"Temp",
//     col6:"Temp",
//   },
//   {
//     col1: "1",
//     col2: "Anwar Shaikh ",
//     col3: "Akram Shaikh",
//     col4: "2023-02-24",
//     col5:"Temp",
//     col6:"Temp",
//   },
//   {
//     col1: "1",
//     col2: "Anwar Shaikh ",
//     col3: "Akram Shaikh",
//     col4: "2023-02-24",
//     col5:"Temp",
//     col6:"Temp",
//   },
//   {
//     col1: "1",
//     col2: "Anwar Shaikh ",
//     col3: "Akram Shaikh",
//     col4: "2023-02-24",
//     col5:"Temp",
//     col6:"Temp",
//   },
// ];

const columns: any = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Contact Name",
    accessor: "full_name",
  },
  {
    Header: "Client Name",
    accessor: "clientName",
  },
  {
    Header: "Ownership",
    accessor: "ownershipUserName",
  },
  {
    Header: "CreatedBy",
    accessor: "userName",
  },
  {
    Header: "CreatedAt",
    accessor: "createdAt",
  },
];

function NoRecord() {
  const [gridList, setGridList] = useState(true);
  const [page, setPage]: any = useState("1");
  const [perPage, setPerPage]: any = useState("10");
  const [searchByUser, setSearchByUser]: any = useState("");
  const [createdAt, setCreatedAt]: any = useState(["", ""]);
  const [realData, setRealData]: any = useState([]);
  const [realPagination, setRealPagination]: any = useState([]);
  const noRecordTableData: any = realData ? realData : [];
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: noRecordTableData });

  const [sortOrder, setOrder] = useState(true);
  const [sortField, setSortField] = useState("");

  useEffect(() => {
    getNoRecordData();
  }, [searchByUser, page, createdAt, sortField, sortOrder]);

  const getNoRecordData = async () => {
    const body: any = {
      createdAt: createdAt,
      searchByUser: searchByUser,
      perPage: perPage,
      page: page,
      sortField: sortField,
      sortOrder: sortOrder ? "DESC" : "ASC",
    };
    const result: any = await Http.post(
      "reports/no-record-added-since-created",
      body,
      false
    );
    setRealData(result?.data?.data);
    setRealPagination(result?.data?.pagination);
  };

  const getSetDateData = (e: any, data: any) => {
    setCreatedAt([data[0], data[1]]);
    setPage("1");
  };

  return (
    <>
      <div className="col">
        <div className="upper-bar" style={{ justifyContent: "center" }}>
          <div></div>
          <p className="title">No Record</p>
          {/* {gridList ? (
            <BsGraphUp
              className="grid-icon-user"
              onClick={() => setGridList(false)}
            />
          ) : (
            <AiOutlineUnorderedList
              className="grid-icon-user"
              onClick={() => setGridList(true)}
            />
          )} */}
        </div>
        {gridList ? (
          <div className="all-table animate__animated animate__flipInY">
            <div className="top-bar-userwise">
              <div className="search-bar-user-wise">
                <AiOutlineSearch
                  className="search-icon-user-wise"
                  style={{
                    fontSize: "20px",
                    color: "#0E4D92",
                    backgroundColor: "#F0F0F0",
                    height: "100%",
                    marginLeft: "10px",
                  }}
                />
                <input
                  type="text"
                  className="search-wise-user"
                  placeholder="Search..."
                  value={searchByUser}
                  onChange={(e: any) => {
                    setSearchByUser(e.target.value);
                    setPage("1");
                  }}
                />
                {/* <button type="button" className="submit-btn">
                Search
              </button> */}
              </div>
              <div className="forCrossIcon" style={{ position: "relative" }}>
                <RangePicker
                  className="pagi-datepicker"
                  ranges={{
                    Today: [moment(), moment()],
                  }}
                  onChange={getSetDateData}
                />
                {createdAt[0] !== "" || createdAt[1] !== "" ? (
                  <IoMdCloseCircle
                    style={{
                      pointerEvents: "none",
                      color: "#939393",
                      fontSize: "18px",
                      position: "absolute",
                      top: "7px",
                      right: "7px",
                      cursor: "pointer",
                    }}
                  />
                ) : null}
              </div>
            </div>
            <div className="table-of-userwise">
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {headerGroup.headers.map((column) => (
                        // <th {...column.getHeaderProps()}>
                        //   {column.render("Header")}
                        // </th>
                        <th {...column.getHeaderProps()}>
                          {column.id !== "notesCount" ? (
                            <>
                              {column.id === "full_name" ? (
                                <>
                                  {sortOrder && sortField === column.id ? (
                                    <AiOutlineSortDescending
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setOrder(!sortOrder);
                                        setSortField("first_name");
                                      }}
                                    />
                                  ) : (
                                    <AiOutlineSortAscending
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setOrder(!sortOrder);
                                        setSortField("first_name");
                                      }}
                                    />
                                  )}
                                </>
                              ) : (
                                <>
                                  {sortOrder && sortField === column.id ? (
                                    <AiOutlineSortDescending
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setOrder(!sortOrder);
                                        setSortField(column.id);
                                      }}
                                    />
                                  ) : (
                                    <AiOutlineSortAscending
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setOrder(!sortOrder);
                                        setSortField(column.id);
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </>
                          ) : null}

                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {row.cells.map((cell: any) => {
                          if (cell.column.id === "full_name") {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell?.row?.original?.first_name +
                                  " " +
                                  cell?.row?.original?.last_name}
                              </td>
                            );
                          } else {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="footer-bar-userwise">
              <Pagination
                className="pagi-user-wise"
                current={realPagination?.current_page}
                total={realPagination?.total_records}
                onChange={(e: any) => setPage(e)}
              />
            </div>
          </div>
        ) : (
          <Norecordchart />
        )}
      </div>
    </>
  );
}

export default NoRecord;
