import { FilterFilled, FilterOutlined } from "@ant-design/icons";
import { SelectAll } from "@mui/icons-material";
import { borderBottom } from "@mui/system";
import { elementAcceptingRef } from "@mui/utils";
import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  DatePickerProps,
  Popover,
  Space,
} from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { useSelector } from "react-redux";
import {
  changePaginationData,
  clientTableData,
  clientTableStates,
  doClientMultiSearch,
  refreshTable,
} from "../../../redux/actions/client";
import { getFilteredClientList } from "../../../services/client";
import SortComponent from "./SortComponent";
const { RangePicker } = DatePicker;
const ClientHeadModel = ({
  item,
  index,
  clientData,
  // clientTableStatesData,
  visibleHeadModel,
  setVisibleHeadModel,
  dispatch,
  searchInput,
  // isSearched,
  // setIsSearched,
  isMasterResetStart,
  setIsMasterResetStart
}: any) => {
  const [isThisModelVisible, setIsThisModelVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<any>("");
  const [isSearched, setIsSearched] = useState(false);
  const [modelSearchVal, setModelSearchVal] = useState("");

  const [dataNotFound, setDataNotFound] = useState(false);
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [upDatedDate, setUpDatedDate] = useState<any>();
  const [startDate, setStartDate] = useState<any>(moment(new Date()));
  const [endtDate, setEndDate] = useState<any>(moment(new Date()));
  const [disable, setdisable] = useState(false);
  const [dateDisable, setDateDisable] = useState(false);
  let filteredClientData: any[] = [];

  const checkOuterClick = (e: any) => {
    // 

    // const checkFilterActive = clientTableStatesData.isFilterOn
    if (clientTableStatesData.body[item.accessor].length > 0) {
      setdisable(true);
    } else if (clientTableStatesData.body[item.accessor].length === 0) {
      setdisable(false);
    }

    const deStructure = e.target?.parentElement?.className;
    const picker = deStructure?.substring(0, 10);
    const popdeStructure = e.target?.parentElement?.className;
    const pop = popdeStructure?.substring(0, 11);

    // 
    if (
      !e.target?.parentElement?.className?.includes("filter-icon") &&
      !e.target?.parentElement?.className?.includes("ant-checkbox") &&
      picker !== "ant-picker" && pop !== "ant-popover"
    ) {

      if (!isSearched) {
        clientTableStatesData.body[item.accessor] = [];
        // clientTableStatesData.isFilterOn = [];
        setSelectAllCheck(false);
        setDateDisable(false);
        // const checkFilterActive = clientTableStatesData.isFilterOn.filter(
        //   (e: any) => e !== item.accessor
        // );

        // clientTableStatesData.isFilterOn = checkFilterActive;
        // clearSearchField(item.accessor)
        // dispatch(clientTableStates(clientTableStatesData));
        // dispatch(refreshTable());
      }
      setDataNotFound(false);
      clientTableStatesData.filterSearchResult = [];
      // clientTableStatesData['isFilterOn'] = [];
      setModelSearchVal("");
      setIsThisModelVisible(false);
      setRef(null);
      // setRef(useDetectClickOutsideVar);
    }
  };

  const [ref, setRef] = useState<any>()

  const useDetectClickOutsideVar = useDetectClickOutside({
    onTriggered: checkOuterClick,
    triggerKeys: ["Enter", "Escape"],
  });

  useEffect(() => {
    setRef(useDetectClickOutsideVar)
    //   if (!ref) {
    //   }
    // setTimeout(() => {
    // }, 500);
  }, [ref]);

  // useEffect(() => {
  //     setSelectAllCheck(false)
  // }, [searchInput])

  useEffect(() => {

    setIsSearched(false)
    // if (isMasterResetStart) {

    // }

  }, [isMasterResetStart])

  let clientTableStatesData = useSelector(
    (state: any) => state.client.clientTableStatesData
  );

  useEffect(() => {
    let temp = true;

    if (visibleHeadModel == selectedFilter) {
      const node = document.querySelectorAll(
        `.current_list_${item.accessor} .clientCheckBoX .ant-checkbox-input`
      );

      node.forEach((ele: any) => {
        if (!clientTableStatesData.body[item.accessor].includes(ele?.value)) {
          temp = false;
        }
      });

      if (temp && clientTableStatesData?.body[item?.accessor]?.length > 0) {
        setSelectAllCheck(true);
      } else {
        setSelectAllCheck(false);
      }


      // if (clientTableStatesData.body[item.accessor]?.length === node?.length - 1) {
      //     setSelectAllCheck(true)
      // } else {
      //     setSelectAllCheck(false)
      // }
    }
  }, [
    clientTableStatesData.filterSearchResult,
    filteredClientData,
    isThisModelVisible,
  ]);

  useEffect(() => {
    const node = document.querySelectorAll(
      `.current_list_${item.accessor} .ant-checkbox-input`
    );

    if (
      clientTableStatesData.body[item.accessor]?.length ===
      node?.length - 1
    ) {
      setSelectAllCheck(true);
    } else {
      setSelectAllCheck(false);
    }
    setDataNotFound(false);
    setModelSearchVal("");
  }, [isThisModelVisible]);

  useEffect(() => {
    if (visibleHeadModel != selectedFilter) {
      setIsThisModelVisible(false);
      if (!isSearched) {
        setSelectAllCheck(false);
        clientTableStatesData.body[item.accessor] = [];
        clientTableStatesData.filterSearchResult = [];
        // filteredClientData=[]
      }

      dispatch(clientTableStates(clientTableStatesData));
    }
  }, [visibleHeadModel]);

  const modelSearch = async (value: string, accessor: any) => {
    setSelectAllCheck(false);
    setModelSearchVal(value);

    const { data }: any = await getFilteredClientList({
      searchBy:
        accessor == "ClientownersUser"
          ? "ownership"
          : accessor == "createUser"
            ? "createUser"
            : accessor,
      searchRecord: value,
      per_page: 10,
      page: 1,
    });

    const array: any = [];
    const ObjData: any = [];
    // 
    const td = data?.data?.map((row: any) => {
      Object.keys(row).forEach((key) => {
        if (key === accessor) {
          if (accessor == "ClientownersUser" || accessor == "createUser") {
            if (!array.includes(row[accessor]?.name)) {
              array.push(row[accessor]?.name);
              ObjData.push(row);
            }
          } else if (!array.includes(row[accessor])) {
            array.push(row[accessor]);
            ObjData.push(row);
          }
        }
      });

      // 
    });

    const checkFilterActive = clientTableStatesData.isFilterOn.filter(
      (e: any) => e != accessor
    );

    setDataNotFound(false);
    if (value !== "" && data?.data?.length === 0) {
      setDataNotFound(true);
    } else if (value === "" || data?.data?.length === 0) {
      return dispatch(
        clientTableStates({
          ...clientTableStatesData,
          filterSearchResult: [],
          // isFilterOn: checkFilterActive
        })
      );
    } else {
      return dispatch(
        clientTableStates({
          ...clientTableStatesData,
          filterSearchResult: ObjData,
          // isFilterOn: checkFilterActive
        })
      );
    }

    // }
  };

  const onChange = (ele: any, accessor: any) => {
    // 

    // setSelectAllCheck(true)
    const node = document.querySelectorAll(
      `.current_list_${item.accessor} .ant-checkbox-input`
    );

    if (
      ele?.target?.checked &&
      !clientTableStatesData?.isFilterOn?.includes(accessor)
    ) {
      clientTableStatesData.isFilterOn.push(accessor);
    } else if (
      !ele?.target?.checked &&
      clientTableStatesData?.body[accessor]?.length === 1
    ) {
      const checkFilterActive = clientTableStatesData.isFilterOn.filter(
        (item: any) => item !== accessor
      );
      clientTableStatesData.isFilterOn = checkFilterActive;
    }

    if (
      !clientTableStatesData.body[accessor]?.includes(
        ele?.target?.value?.toString()
      )
    ) {
      clientTableStatesData.body[accessor]?.push(
        ele?.target?.value?.toString()
      );
    } else {
      clientTableStatesData.body[accessor] = clientTableStatesData.body[
        accessor
      ].filter((e: any) => e !== ele?.target?.value?.toString());
    }

    //     
    if (clientTableStatesData.body[accessor]?.length === node?.length - 1) {
      setSelectAllCheck(true);
    } else {
      setSelectAllCheck(false);
    }

    if (accessor == "createdAt") {
      clientTableStatesData.isFilterOn.push(accessor);
    }

    
    if (clientTableStatesData.body[accessor].length > 0) {
      setdisable(true);
    } else if (clientTableStatesData.body[accessor].length === 0) {
      setdisable(false);
    }

    dispatch(clientTableStates(clientTableStatesData));

  };

  const onChangeRangeChange = (val: any, accessor: any) => {
    
    clientTableStatesData.isFilterOn.push(accessor);
    clientTableStatesData.body[accessor] = val;

    dispatch(clientTableStates(clientTableStatesData));
  };

  const clearSearchField = (accessor: any) => {
    setSelectAllCheck(false);
    setModelSearchVal("");
    clientTableStatesData.body[accessor] = [];

    const checkFilterActive = clientTableStatesData.isFilterOn.filter(
      (e: any) => e != accessor
    );

    


    clientTableStatesData['isFilterOn'] = checkFilterActive;
    clientTableStatesData.filterSearchResult = [];

    if (clientTableStatesData.body[item.accessor].length > 0) {
      setdisable(true);
    } else if (clientTableStatesData.body[item.accessor].length === 0) {
      setdisable(false);
    }

    setStartDate(moment(new Date()))
    setEndDate(moment(new Date()))
    // setModelSearchVal("");
    dispatch(clientTableStates(clientTableStatesData));
    dispatch(doClientMultiSearch());
    // setIsThisModelVisible(!isThisModelVisible);
  };

  const content = (index: any) => {
    clientData?.map((e: any) => {
      const accessor = item.accessor;
      let found = "";
      if (
        item?.accessor == "ClientownersUser" ||
        (item?.accessor == "createUser" && e[accessor] !== "")
      ) {
        found = filteredClientData?.find(
          (element) => element.value === e[accessor]?.name
        );
      } else {
        found = filteredClientData?.find(
          (element) => element.value === e[accessor]
        );
      }

      if (!found) {
        if (
          item?.accessor == "ClientownersUser" ||
          (item?.accessor == "createUser" && e[accessor] !== "")
        ) {
          // 
          filteredClientData?.push({
            id: e?.id,
            value: e[accessor]?.name,
            accessor: item?.accessor,
          });
        } else if (e[accessor] !== "") {
          filteredClientData?.push({
            id: e?.id,
            value: e[accessor],
            accessor: item?.accessor,
          });
        }
      }
    });

    const isCheckTrue = (value: any, accessor: any) => {
      // 
      let val: any = value?.toString();
      if (clientTableStatesData.body[accessor]?.includes(val)) return true;
      return false;
    };


    const selectAll = (e: any) => {
      const node = document.querySelectorAll(
        `.current_list_${item.accessor} .ant-checkbox-input`
      );

      if (e?.target?.checked) {
        

        node.forEach((e: any) => {
          if (!e?.target?.checked) {
            if (e?.value) {
              if (
                !clientTableStatesData.body[item.accessor].includes(e?.value)
              ) {
                clientTableStatesData.body[item.accessor].push(e?.value);
              }
              if (!clientTableStatesData.isFilterOn.includes(item.accessor)) {
                clientTableStatesData.isFilterOn.push(item.accessor);
              }
            }
          }
        });
        setSelectAllCheck(true);
      } else {
        setSelectAllCheck(false);
        node.forEach((e: any) => {
          if (e?.value) {
            clientTableStatesData.body[item.accessor] =
              clientTableStatesData.body[item.accessor].filter(
                (val: any) => val !== e.value
              );
          }
          if (
            clientTableStatesData.isFilterOn.includes(item.accessor) &&
            clientTableStatesData.body[item.accessor].length === 0
          ) {
            clientTableStatesData.isFilterOn =
              clientTableStatesData.isFilterOn.filter(
                (val: any) => val !== item.accessor
              );
          }
        });
      }

      if (clientTableStatesData.body[item.accessor].length > 0) {
        setdisable(true);
      } else if (clientTableStatesData.body[item.accessor].length === 0) {
        setdisable(false);
      }

      dispatch(clientTableStates(clientTableStatesData));

      return;
    };

    if (item.accessor === "createdAt" || item.accessor === "updatedAt") {
      const datePickers: RangePickerProps["onChange"] = (date, dateString) => {
        if (dateString.length > 1) {
          setDateDisable(true);
        }

        dateString[0]
          ? setStartDate(moment(new Date(dateString[0])))
          : setStartDate(moment(new Date()));
        dateString[1]
          ? setEndDate(moment(new Date(dateString[1])))
          : setEndDate(moment(new Date()));
        onChangeRangeChange([dateString[0], dateString[1]], item.accessor);
      };
      return (
        <div ref={ref}>
          <Space direction="vertical" size={12}>
            <RangePicker
              ranges={{
                Today: [moment(), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
              allowEmpty={[true, true]}
              value={[startDate, endtDate]}
              // disabledDate={disabledDate}
              onChange={datePickers}
            />
          </Space>
          <hr style={{ marginBottom: "0px" }} />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {
              <>
                <Button
                  onClick={() => {
                    

                    setModelSearchVal("");
                    setIsSearched(true);
                    dispatch(doClientMultiSearch());
                    setIsThisModelVisible(false);
                  }}
                  style={{ marginTop: "10px", opacity: dateDisable ? 1 : 0.7 }}
                  className="global-antd-btn"
                >
                  Search
                </Button>
                <Button
                  className="global-antd-btn clear-btn-filter"
                  id={`${item.accessor}ModelSearchClear`}
                  style={{ marginTop: "10px", opacity: dateDisable ? 1 : 0.7 }}
                  onClick={() => {
                    // setIsThisModelVisible(false);
                    clearSearchField(item.accessor);
                  }}
                >
                  Clear
                </Button>
              </>
            }
          </div>
        </div>
      );
    } else {
      return (
        <div ref={ref} className={`current_list_${item.accessor}`}>
          <AutoComplete
            style={{ width: 400 }}
            onSearch={(value) => modelSearch(value, item.accessor)}
            value={modelSearchVal}
            placeholder="Search"
            autoFocus
            tabIndex={0}
          />
          <br />
          {dataNotFound ? (
            ""
          ) : (
            <Checkbox
              checked={selectAllCheck}
              onChange={(e) => selectAll(e)}
              style={{ margin: "10px 0px" }}
            >
              <span style={{ fontWeight: "700" }}>Select All</span>
            </Checkbox>
          )}
          {dataNotFound ? (
            <div>Data Not Found</div>
          ) : (
            <div
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setModelSearchVal("");
                  dispatch(doClientMultiSearch());
                  setIsThisModelVisible(false);
                }
              }}
              className="clientModelSearchList"
            >
              {clientTableStatesData.filterSearchResult?.length === 0
                ? filteredClientData?.map((e: any) => {
                  if (e.value === "--" || e.value == null) {
                    return;
                  } else {
                    return (
                      <>
                        <Checkbox
                          value={e.value}
                          key={index}
                          style={{ display: "flex", margin: "0px" }}
                          className="clientCheckBoX"
                          checked={isCheckTrue(e.value, e.accessor)}
                          onChange={(ele) => {
                            onChange(ele, e.accessor);
                          }}
                        >
                          {e.value}
                        </Checkbox>
                      </>
                    );
                  }
                })
                : clientTableStatesData.filterSearchResult?.map(
                  (e: any, index: any) => {
                    const accessor = item.accessor;
                    const value =
                      accessor == "ClientownersUser" ||
                        accessor == "createUser"
                        ? e[accessor]?.name
                        : e[accessor];

                    return (
                      <>
                        <Checkbox
                          // tabIndex={index}
                          key={index}
                          value={value}
                          style={{ display: "flex", margin: "0px" }}
                          className="clientCheckBoX"
                          onChange={(ele) => onChange(ele, accessor)}
                          checked={isCheckTrue(value, accessor)}
                        >
                          {value}
                        </Checkbox>
                      </>
                    );
                  }
                )}
              <hr style={{ marginBottom: "0px" }} />
            </div>
          )}
          {
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                disabled={!disable}
                onClick={() => {
                  setModelSearchVal("");
                  setIsSearched(true);
                  dispatch(doClientMultiSearch());
                  setIsThisModelVisible(false);
                }}
                style={{ marginTop: "10px", opacity: disable ? 1 : 0.7 }}
                className="global-antd-btn search-btn-filter"
                id="search-btn-filter"
              >
                Search
              </Button>
              <Button
                disabled={!disable}
                className="global-antd-btn clear-btn-filter"
                id={`${item.accessor}ModelSearchClear`}
                onClick={() => {
                  clearSearchField(item.accessor);
                  // setModelSearchVal("");
                  setIsSearched(false);
                  // setIsThisModelVisible(false);
                }}
                style={{ opacity: disable ? 1 : 0.7 }}
              >
                Clear
              </Button>
            </div>
          }

          {/* </TabIndexContent> */}
        </div>
      );
    }
  };

  useEffect(() => {
    let ele: any = document?.querySelector(".table-responsive");
    if (isThisModelVisible) {
      ele.addEventListener("scroll", handleScroll, false);
      // Remove the event listener
    }
    return () => {
      ele.removeEventListener("scroll", handleScroll, false);
    };
  }, [isThisModelVisible]);

  const handleScroll = (event: any) => {
    if (event.target.className !== "clientModelSearchList") {
      if (isSearched) {
        setIsThisModelVisible(false);
      } else {
        

        if (visibleHeadModel != "") {
          let ele = document.getElementById(`${item.accessor}ModelSearchClear`);
          ele?.click();
          // clearSearchField(visibleHeadModel)
          setIsThisModelVisible(false);
          setdisable(false);
        }
      }
    }
  };

  // This for send in API once provided!
  const monthArr = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const sendDate =
    moment(startDate).date() >= 10
      ? moment(startDate).date()
      : "0" + moment(startDate).date();

  // .month()] + '-' + moment(startDate).year()}`);
  // 

  return (
    <>
      {item.accessor === "action" ? (
        <th key={index}>
          {item.header}
          <AiOutlineThunderbolt />
        </th>
      ) : (
        <th key={index}>
          {item.accessor !== "notes" && (
            <SortComponent
              sortData={item.accessor}
              data={clientData}
              clientTableStatesData={clientTableStatesData}
            />
          )}
          {item.header}
          <Popover
            placement="bottom"
            content={() => content(index)}
            title={`Search By ${item.header}`}
            // trigger="click"
            visible={isThisModelVisible}
          >
            {item?.isFilter ? (
              clientTableStatesData?.body[item.accessor]?.length > 0 ? (
                <FilterFilled
                  onClick={(e) => {
                    setSelectedFilter(item.accessor);
                    setVisibleHeadModel(item.accessor);
                    setIsThisModelVisible(!isThisModelVisible);
                  }}
                  className="filter-icon"
                />
              ) : (
                <FilterOutlined
                  onClick={(e) => {
                    setSelectedFilter(item.accessor);
                    setVisibleHeadModel(item.accessor);
                    setIsThisModelVisible(!isThisModelVisible);
                  }}
                  className="filter-icon"
                />
              )
            ) : (
              ""
            )}
          </Popover>
        </th>
      )}
    </>
  );
};

export default ClientHeadModel;
