import React, { useEffect, useState } from "react";
import ContactHeadModel from "./ContactParts/ContactHeadModel";
import ContactActionBtn from "./ContactActionBtn";
import { getContactColumnData } from "../../services/contacts";
import ContactNotesModal from "./ContactParts/ContactNotesModal";
import HyperLink from "./ContactParts/HyperLink";
import { useDispatch, useSelector } from "react-redux";
import { checkSelectAll, ContactIsChecked } from "../../redux/actions/contacts";
import ShowReportingManager from './ContactParts/ShowReportingManager';

const ContactsTableBody = (props: any) => {
  const [iconDisplay, setIconDisplay] = useState(false);
  const [iconNumber, setIconNumber] = useState(null);
  const dispatch: any = useDispatch();
  const checkFromState: any = useSelector((state: any) => state.contact);



  const userSettings: any = JSON.parse(
    localStorage.getItem("UserContactSettings") || ""
  );
  const data = props?.data;
  // 


  const display = (key: any) => {
    setIconDisplay(true);
    setIconNumber(key);
  };

  const contactCheck = (id: any, checked: any) => {
    let arr: any = checkFromState.ContactIsChecked;
    if (!arr?.includes(id)) {
      arr.push(id);
    } else {
      const temp: any = arr?.filter((e: any) => e !== id);
      arr = temp;
    }
    dispatch(ContactIsChecked(arr));
  };

  return (
    <>
      <tbody style={{ color: "#0E4D92" }}>
        {data?.length > 0 ? (
          data?.map((item: any, index: any) => {
            return (
              <>
                <tr
                  key={index}
                  onMouseEnter={() => display(index)}
                  onMouseLeave={() => setIconDisplay(false)}
                >
                  {getContactColumnData()?.map((e: any) => {
                    if (
                      !userSettings?.showOnlyThisColumns?.includes(e.accessor)
                    ) {
                      return "";
                    }
                    if (e.accessor === "id") {

                      const isContactChecked =
                        checkFromState.ContactIsChecked.includes(item.id)
                          ? true
                          : false;
                      return (
                        <td key={index}>
                          <input
                            checked={isContactChecked}
                            className='contact-checkbox'
                            onChange={(e: any) =>
                              contactCheck(item.id, e.target.checked)
                            }
                            style={{ marginRight: "10px" }}
                            type="checkbox"
                          />
                          {item[e.accessor]}
                        </td>
                      );
                    }
                    if (
                      e.accessor === "ContactcreatUser" ||
                      e.accessor === "ContactownerUser"
                    ) {
                      return (
                        <>
                          <td key={index}>
                            {item[e.accessor]?.name
                              ? item[e.accessor]?.name
                              : "--"}
                          </td>
                        </>
                      );
                    }
                    if (e.accessor === "clientName") {
                      return (
                        <>
                          <td key={index}>
                            {item?.clientName?.client_name}
                            {/* {<HyperLink item={{ item, iconDisplay, iconNumber, index }} />} */}
                          </td>
                        </>
                      );
                    }

                    if (e.accessor === "first_name") {

                      return (
                        <>
                          <td key={index}>
                            {item?.first_name}
                            {
                              <HyperLink
                                item={{ item, iconDisplay, iconNumber, index }}
                              />
                            }
                          </td>
                        </>
                      );
                    }

                    if (e.accessor === "ReportingManagerData") {
                      return (
                        <>
                          {
                            item?.ReportingManagerData
                              ?.first_name ?
                              <td key={index}>
                                {item?.ReportingManagerData
                                  ?.first_name} {item?.ReportingManagerData
                                    ?.last_name}

                                {
                                  <ShowReportingManager
                                    item={{ item: item?.ReportingManagerData, iconDisplay, iconNumber, index }}
                                  />
                                }
                              </td> : <td>--</td>
                          }
                        </>
                      );
                    }

                    

                    if (e.accessor === "linkedIn_profile") {
                      const validURL = () => {
                        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
                        return !!pattern.test(item[e.accessor]);
                      }
                      if (!validURL()) {
                        return (
                          <>
                            <td key={index} style={{ textAlign: "center" }}>
                              --
                            </td>
                          </>
                        );
                      }else{
                        return (
                          <>
                            <td
                              key={index}
                              style={{ textAlign: "center" }}
                              dangerouslySetInnerHTML={{
                                __html: `<a target='_blank' href='${item[e.accessor]
                                  }'><img height='15px' width='auto' src='/linkdn-logo.png'></img></a>`,
                              }}
                            ></td>
                          </>
                        );
                      }
                      
                    }
                    // if(e.accessor === "next_follow_up_date"){
                    //   const time = new Date(item[e.accessor]);
                    //   return (
                    //     <td key={index}>
                    //       {new Intl.DateTimeFormat("en-In", {
                    //         year: "numeric",
                    //         month: "2-digit",
                    //         day: "2-digit"
                    //       }).format(time)}
                    //     </td>
                    //   );
                    // }
                    if (e.accessor === "last_follow_up_date") {
                      const time = new Date(item[e.accessor]);
                      // 

                      return (
                        <>
                          <td key={index}>
                            {item[e.accessor] != "0000-00-00 00:00:00"
                              ? new Intl.DateTimeFormat("en-In", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }).format(time)
                              : "--"}
                          </td>
                        </>
                      );
                    }
                    if (
                      e.accessor === "createdAt" ||
                      (e.accessor === "updatedAt" && item[e.accessor] !== "")
                    ) {
                      const time = new Date(item[e.accessor]);

                      return (
                        <>
                          <td key={index}>
                            {item[e.accessor] != "0000-00-00 00:00:00"
                              ? new Intl.DateTimeFormat("en-In", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                              }).format(time)
                              : "--"}
                          </td>
                        </>
                      );
                    } else if (e.accessor === "additional_notes") {
                      return (
                        <>
                          <td
                            key={index}
                            style={{ maxWidth: "200px", overflow: "hidden" }}
                          >
                            {item?.contactNotes?.length > 0 ? (
                              <>
                                <ContactNotesModal data={item.contactNotes} standard_comment={item.standard_comment} managerName={item?.first_name+ "" + item?.last_name}/>
                                ...
                                {
                                  item?.contactNotes[0]?.notes
                                }
                              </>
                            ) : (
                              "--"
                            )}
                          </td>
                        </>
                      );
                    } else if (e.accessor === "action") {
                      return (
                        <>
                          <td key={index} style={{ width: "30px" }}>
                            <ContactActionBtn item={item} />
                          </td>
                        </>
                      );
                    }

                    return item[e.accessor] &&
                      item[e.accessor] != "0000-00-00" ? (
                      <>
                        <td key={index}>{item[e.accessor]}</td>
                      </>
                    ) : (
                      <>
                        <td key={index}>--</td>
                      </>
                    );
                  })}
                </tr>
              </>
            );
          })
        ) : (
          <tr>
            <td colSpan={5}>No Data Found</td>
          </tr>
        )}
      </tbody>
    </>
  );
};

export { ContactsTableBody };
