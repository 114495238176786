import { message } from "antd";
import axios from "axios";

// const API_URL = "http://192.168.1.174:8085" + "/api"; // Siraj
// const API_URL = "http://192.168.1.176:8081" + "/api"; // Akram
// const API_URL = "http://192.168.1.200:8085" + "/api"; // Anup
const API_URL = "https://api.wiseskulls.com" + "/api"; // Live
// const API_URL =
//   "https://main-client-module-backend-ynsu3.ondigitalocean.app" + "/api"; // New Live

const getHeader = () => {
  let user: any = localStorage.getItem("access_token");
  user = user ? JSON.parse(user) : null;

  if (user) {
    return {
      "Content-Type": "application/json",
      authorization: user,
    };
  }
};

export default class Http {
  static get(url: string, msg: boolean) {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: `${API_URL}/${url}`,
        headers: getHeader(),
      })
        .then(function (response: any) {
          if (response.data && response.data.success) {
            response.data.message &&
              msg &&
              message.success(response.data.message, 0.6);
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function (error: any) {
          // alert(error);
          //
          if (error.response.status === 404) {
            //
            message.error(error.response.data.message);
          } else if (error.response.status === 401) {
            localStorage.clear();
            window.location.pathname = "/";
            message.error("Logged Out");
          } else {
            message.error("Server Error");
          }
          // if (error.response.data.message === "Invalid or expired token provided!") {
          //   localStorage.clear();
          //   window.location.pathname = "/";
          // }
          reject(error.response.data);
        });
    });
  }

  static post(url: string, body: any, msg: boolean) {
    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `${API_URL}/${url}`,
        data: body,
        headers: getHeader(),
      })
        .then(function (response: any) {
          if (response.data && response.data.success) {
            response.data.message &&
              msg &&
              message.success(response.data.message, 0.6);
            resolve(response.data);
          } else {
            //
            resolve(response.data);
          }
        })
        .catch(function (error: any) {
          //

          // if(error.response.status === 404){
          //   //
          // }
          error.response.data.message &&
            msg &&
            message.error(error.response.data.message);
          if (url != "user/login") {
            setTimeout(() => {
              if (error.response.status === 401) {
                localStorage.clear();
                window.location.pathname = "/";
              }
            }, 2000);
          }
          reject(error.response);
        });
    });
  }

  static put(url: string, body: any, msg: boolean) {
    return new Promise((resolve, reject) => {
      axios({
        method: "put",
        url: `${API_URL}/${url}`,
        data: body,
        headers: getHeader(),
      })
        .then(function (response: any) {
          if (response.data && response.data.success) {
            response.data.message &&
              msg &&
              message.success(response.data.message, 0.6);
            resolve(response.data);
          } else {
            response.data.message && message.error(response.data.message);
            reject(response.data);
          }
        })
        .catch(function (error: any) {
          // alert(error);
          if (error.response.status === 401) {
            localStorage.clear();
            window.location.pathname = "/";
          }
          //
          reject(error.response.data);
        });
    });
  }

  static delete(url: string) {
    return new Promise((resolve, reject) => {
      axios({
        method: "delete",
        url: `${API_URL}/${url}`,
        headers: getHeader(),
      })
        .then(function (response: any) {
          if (response.data && response.data.success) {
            response.data.message &&
              message.success(response.data.message, 0.6);
            resolve(response.data);
          } else {
            reject(response.data);
          }
        })
        .catch(function (error: any) {
          // alert(error);
          if (error.response.status === 401) {
            error.response.message && message.error(error.response.message);
            localStorage.clear();
            window.location.pathname = "/";
          }
          //
          reject(error.response.data);
        });
    });
  }
}
