import { Modal } from "antd";
import React, { useState } from "react";
import { TbFileDatabase } from "react-icons/tb";
import { Table } from "react-bootstrap";
import { getContactColumnData } from "../../../services/contacts";

const HyperLink = ({ item }: any) => {
  const [hyperModal, setHyperModal] = useState(false);
  

  const hyperClick = () => {
    setHyperModal(true);
  };




  return (
    <>
      <span
        style={{ cursor: "pointer", opacity: item?.iconDisplay && item?.iconNumber === item?.index ? 1 : 0 }}
        onClick={() => {
          hyperClick();
        }}
      >
        {<TbFileDatabase style={{ marginBottom: "2px" }} />}
      </span>
      <Modal
        destroyOnClose
        title={`${item?.item?.first_name} Name's Details`}
        className="importClient"
        // style={{ width: 1000 }}
        visible={hyperModal}
        onOk={() => setHyperModal(false)}
        onCancel={() => setHyperModal(false)}
      >
        <Table striped="columns" className="preview-table">
          <thead>
            <tr>
              <th>No</th>
              <th>Accessor</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            {getContactColumnData().map((e: any, index: any) => {
              const AllData =
                e.accessor === "clientName" &&
                  item?.item?.contactNotes[0]?.notes != ""
                  ? item?.item[e.accessor]?.client_name
                  : e.accessor === "ContactownerUser" ||
                    e.accessor === "ContactcreatUser"
                    ? item?.item[e.accessor]?.name
                    : e.accessor === "additional_notes"
                      ? item?.item?.contactNotes[0]?.notes
                      : item?.item[e.accessor];


              if (e.accessor === "action" || e.accessor === "ReportingManagerData" || e.accessor === "reportingManager") {
                return;
              }
              index += 1;
              return (
                <tr>
                  <td>{`${index})`}</td>
                  <td className="preview-header" > <b> {e.header}</b></td>
                  <td>{AllData === null ? '--' : AllData === undefined ? '--' : AllData === '' ? '--' : AllData}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal>
    </>
  );
};

export default HyperLink;
