import { Button, Modal } from "antd";
import React, { useState } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import * as XLSX from "xlsx";
import Http from "../../services/http";

const ImportModal = () => {
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState("");
  const [errorStatus, setErrorStatus] = useState([]);
  const [isImportOpen, setIsImportOpen] = useState(false);
  // submit
  const [excelData, setExcelData] = useState(null);
  // it will contain array of objects

  // handle File
  const fileType = [
    "text/csv",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const handleFile = (e: any) => {
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileType.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e: any) => {
          setExcelFileError(selectedFile.name);
          setExcelFile(e.target.result);
        };
      } else {
        setExcelFileError("Please select only .xls and .csv file");
        setExcelFile(null);
      }
    } else {

    }
  };

  // submit function
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (excelFile !== null) {
      const workbook = XLSX.read(excelFile, { type: "binary" });
      const worksheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[worksheetName];
      const data: any = XLSX.utils.sheet_to_json(worksheet);
      const ImpData: any = JSON.stringify(data);
      // const ImpData: any = data;

      // ImpData.map((element: any) => {
      //   for (let key in element) {

      //     if (element[key]) {

      //     }

      //   }
      // });


      // const ImpData:any = data
      const result: any = await Http.post("users/import-data", ImpData, true);
      setErrorStatus(result.message);
      setExcelData(ImpData);
      if (result.success && result.message.length === 0) {
        setIsImportOpen(false);
      }
    } else {
      setExcelFile(null)
      setExcelData(null);
    }
  };

  return (
    <>
      <button
        style={{ backgroundColor: "white", color: "#0e4d92" }}
        onClick={() => setIsImportOpen(true)}
      >
        Import
      </button>
      <Modal
        destroyOnClose
        title="Client Import"
        className="importClient"
        // style={{ width: 1000 }}
        visible={isImportOpen}
        onOk={() => setIsImportOpen(false)}
        onCancel={() => setIsImportOpen(false)}
      >
        <div className="container">
          <div className="download_template">
            <a href="/template/client_import_template.xlsx">Download Excel Template<AiOutlineDownload style={{ fontSize: "22px", marginLeft: "5px", marginTop: "-5px" }} /></a>
          </div>
          {/* upload file section */}
          <div className="form">
            <form
              className="form-group"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {/* <label><h5>Upload Excel file</h5></label> */}
              <br></br>
              <div className="draganddrop">
                <label htmlFor="rrr">
                  Drag File Here To Import <br /> OR <br /> <i> Browse</i>{" "}
                  <br /> <span> (only .xls and .csv file allowed )</span>{" "}
                </label>
                <input
                  type="file"
                  id="rrr"
                  className="form-control draganddropinner"
                  onChange={handleFile}
                  required
                ></input>
              </div>
              {excelFileError && (
                <div
                  className={
                    excelFileError === "Please select only .xls and .csv file"
                      ? "text-danger"
                      : "text-grey"
                  }
                  style={{ marginTop: 5 + "px" }}
                >
                  {excelFileError}
                </div>
              )}
              <Button
                htmlType="submit"
                className="global-antd-btn import-submit-btn"
              >
                Submit
              </Button>
              {errorStatus?.length === 0 ? (
                ""
              ) : (
                <div className="error-div">
                  {errorStatus?.map((item: any, index: any) => {
                    const newIndex: any = index + 1;
                    return <p>{`${newIndex}) ${item}`}</p>;
                  })}
                </div>
              )}
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ImportModal;
