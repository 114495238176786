import { UploadOutlined } from "@mui/icons-material";
import { Button, Upload, UploadProps } from "antd";
import React, { useState } from "react";
import "./style.css";
import * as xlsx from "xlsx";

const Import = () => {
  const [importData, setImportData] = useState({});

  const readUploadFile = (e: any) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = "success";
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const resp = xlsx.utils.sheet_to_json(worksheet);
        // 
        setImportData(resp);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }
  // 

  return (
    <>
      <div className="temp" style={{ marginTop: "150px" }}>
        <form>
          <label htmlFor="upload" style={{ fontSize: "16px", border: "1px solid black", padding: "5px", borderRadius: "5px", cursor: "pointer", marginLeft: "20px" }} >Import</label>
          <input
            type="file"
            name="upload"
            id="upload"
            style={{ display: "none" }}
            onChange={(e: any) => readUploadFile(e)}
          />
        </form>
      </div>

    </>
  );
}

export default Import;