import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ContactTopSearch from "../../components/Contact/ContactTopSearch";
import Table from "../../components/Contact/Table";
import {
  clearContactMultipleSearch,
  getAllContacts,
  searchContacts,
  setMultiSearchData,
  setPagination,
} from "../../redux/actions/contacts";
import Http from "../../services/http";
import "./style.css";

interface Props {
  contact: any;
  getAllContact: Function;
  setPagination: Function;
  contactRefresh: boolean
}

const Contacts = (props: Props) => {
  const dispatch = useDispatch();
  const { pageSize } = useSelector((state: any) => state.contact)

  const [page, setPage] = useState(1);
  const [isSearched, setIsSearched] = useState(false);

  const [perPage, setPerPage] = useState(pageSize);
  const [firstLoad, setFirstLoad] = useState(true);

  // For Top Search
  const [searchVal, setSearchVal] = useState("");
  const [searchBy, setSearchBy] = useState("");



  const { contactTableSearchData } = useSelector((state: any) => state.contact);

  const doMultiSearch = async () => {
    const finalBody: any = {
      page: page,
      per_page: perPage,
    };

    Object.keys(contactTableSearchData).forEach((key) => {
      switch (key) {
        case "clientName":
          finalBody["client_name"] = contactTableSearchData[key];
          break;

        default:
          finalBody[key] = contactTableSearchData[key];
          break;
      }
    })

    const owner: any = JSON.parse(localStorage.getItem("dataByOwnership") || "{}")
    const isOwner = owner.OwnerUser
    // const isOwner = true

    // Ulta chal raha he true ka false and false ka true kyupya dhyan de

    const urlBody = isOwner ? "users/multiple-search-contact" : "users/multiple-search-contact-owner"

    const { data }: any = await Http.post(
      urlBody,
      finalBody,
      false
    );

    dispatch(setMultiSearchData(data.data));

    dispatch(setPagination(data.pagination));

    // setIsThisModelVisible(!isThisModelVisible);
  };


  useEffect(() => {

    document.title = 'Contacts - Client Module';

    if (firstLoad) {
      dispatch(clearContactMultipleSearch());
      setFirstLoad(false)
    }

  }, [])

  useEffect(() => {

    if (searchVal === "" && props.contact.contactTableSearchData.isMultiSearch?.length === 0) {
      props.getAllContact(page, perPage)
    } else if (searchVal !== "") {
      const body = {
        searchBy,
        searchRecord: searchVal,
        page,
        per_page: perPage,
      };
      dispatch(searchContacts(body));
    } else {
      doMultiSearch();
    }

  }, [page, perPage, props.contactRefresh]);

  const topSearchprops = {
    searchVal,
    searchBy,
    setSearchVal,
    setSearchBy,
    setPage,
    setPerPage,
    isSearched,
    setIsSearched,
    doMultiSearch
  };
  return (
    <>
      <div className="wrapper-table" id="client-wrapper-table">
        <div className="table-boxs">
          <ContactTopSearch props={topSearchprops} />
          <Table
            data={props.contact.getAllContact}
            page={page}
            setPage={setPage}
            perPage={perPage}
            setPerPage={setPerPage}
            searchVal={searchVal}
            isSearched={isSearched}
            setIsSearched={setIsSearched}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ contact }: any) => ({
  contact: contact,
  contactRefresh: contact.contactRefresh,
});

const mapDispatchToProps = (dispatch: any) => ({
  getAllContact: (page: any, perPage: any) =>
    dispatch(getAllContacts(page, perPage)),
  setPagination: (data: any) => dispatch(setPagination(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
