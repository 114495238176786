import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";

function Norecordchart() {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  const options: any = {
    animation: {
      duration: 10,
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#0E4D92",
          font: {
            family: "Mulish",
            size: 13,
            weight: "600",
          },
        },
      },
      y:{
        beginAtZero: true,
        border: {
          display: false,
        },
        ticks: {
          color: "#0E4D92",
          font: {
            family: "Mulish",
            size: 13,
            weight: "600",
          },
        },
      }
    },
  };

  const labels = ["Anwar", "Akram", "Anup", "Siraj"];

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "",
        data: ["100", "200", "150", "170"],
        borderColor: "#146FC8",
        backgroundColor: "rgba(26, 111, 196, 0.5)",
      },
    ],
  };

  return (
    <div className="animate__animated animate__flipInY" style={{padding:"15px 20px"}}>
      <Line options={options} data={data} />
    </div>
  );
}

export default Norecordchart;
