import { getUserTableData, setUserActive } from "../../services/client";
import { doMasterReset } from "./client";
import { doContactMasterReset } from './contacts';


export enum UserType {
	SET_USER = 'SET_USER',
	LOGOUT_USER = 'LOGOUT_USER',
	USER_LIST = 'USER_LIST',
	USER_LIST_FAILED = 'USER_LIST_FAILED',
	USER_LIST_SUCCESS = 'USER_LIST_SUCCESS',
	GET_USER_DATA = 'GET_USER_DATA'
}

export const setUser = (data: any) => (dispatch: any) => {
	dispatch({
		type: UserType.SET_USER,
		data
	})
}


export const logoutUser = () => (dispatch: any) => {

	dispatch(doMasterReset());
	dispatch(doContactMasterReset());

	dispatch({
		type: UserType.LOGOUT_USER
	});
}

export const getUserData = (pagesize: any, page: any) => async (dispatch: any) => {

	const result = await getUserTableData(pagesize, page)

	const { data }: any = await result

	dispatch({
		type: UserType.GET_USER_DATA,
		data
	})
}

export const UserIsActive = (body: any, id: any) => async () => {
	const result: any = await setUserActive(body, id)
	if (result.success) {
		return (true)
	} else {
		return (false)
	}
}