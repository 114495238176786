import { ExclamationCircleOutlined } from "@ant-design/icons";
import { message, Modal } from "antd";
import React from "react";
import { HiOutlineEnvelopeOpen } from "react-icons/hi2";
import { contactRefresh } from "../../../redux/actions/contacts";
import { updateContactNewNotes } from "../../../services/contacts";
import Http from "../../../services/http";
const { confirm } = Modal;

const MarkAsRead = ({ dispatch, id }: any) => {

  const updateNotes = () => {

    Http.put(
      `users/contact-notes-update/${id}`,
      { isDone: true },
      false
    ).then(() => {
      message.success("Mark as read!")
      dispatch(contactRefresh());
    }).catch((error: any) => {
      message.error(error)
    })
  };



  const confirmRead = () => {
    confirm({
      title: `Are you sure you want to mark this note as Read?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      className: "clientDeletebtn",
      maskClosable: true,
      onOk() { updateNotes() },
      onCancel() {

      },
    });

  }


  return (
    <span onClick={confirmRead} style={{ cursor: "pointer" }}>
      <HiOutlineEnvelopeOpen style={{ fontSize: '18px', color: 'gray' }} />
    </span>
  );
};

export default MarkAsRead;
