export const firstMenu = [
    {
        name: "Search Any",
        value: "",
    },
    {
        name: "ID",
        value: "id",
    },
    {
        name: "Client Name",
        value: "client_name",
    },
    {
        name: "First Name",
        value: "first_name",
    },
    {
        name: "Last Name",
        value: "last_name",
    },
    {
        name: "Title",
        value: "title",
    },
    {
        name: "Phone 1",
        value: "phone_1",
    },
    {
        name: "Phone 2",
        value: "phone_2",
    },
    {
        name: "Phone 3",
        value: "phone_3",
    },
    {
        name: "City",
        value: "city",
    },
    {
        name: "State",
        value: "state",
    },
    {
        name: "Country",
        value: "country",
    },
    {
        name: "Skills Support",
        value: "skills_support",
    },
];

export const secondMenu = [
    {
        name: "Full Name",
        value: "fullName",
    },
    {
        name: "Email",
        value: "email",
    },
    {
        name: "Alternate Email",
        value: "alternate_email",
    },
    {
        name: "Time Zone",
        value: "time_zone",
    },
    {
        name: "Unit",
        value: "unit",
    },
    {
        name: "End Client",
        value: "end_client",
    },
    // {
    //     name: "Last Follow-up Date",
    //     value: "last_follow_up_date",
    // },
    // {
    //     name: "Next Follow-up Date",
    //     value: "next_follow_up_date",
    // },
    {
        name: "Standard Comment",
        value: "standard_comment",
    },
    {
        name: "Lead Status",
        value: "lead_status",
    },
    {
        name: "Lead Source",
        value: "lead_source",
    },
    {
        name: "LinkedIn Profile",
        value: "linkedIn_profile",
    },
    {
        name: "Created By",
        value: "ContactcreatUser",
    },
    {
        name: "Ownership",
        value: "ContactownerUser",
    },
    // {
    //     name: "Notes",
    //     value: "additional_notes",
    // },
];
