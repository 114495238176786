import Http from "./http";

export const getUserDataAPI = async (data: any) => {
  const result = await Http.post("reports/user-wise-notes", data, false);
  return result;
};


export const getLebelCountAPI = async (data:any) =>{
  const result:any = await Http.post("reports/label-count", data, false);
  return result
} 

export const getTotalNextFollowUpAPI = async (data:any) =>{
  const resuly:any = await Http.post("reports/total-next-follow-up-date", data, false);
  return resuly;
} 