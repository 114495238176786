import "./sidebar.css";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import AssessmentIcon from "@mui/icons-material/Assessment";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import PieChartIcon from '@mui/icons-material/PieChart';
import { useLocation } from "react-router-dom";
import { Button, Modal } from "antd";
import { Tooltip } from "@mui/material";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { logoutUser } from "../../redux/actions/user";
import { connect, useDispatch } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { RiContactsFill } from "react-icons/ri";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import NotificationPopup from "./sidebarComponent/NotificationPopup";
import { contactRefresh } from "../../redux/actions/contacts";
const { confirm } = Modal;

const logo = require("../../assets/images/nav-logo.png");
const logo2 = require("../../assets/images/Wiseskulls_Text.png");

interface Props {
  logoutUser: Function;
}

function Sidebar({ logoutUser }: Props) {
  const dispatch = useDispatch();
  const location: any = useLocation();

  const userData = JSON.parse(localStorage.getItem("user")!);

  const onLogOut = () => {
    confirm({
      title: `Are you sure you want to log out?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      className: "clientDeletebtn",
      maskClosable: true,
      onOk() {
        logoutUser();
      },
      onCancel() {},
    });
  };

  

  return (
    <div className="sidebar-wrapper sidebar">
      <div className="sidebar-options">
        <div className="logo-profile">
          <div className="logo-home">
            <Link
              to="/dashboard"
              style={{ display: "flex", alignItems: "center" }}
            >
              <img src={logo} alt="" width="35" height="" />
              <img
                src={logo2}
                alt=""
                style={{ marginLeft: "8%", marginTop: "5px" }}
              />
            </Link>
          </div>
        </div>
        <ul className="main-menu">
          <li
            className={
              location?.pathname === "/dashboard"
                ? "logo-border"
                : "logo-orignal"
            }
          >
            {location?.pathname === "/dashboard" ? (
              <a href="/dashboard">
                <DashboardIcon />
                Dashboard{" "}
              </a>
            ) : (
              <Link to="/dashboard">
                <DashboardIcon />
                Dashboard{" "}
              </Link>
            )}
          </li>
          <li
            className={
              location?.pathname === "/client" ? "logo-border" : "logo-orignal"
            }
          >
            {location?.pathname === "/client" ? (
              <a href="/client">
                <AssessmentIcon />
                Clients{" "}
              </a>
            ) : (
              <Link to="/client">
                <AssessmentIcon />
                Clients{" "}
              </Link>
            )}
          </li>
          <li
            className={
              location?.pathname === "/contacts"
                ? "logo-border"
                : "logo-orignal"
            }
          >
            {location?.pathname === "/contacts" ? (
              <a href="/contacts">
                <RiContactsFill />
                Contacts{" "}
              </a>
            ) : (
              <Link to="/contacts">
                <RiContactsFill />
                Contacts{" "}
              </Link>
            )}
          </li>
          {userData.role === "0" ? (
            <li
              className={
                location?.pathname === "/users" ? "logo-border" : "logo-orignal"
              }
            >
              <Link to="/users">
                <GroupIcon />
                Users{" "}
              </Link>
            </li>
          ) : null}

          {userData.role == "0" ? (
            <li
              className={
                location?.pathname === "/report"
                  ? "logo-border"
                  : "logo-orignal"
              }
            >
              <Link to="/report">
                <PieChartIcon  />
                Report
              </Link>
            </li>
          ) : null}

          <li>
            <div className="logout-btn">
              <Tooltip
                placement="top"
                title="LogOut"
                className="antd-logout-btn"
                arrow
              >
                <button onClick={() => onLogOut()}>
                  <LogoutIcon />
                </button>
                {/* <button onClick={() => logoutUser()}>
                  <LogoutIcon />
                </button> */}
              </Tooltip>
            </div>
          </li>
          <NotificationPopup dispatch={dispatch} />
        </ul>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  logoutUser: () => dispatch(logoutUser()),
});

export default connect(null, mapDispatchToProps)(Sidebar);
