import "./App.css";
import * as React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routing from "./route/index";
import { connect } from "react-redux";
// import Routing from './route/index';
import { logoutUser } from "./redux/actions/user";

import "./App.css";

function App(props: any) {

  return (
    <Router>
      <Routing user={props.user.user} logout={props.logout} />
    </Router>
  );
}

const mapStateToProps = (state: any) => ({
  user: state.user,
});
const mapDispatchToProps = (dispatch: any) => ({
  logout: () => dispatch(logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
