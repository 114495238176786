import React, { useEffect, useState } from 'react'
import { AppstoreAddOutlined } from "@ant-design/icons";
import { Button, Checkbox, Modal } from "antd";
import { Tooltip } from "@mui/material";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { clientColumns } from "../TableParts";
import { refreshTable, setHiddenTableColumns } from '../../../redux/actions/client';
import { display } from '@mui/system';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { AiOutlineDrag } from 'react-icons/ai';
import { saveUserSetting } from '../../../services/contacts';
import { getClientColumnData } from '../../../services/client';


const ClientHideShowTableColmns = ({ dispatch }: any) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSelectAllSelect, setIsSelectAllSelect] = useState(false);

    const plainOptions = getClientColumnData()?.map((head: any) => head.accessor == "action" || head.accessor == "id" ? { label: head.header, value: head.accessor, disabled: true } : { label: head.header, value: head.accessor }).filter((val: any) => val);

    const [ClientColumns, setContactColumns] = useState(getClientColumnData())

    const [dragedClientColumns, setDragedClientColumns] = useState(clientColumns)


    // const plainOptions = JSON.parse(localStorage.getItem("clientPlainOptions") || '')

    // localStorage.setItem("clientPlainOptions", JSON.stringify(plainOptions))

    // const  = clientColumns.map((head: any) => head.accessor).filter(val => val);

    // let defaultCheckedList2: any = ContactColumns.map((head: any) => head.accessor).filter((val: any) => val);

    // localStorage.setItem("clientDefaultCheckedList", JSON.stringify(defaultCheckedList2))


    let defaultCheckedList = JSON.parse(localStorage.getItem("clientDefaultCheckedList") || '')


    const [checkedList, setCheckedList] =
        useState(defaultCheckedList);

    // 


    useEffect(() => {

        // 
        // 

        if (checkedList.length == plainOptions.length) {
            setIsSelectAllSelect(true)
        } else {
            setIsSelectAllSelect(false)
        }

    }, [checkedList, isSelectAllSelect])

    let hiddenColumns: any = [];

    const onSubmit = () => {
        checkedList.forEach((val: any) => {
            const arr = ClientColumns.filter((ele: any) => ele.accessor === val);
            hiddenColumns.push(...arr);
        });
        setIsModalOpen(false);

        saveUserSetting("ClientColumns", dragedClientColumns);
        saveUserSetting("clientDefaultCheckedList", checkedList);

        dispatch(refreshTable())

        // dispatch(setHiddenTableColumns(hiddenColumns));
    };

    const setSelectAllFunc = (e: any) => {
        if (e.target.checked) {
            const defaultCheckedList = clientColumns.map((head: any) => head.accessor).filter((val: any) => val);
            setCheckedList(defaultCheckedList)
        } else {
            const defaultCheckedList = clientColumns.map((head: any) => {
                if (head.accessor == "id" || head.accessor == "action") {
                    return head.accessor;
                }
            }).filter((val: any) => val);
            // 
            setCheckedList(defaultCheckedList)
        }
    }

    const selectionChanged = (list: any) => {
        // setCheckedList(list);

        if (list.target.value !== "id" || list.target.value !== "action") {
            if (!checkedList.includes(list.target.value)) {
                setCheckedList([...checkedList, list.target.value]);
            } else {
                const temp = checkedList.filter((e: any) => e !== list.target.value);
                setCheckedList(temp);
            }
        }
    };


    function handleOnDragEnd(result: any) {
        if (result.draggableId === "id" || result.draggableId === "action") return;
        if (result.destination.index === 0 || result.destination.index === ClientColumns.length - 1) return;
        if (!result.destination) return;
        const items = Array.from(dragedClientColumns);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        // setContactColumns(items)
        setDragedClientColumns(items)
        
        // setContactColumns(items);
        // localStorage.setItem("clientPlainOptions", JSON.stringify(items))
        // setContactColumns(items);
    }



    return (

        <>
            <Tooltip title="Show/Hide Table Columns" placement="top" arrow>
                <AppstoreAddOutlined onClick={() => setIsModalOpen(true)} />
            </Tooltip>
            <Modal
                title="Show/Hide Table Columns"
                open={isModalOpen}
                onOk={onSubmit}
                onCancel={() => setIsModalOpen(false)}
                className={"hide-column"}
                style={{ height: "70%" }}
            >

                <Checkbox className='select-all' onChange={setSelectAllFunc} checked={isSelectAllSelect}>
                    Select All
                </Checkbox>

                {/* <Checkbox.Group
                    style={{ display: "flex", flexDirection: "column" }}
                    options={plainOptions}
                    value={checkedList}
                    onChange={selectionChanged}
                /> */}

                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters">
                        {(provided: any) => (
                            <ul
                                className="characters"
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {dragedClientColumns?.map(({ header="", accessor="" }: any, index: any) => {

                                    const isDragDisabled = accessor == "id" || accessor == "action" ? true : false;

                                    const isChecked = checkedList?.includes(accessor)
                                        ? true
                                        : false;

                                    let dragDesign: any = { padding: "0.4rem", margin: "0.5rem", border: "0px solid #222", boxShadow: "rgba(0, 0, 0, 0.35) 0px 2px 5px", display: "flex", justifyContent: "space-between" }

                                    if (isDragDisabled) {
                                        // dragDesign = {}
                                    }

                                    return (
                                        <Draggable
                                            key={accessor}
                                            draggableId={accessor}
                                            index={index}
                                        // isDragDisabled={isDragDisabled}
                                        >
                                            {(provided: any) => (
                                                <li
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                >
                                                    {
                                                        // !isDragDisabled &&
                                                        <div
                                                            style={dragDesign}
                                                        >
                                                            <Checkbox
                                                                value={accessor}
                                                                checked={isChecked}
                                                                onChange={selectionChanged}
                                                                disabled={isDragDisabled}
                                                            >
                                                                {header}
                                                            </Checkbox>
                                                            {
                                                                isDragDisabled ? '' :

                                                                    <span style={{ justifySelf: "end", color: "#111" }}>
                                                                        <AiOutlineDrag />
                                                                    </span>
                                                            }
                                                        </div>
                                                    }
                                                </li>
                                            )}
                                        </Draggable>
                                    );
                                })}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>

                <div className='column_hide_show' style={{ display: 'flex', justifyContent: 'space-around', marginTop: '0px' }}>
                    <Button className='global-antd-btn' onClick={() => {
                        setIsModalOpen(false)
                        onSubmit();
                    }}>
                        Submit
                    </Button>
                    <Button className='global-antd-btn' onClick={() => setIsModalOpen(false)}>
                        Cancel
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default ClientHideShowTableColmns
