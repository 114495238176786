import { Button, Empty, Form, Input, message, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";

import axios from "axios";
import Http from "../../services/http";
import { getClientInfoData, refreshTable } from "../../redux/actions/client";
import { useDispatch } from "react-redux";
import { contactRefresh } from "../../redux/actions/contacts";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import TextArea from "antd/lib/input/TextArea";
import http from "../../services/http";

const ClienContact = ({
  clientTab,
  setIsModalOpen,
  setClientTab,
  getTableData,
  clientId,
  setClientId,
  isModalOpen,
}: any) => {
  // const [client, setClient] = useState(["Infosys", "Wipro", "IBM"]);

  const dispatch = useDispatch();
  const [incOut, setIncOut]: any = useState("");
  const [isError, setIsError] = useState(false);
  const [sendClient, setSendClient] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneTwo, setPhoneTwo] = useState("");
  const [phoneThree, setPhoneThree] = useState("");
  const [email, setEmail] = useState("");
  const [alEmail, setAlEmail] = useState("");
  const [userList, setUserList] = useState([]);
  const [country, setCountry] = useState([]);
  const [sendCountry, setSendCountry] = useState("");
  const [state, setState] = useState([]);
  const [sendState, setSendState] = useState("");
  const [city, setCity] = useState([]);
  const [sendCity, setSendCity] = useState("");
  const [nextFollowTime, setNextFollowTime] = useState("00:00:00");
  const [timeZone, setTimeZone] = useState([
    "IST",
    "HST",
    "AKST",
    "PST",
    "MST",
    "CST",
    "EST",
  ]);
  const [unit, setUnit] = useState("");
  const [endClient, setEndClient] = useState("");
  const [skills, setSkills] = useState("");
  const [standardComment, setStandardComment] = useState([
    "Follow-up",
    "Follow-up on a specific Date/Time",
    "Hung-up",
    "Manager with Active Requirements",
    "Connected Manager",
    "Voicemail",
    "DND",
    "Number not in service",
    "Wants to stick with standard Channel",
    "Wrong Number",
    "Handles Offshore Requirements",
    "No number given",
    "Not involved in hiring",
    "Call Later",
    "Call is not going through",
    "No Longer with company",
    "Reference",
  ]);
  const [comment, setComment] = useState("");
  const [salesComment, setSalesComment] = useState("");
  const [leadStatusArray, setLeadStatusArray] = useState([
    "New Lead",
    "Working",
    "Connected Manager",
  ]);
  const [leadStatus, setLeadStatus] = useState("");
  const [leadSourceArray, setleadSourceArray] = useState([
    "LinkedIn",
    "Zoominfo",
    "Internal Data",
    "Client Reference",
    "Internal Team's Referance",
  ]);
  const [leadSource, setLeadSource] = useState("");
  const [linkedInProfile, setLinkedInProfile] = useState("");
  const [ownership, setOwnership] = useState([]);
  const [sendOwnerShip, setSendOwnerShip] = useState("");
  const [notes, setNotes] = useState("");
  const [clientListData, setClientListData] = useState([]);
  const [time, setTime] = useState("");
  const [contactTimeZone, setContactTimeZone] = useState("");
  const [nextFollowZone, setNextFollowZone] = useState([
    "IST",
    "HST",
    "AKST",
    "PST",
    "MST",
    "CST",
    "EST",
  ]);
  const [nextFollowUpdate, setNextFollowUpdate] = useState("");
  const [reportingManager, setReportingManager] = useState("");
  const [reportingManagerFromApi, setReportingManagerFromApi] = useState([]);
  const [reportingManagerLoading, setReportingManagerLoading] = useState(false);
  const token = localStorage.getItem("access_token") || "";

  const addContact = async () => {
    const body = {
      client_id: clientId,
      client_name: clientTab?.client_name
        ? clientTab?.client_name
        : clientTab?.client_name,
      first_name: firstName,
      last_name: lastName,
      contact_time_zone: contactTimeZone,
      phone_1: phone,
      phone_2: phoneTwo,
      phone_3: phoneThree,
      alternate_email: alEmail,
      city: sendCity,
      state: sendState,
      country: sendCountry,
      next_follow_up_time_zone: time,
      unit: unit,
      end_client: endClient,
      email: email,
      reportingManager: reportingManager,
      title: title,
      skills_support: skills,
      next_follow_up_time: nextFollowTime,
      next_follow_up_date: nextFollowUpdate,
      standard_comment: comment,
      sales_comments: salesComment,
      lead_status: leadStatus,
      lead_source: leadSource,
      linkedIn_profile: linkedInProfile,
      ownership: sendOwnerShip,
      notes: notes,
      noteSource: incOut,
    };

    Http.post("users/createcontact", body, false).then((result: any) => {
      if (result.success) {
        message.success(result.message);
        setClientTab(null);
        clearFields();
        getTableData();
        setIsModalOpen(false);
        dispatch(contactRefresh());
      } else {
        message.error(result.message);
      }
    });
  };

  const formSubmit = () => {
    addContact(); // Need client i
  };

  useEffect(() => {
    getdata();
  }, []);

  // useEffect(()=>{
  //     clearFields();
  //     console.log("sdfdfasdsa");

  // }, [isModalOpen])

  const getdata = async () => {
    const { data = [] }: any = await Http.get(
      "user/user-list?per_page=1000&page=1",
      false
    );
    setUserList(data.data);
    //
    const { countries = [] }: any = await Http.get("users/country_list", false);
    setCountry(countries);
    //
  };

  const countryChange = (e: any) => {
    setState([]);
    setSendState("");
    setCity([]);
    setSendCity("");

    if (!e) {
      return setSendCountry("");
    }

    Http.post("users/get_states_by_country", { country_id: e }, false).then(
      async ({ states = [] }: any) => {
        setState(states);
      }
    );

    const filterdArray2: any = country.filter((elem: any) => {
      return elem.id == e;
    });

    setSendCountry(filterdArray2[0].name);
  };

  const changeState = (e: any) => {
    setCity([]);
    setSendCity("");
    if (!e) {
      return setSendState("");
    }
    Http.post("users/get_cities_by_state", { state_id: e }, false).then(
      async ({ city = [] }: any) => {
        setCity(city);
      }
    );

    const filterdArray2: any = state.filter((elem: any) => {
      return elem.id == e;
    });

    setSendState(filterdArray2[0].name);
  };

  useEffect(() => {
    reportingmanagerList();
  }, [clientId]);

  const reportingmanagerList = async () => {
    setReportingManagerLoading(true);
    setReportingManagerFromApi([]);
    const result: any = await http.post(
      `users/contact-reporting-manager`,
      { reportingId: clientId },
      false
    );
    setReportingManagerLoading(false);
    setReportingManagerFromApi(result.data);
  };

  const clientList = async () => {
    const { data }: any = await http.get("users/get-client", false);
    setClientListData(data);
  };

  useEffect(() => {
    clientList();
  }, []);
  const clearFields = () => {
    setSendClient("");
    setClientId("");
    setFirstName("");
    setLastName("");
    setSendCountry("");
    setSendState("");
    setSendCity("");
    setTitle("");
    setPhone("");
    setPhoneTwo("");
    setPhoneThree("");
    setEmail("");
    setAlEmail("");
    setTime("");
    setUnit("");
    setEndClient("");
    setContactTimeZone("");
    setSkills("");
    setReportingManager("");
    // setStandardComment(["One", "Two", "Three", "Four"]);
    setComment("");
    setSalesComment("");
    setLeadStatusArray(["New Lead", "Working", "Connected Manager"]);
    setLeadStatus("");
    setleadSourceArray(["LinkedIn", "Zoominfo", "Internal Data"]);
    setLeadSource("");
    setLinkedInProfile("");
    setSendOwnerShip("");
    setNotes("");
    setNextFollowTime("00:00:00");
    setClientTab(null);
    setIncOut("");
  };

  // const noChecker = (item:any) => {

  //   if(item > 9999999999){
  //     setPhone(item);
  //   }

  // }
  return (
    <>
      <div className="cContactForm">
        <Form onFinish={() => formSubmit()}>
          <div className="form-row">
            <div className="form-group">
              <Form.Item label="Client Name" required>
                {clientTab?.client_name ? (
                  <Input
                    className="input-field"
                    type="text"
                    required
                    value={clientTab.client_name}
                    readOnly={true}
                  />
                ) : (
                  <Select
                    showSearch
                    // onSearch={searchClient}
                    placeholder="Select"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    onChange={(e: any) => {
                      setClientId(e);
                    }}
                    value={clientId}
                  >
                    {clientListData?.map((item: any) => {
                      return (
                        <Select.Option key={item.id} value={item.id}>
                          {item.client_name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="First Name" required>
                <Input
                  className="input-field"
                  type="text"
                  required
                  value={firstName}
                  onChange={(e: any) => {
                    const result = e.target.value.replace(/[^a-z, 1-9]/gi, "");
                    setFirstName(result);
                  }}
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="Last Name">
                <Input
                  className="input-field"
                  type="text"
                  value={lastName}
                  onChange={(e: any) => {
                    const result = e.target.value.replace(/[^a-z, 1-9]/gi, "");
                    setLastName(result);
                  }}
                />
              </Form.Item>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <Form.Item required label="Ownership">
                <Select
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  value={sendOwnerShip}
                  onChange={(e: any) => setSendOwnerShip(e)}
                >
                  {userList.map((item: any) => {
                    if (item?.isActive === 1) {
                      return (
                        <Select.Option required value={item.id}>
                          {item.name}
                        </Select.Option>
                      );
                    }
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="Contact Time Zone">
                <Select
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  value={contactTimeZone}
                  onChange={(e: any) => setContactTimeZone(e)}
                >
                  {nextFollowZone.map((item: any) => {
                    return (
                      <Select.Option required value={item}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="Phone Number 1" required>
                <PhoneInput
                  required
                  defaultCountry="US"
                  maxLength="15"
                  placeholder="Enter phone number"
                  value={phone}
                  error={
                    phone
                      ? isValidPhoneNumber(phone)
                        ? undefined
                        : "Invalid phone number"
                      : "Phone number required"
                  }
                  onChange={(val: any) => {
                    setPhone(val);
                  }}
                />
              </Form.Item>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <Form.Item label="Phone Number 2">
                {/* <Input
                  className="input-field"
                  type="number"
                  value={phoneTwo}
                  onChange={(e: any) => setPhoneTwo(e.target.value)}
                /> */}

                <PhoneInput
                  defaultCountry="US"
                  maxLength="15"
                  placeholder="Enter phone number"
                  value={phoneTwo}
                  onChange={(val: any) => {
                    setPhoneTwo(val);
                  }}
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="Phone Number 3">
                {/* <Input
                  className="input-field"
                  type="number"
                  value={phoneThree}
                  onChange={(e: any) => setPhoneThree(e.target.value)}
                /> */}
                <PhoneInput
                  defaultCountry="US"
                  placeholder="Enter phone number"
                  value={phoneThree}
                  maxLength="15"
                  onChange={(val: any) => {
                    setPhoneThree(val);
                  }}
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="Email" required>
                <Input
                  className="input-field"
                  type="email"
                  required
                  value={email}
                  onChange={(e: any) => setEmail(e.target.value)}
                />
              </Form.Item>
            </div>
          </div>

          <div className="form-row">
            <div className="form-group">
              <Form.Item label="Alternate Email">
                <Input
                  className="input-field"
                  type="al_email"
                  value={alEmail}
                  onChange={(e: any) => setAlEmail(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="Country">
                <Select
                  showSearch
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  value={sendCountry}
                  onChange={(e: any) => countryChange(e)}
                >
                  <Select.Option value={""}>Select</Select.Option>
                  {country?.map((item: any) => {
                    //
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="State">
                <Select
                  showSearch
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  value={sendState}
                  onChange={(e: any) => changeState(e)}
                >
                  <Select.Option value={""}>Select</Select.Option>
                  {state.map((item: any) => {
                    return (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <Form.Item label="City">
                <Select
                  showSearch
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  value={sendCity}
                  onChange={(e: any) => {
                    if (!e) {
                      return setSendCity("");
                    } else {
                      return setSendCity(e);
                    }
                  }}
                >
                  <Select.Option value={""}>Select</Select.Option>

                  {city?.map((item: any) => {
                    return (
                      <Select.Option key={item.id} value={item.name}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="Title">
                <Input
                  className="input-field"
                  type="text"
                  value={title}
                  onChange={(e: any) => setTitle(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="Unit">
                <Input
                  className="input-field"
                  type="text"
                  placeholder=""
                  value={unit}
                  onChange={(e: any) => setUnit(e.target.value)}
                />
              </Form.Item>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <Form.Item label="End Client">
                <Input
                  className="input-field"
                  type="text"
                  placeholder=""
                  value={endClient}
                  onChange={(e: any) => setEndClient(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="Skills Support">
                <Input
                  className="input-field"
                  type="text"
                  placeholder=""
                  value={skills}
                  onChange={(e: any) => setSkills(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="Lead Source">
                <Select
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  value={leadSource}
                  onChange={(e: any) => setLeadSource(e)}
                >
                  <Select.Option value={""}>Select</Select.Option>

                  {leadSourceArray.map((item: any) => {
                    return (
                      <Select.Option key={item.id} value={item}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <Form.Item label="LinkedIn Profile">
                <Input
                  className="input-field"
                  type="url"
                  placeholder="Starts with 'https://'"
                  value={linkedInProfile}
                  onChange={(e: any) => setLinkedInProfile(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="Standard Comment">
                <Select
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  value={comment}
                  onChange={(e: any) => setComment(e)}
                >
                  <Select.Option value={""}>Select</Select.Option>

                  {standardComment.map((item: any) => {
                    return (
                      <Select.Option key={item.id} value={item}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="Next Follow-up Date">
                <Input
                  // required
                  className="input-field"
                  type="date"
                  defaultValue=""
                  value={nextFollowUpdate}
                  onChange={(e: any) => setNextFollowUpdate(e.target.value)}
                />
              </Form.Item>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <Form.Item label="Time">
                <Input
                  // required
                  style={{
                    marginBottom: "0px",
                    width: "max-content !important",
                  }}
                  className="input-field"
                  type="time"
                  defaultValue=""
                  value={nextFollowTime}
                  onChange={(e: any) => setNextFollowTime(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="Time Zone">
                <Select
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  value={time}
                  onChange={(e: any) => setTime(e)}
                >
                  <Select.Option value={""}>Select</Select.Option>
                  {timeZone.map((item: any) => {
                    return (
                      <Select.Option key={item.id} value={item}>
                        {item}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>

            <div className="form-group">
              <Form.Item label="Reporting Manager">
                <Select
                  showSearch
                  placeholder="Select"
                  optionFilterProp="children"
                  loading={reportingManagerLoading}
                  // filterOption={(input: any, option: any) =>
                  //   option.children.toLowerCase().includes(input.toLowerCase())
                  // }
                  // filterSort={(optionA, optionB) =>
                  //   optionA.children
                  //     .toLowerCase()
                  //     .localeCompare(optionB.children.toLowerCase())
                  // }
                  value={reportingManager}
                  onChange={(e: any) => setReportingManager(e)}
                >
                  <Select.Option value={""}>Select</Select.Option>

                  {reportingManagerFromApi?.map((item: any) => {
                    return (
                      <Select.Option
                        key={item.id}
                        value={item.id}
                        title={item.email}
                      >
                        <b>
                          {item.first_name} {item.last_name}
                        </b>
                        &nbsp;-( {item.email} )
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group note-group">
              <Form.Item label="Notes">
                <div
                  className="inc-out"
                  style={{ position: "relative", top: "-25px", right: "-60px" }}
                >
                  <input
                    type="radio"
                    name="source"
                    id="inc"
                    value={"incoming"}
                    checked={incOut == "incoming" ? true : false}
                    style={{ marginRight: "5px", cursor: "pointer" }}
                    onChange={(e: any) => setIncOut(e.target.value)}
                    required
                  />
                  <label
                    htmlFor="inc"
                    style={{ marginRight: "5px", cursor: "pointer" }}
                  >
                    Incoming
                  </label>
                  <input
                    type="radio"
                    name="source"
                    id="out"
                    value={"outgoing"}
                    checked={incOut == "outgoing" ? true : false}
                    onChange={(e: any) => setIncOut(e.target.value)}
                    required
                    style={{ marginRight: "5px", cursor: "pointer" }}
                  />
                  <label
                    htmlFor="out"
                    style={{ marginRight: "5px", cursor: "pointer" }}
                  >
                    Outgoing
                  </label>
                </div>
                <TextArea
                  // required
                  className="input-field textarea"
                  placeholder=""
                  rows={2}
                  value={notes}
                  onChange={(e: any) => setNotes(e.target.value)}
                  style={{ marginTop: "-25px" }}
                />
              </Form.Item>
            </div>
          </div>

          <div className="form-row btn-group">
            <div className="form-group btn-group">
              <div className="form-btn-group">
                <Form.Item label="">
                  <Button
                    className="global-antd-btn"
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    className="global-antd-btn"
                    onClick={clearFields}
                    type="primary"
                    style={{
                      backgroundColor: "grey",
                      border: "none",
                      marginLeft: "35px",
                    }}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ClienContact;
