import React, { useEffect, useState } from "react";
import exportFromJSON from "export-from-json";
import { Button, Checkbox, Modal, Spin } from "antd";
import Http from "../../../services/http";
import { useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";

const ExportContact = ({ props }: any) => {
  const [clientData, setClientData] = useState([]);
  const [exportModal, setExportModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  let contactReduser = useSelector((state: any) => state.contact);
  // let userReduser = useSelector((state: any) => state.user);
  const { searchVal, searchBy } = props;

  let { contactTableSearchData, getAllContact } = contactReduser;

  useEffect(() => {
    if (!exportModal) return
    getContactData()
    setIsLoading(true)
  }, [exportModal]);

  const getContactData = async () => {
    const { OwnerUser = false } = JSON.parse(
      localStorage.getItem("dataByOwnership") || "false"
    );

    const result: any = await Http.post(
      `users/contact-export`,
      {
        ...contactTableSearchData,
        searchBy,
        searchRecord: searchVal,
        client_name: contactTableSearchData.clientName,
        ownershipKey: !OwnerUser,
      },
      false
    );

    if (result.success) {
      setClientData(result.data);
      setIsLoading(false);
    } else {
    }
    // setClientData(result.data);
    // }
  };

  const defaultSetContactColumns = [
    {
      header: "Contact ID",
      accessor: "id",
      isSelected: false, // accessor is the "key" in the data
      // isSelected: false,
    },
    {
      header: "Client Name",
      accessor: "client_name", // accessor is the "key" in the data
      isSelected: false,
    },
    {
      header: "First Name",
      accessor: "first_name",
      isSelected: false,
    },
    {
      header: "Last Name",
      accessor: "last_name",
      isSelected: false,
    },
    {
      header: "Title",
      accessor: "title",
      isSelected: false,
    },
    {
      header: "Contact Time Zone",
      accessor: "contact_time_zone",
      isSelected: false,
    },
    {
      header: "Phone 1",
      accessor: "phone_1",
      isSelected: false,
    },
    {
      header: "Phone 2",
      accessor: "phone_2",
      isSelected: false,
    },
    {
      header: "Phone 3",
      accessor: "phone_3",
      isSelected: false,
    },
    {
      header: "Email",
      accessor: "email",
      isSelected: false,
    },
    {
      header: "Alternate Email",
      accessor: "alternate_email",
      isSelected: false,
    },
    {
      header: "Reporting Manager",
      accessor: "ReportingManagerName",
      isSelected: false,
    },
    {
      header: "City",
      accessor: "city",
      isSelected: false,
    },
    {
      header: "State",
      accessor: "state",
      isSelected: false,
    },
    {
      header: "Country",
      accessor: "country",
      isSelected: false,
    },
    // {
    //   header: "Time Zone",
    //   accessor: "time_zone",
    //   isSelected: false,
    // },
    {
      header: "Unit",
      accessor: "unit",
      isSelected: false,
    },
    {
      header: "End Client",
      accessor: "end_client",
      isSelected: false,
    },
    {
      header: "Skills Supported",
      accessor: "skills_support",
      isSelected: false,
    },
    {
      header: "Last Follow-up Date ",
      accessor: "last_follow_up_date",
      isSelected: false,
    },
    {
      header: "Next Follow-up Date ",
      accessor: "next_follow_up_date",
      isSelected: false,
    },
    {
      header: "Standard Comments",
      accessor: "standard_comment",
      isSelected: false,
    },
    {
      header: "Lead Status",
      accessor: "lead_status",
      isSelected: false,
    },
    {
      header: "Lead Source",
      accessor: "lead_source",
      isSelected: false,
    },
    {
      header: "LinkedIn Profile",
      accessor: "linkedIn_profile",
      isSelected: false,
    },
    {
      header: "Ownership",
      accessor: "ContactownerUser",
      isSelected: false,
    },
    {
      header: "Created On",
      accessor: "created_on",
      isSelected: false,
    },
    {
      header: "Created By",
      accessor: "ContactcreatUser",
      isSelected: false,
    },
    {
      header: "Create At",
      accessor: "createdAt",
      isSelected: false,
    },
    {
      header: "Notes",
      accessor: "sales_comments",
      isSelected: false,
    },
  ]

  const [contactColumns, setcontactColumns] = useState(defaultSetContactColumns);

  const selectAllColumn = (ele: any) => {
    let newClomnArr: any = [];
    contactColumns.map((e: any) => {
      if (ele.target.checked) {
        if (!e.isSelected) {
          e.isSelected = true;
        } else if (e.isSelected) {
          e.isSelected = true;
        }
      } else if (!ele.target.checked) {
        if (!e.isSelected) {
          e.isSelected = false;
        } else if (e.isSelected) {
          e.isSelected = false;
        }
      }
      newClomnArr.push(e);
    });
    setcontactColumns(newClomnArr);
  };

  const date = new Date();
  const dateName = `ContactData_${date.getDate()}-${date.getMonth()}-${date.getFullYear()}/time_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}`;

  const exportColumn = (ele: any) => {
    let newColumnArr: any = [];
    contactColumns.map((e: any) => {
      if (e.accessor === ele.target.value) {
        e.isSelected = !e.isSelected;
        newColumnArr.push(e);
        //
      } else {
        newColumnArr.push(e);
      }
    });
    setcontactColumns(newColumnArr);
  };

  const FilteredData = clientData.map((e: any) => {
    let obj: any = {};
    contactColumns.map((item: any) => {
      if (item.isSelected) {
        if (item.header === "Ownership" || item.header === "Created By") {
          obj[item.header] = e[item.accessor]?.name;
        } else if (item.accessor === "client_name") {
          obj[item.header] = e["clientName"]?.client_name;
        } else if (item.accessor === "ReportingManagerName") {
          obj[item.header] =
            e["ReportingManagerName"]?.first_name +
            e["ReportingManagerName"]?.last_name;
        } else {
          obj[item.header] = e[item.accessor];
        }
      }
    });
    return obj;
  });

  const data = FilteredData;
  const fileName = dateName;
  const exportType = "csv";

  const exportToExcel = () => {
    exportFromJSON({ data, fileName, exportType });
    setExportModal(false);
    setcontactColumns(defaultSetContactColumns);
  };

  const openExportModal = () => {
    setExportModal(true);
  };
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div className="export-comp">
      <button
        style={{ backgroundColor: "white", color: "#0e4d92" }}
        className="ExportModal"
        type="button"
        onClick={openExportModal}
      >
        Export
      </button>
      <Modal
        destroyOnClose
        title="Contact Export"
        className="export-modal"
        // style={{ width: 1000 }}
        visible={exportModal}
        onOk={() => {
          setcontactColumns(defaultSetContactColumns)
          setExportModal(false)
        }}
        onCancel={() => {
          setcontactColumns(defaultSetContactColumns)
          setExportModal(false)
        }}
      >
        <>
          {isLoading ? (
            <>
              <span className="export-loader">
                <Spin indicator={antIcon} />
                <i style={{ marginTop: "40px", fontSize: "15px", fontWeight: "700" }}>Please wait while we load your data.....</i>
              </span>
            </>
          ) : (
            <>
              <div className="export-checkbox">
                <div className="selectAll-box">
                  <label htmlFor="selectAll">Select All</label>
                  <input
                    id="selectAll"
                    type="checkBox"
                    onClick={(ele) => selectAllColumn(ele)}
                  />
                </div>
                <div
                  className="scroll-div"
                  style={{ height: "300px", overflowY: "scroll" }}
                >
                  {contactColumns.map((e: any) => {
                    return (
                      <div
                        style={{
                          padding: "0.4rem",
                          margin: "0.5rem",
                          border: "0px solid #222",
                          boxShadow: "rgba(0, 0, 0, 0.35) 0px 2px 5px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Checkbox
                          className="characters"
                          value={e.accessor}
                          checked={e.isSelected}
                          style={{ display: "flex", margin: "0px" }}
                          onChange={exportColumn}
                        >
                          {e.header}
                        </Checkbox>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div
                className="exp-btn"
                style={{ justifyContent: "end", display: "flex" }}
              >
                <Button
                  className="global-antd-btn export-btn"
                  onClick={exportToExcel}
                >
                  Export
                </Button>
              </div>
            </>
          )}
        </>
      </Modal>
    </div>
  );
};

export default ExportContact;
