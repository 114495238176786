import React, { useEffect, useRef, useState } from "react";
import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  DatePickerProps,
  Popover,
  Space,
} from "antd";
import { getSearchedContactsList } from "../../../services/contacts";
import e from "express";
import Http from "../../../services/http";
import { useDispatch, useSelector } from "react-redux";
import {
  checkSelectAll,
  ContactIsChecked,
  contactRefresh,
  doContactMasterReset,
  getAllContacts,
  getContactData,
  setContacTableSearchData,
  setMultiSearch,
  setMultiSearchData,
  setPageSize,
  setPagination,
} from "../../../redux/actions/contacts";
// import {
//   RovingTabIndexProvider,
//   useRovingTabIndex,
//   useFocusEffect
// } from "react-roving-tabindex";
import { useDetectClickOutside } from "react-detect-click-outside";
import { FilterFilled, FilterOutlined } from "@ant-design/icons";
import ContactSort from "./ContactSort";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { RangePickerProps } from "antd/lib/date-picker";
import moment from "moment";
const { RangePicker } = DatePicker;

const ContactHeadModel = ({ props }: any) => {
  const [fiilteredContactData, setfiilteredContactData] = useState([]);
  const [isThisModelVisible, setIsThisModelVisible] = useState(false);

  const [isSearched, setIsSearched] = useState(false);
  const { isMasterResetStart, setIsMasterResetStart } = props;

  const [dataNotFound, setDataNotFound] = useState(false);
  const [selectAllCheck, setSelectAllCheck] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<any>("");
  const [disable, setDisable] = useState(true);
  const [startDate, setStartDate] = useState<any>(moment(new Date()));
  const [endtDate, setEndDate] = useState<any>(moment(new Date()));
  const [dateDisable, setDateDisable] = useState(false);
  const [modelSearchVal, setModelSearchVal] = useState("");
  let contactReduser = useSelector((state: any) => state.contact);

  let { contactTableSearchData } = contactReduser;

  useEffect(() => {
    setIsSearched(false);
    // if (isMasterResetStart) {

    // }

    // }
  }, [isMasterResetStart]);

  useEffect(() => {
    setSelectAllCheck(false);
  }, [props.searchVal]);

  useEffect(() => {
    if (!isSearched) {
      setSelectAllCheck(false);
    }
  }, [isSearched]);

  useEffect(() => {
    setModelSearchVal("")
  }, [])

  const specialFilterClear = () => {
    let temp = true;

    if (props.visibleHeadModel == selectedFilter) {
      const node = document.querySelectorAll(
        `.current_list_${props?.item?.accessor} .contactCheckBoX .ant-checkbox-input`
      );

      node.forEach((ele: any) => {

        if (!contactTableSearchData[props?.item?.accessor].includes(ele?.value)) {
          temp = false;
        }
      });

      if (temp && contactTableSearchData[props?.item?.accessor]?.length > 0) {
        setSelectAllCheck(true);
      } else {
        setSelectAllCheck(false);
      }

    }
  };

  useEffect(() => {
    specialFilterClear();
  }, [
    contactTableSearchData[props?.item?.accessor],
    fiilteredContactData,
    selectedFilter,
    disable,
  ]);

  useEffect(() => {
    if (props.visibleHeadModel != selectedFilter) {
      setIsThisModelVisible(false);
      if (!isSearched) {
        setSelectAllCheck(false);
        contactTableSearchData[props.item.accessor] = [];
      }

      setfiilteredContactData([]);
      setDataNotFound(false);
    }
  }, [props.visibleHeadModel]);

  const onContactModelSearch = async (value: any, accessor: any) => {
    setSelectAllCheck(false);
    setModelSearchVal(value);
    if (value != "") {
      const filteredContactData: any = [];
      const body = {
        searchBy:
          accessor === "clientName"
            ? "client_name"
            : accessor === "ContactcreatUser"
              ? "ContactcreatUser"
              : accessor === "ContactownerUser"
                ? "ContactownerUser"
                : accessor,
        searchRecord: value,
        page: 1,
        per_page: 10,
      };

      const { data }: any = await getSearchedContactsList(body);

      const array: any = [];
      data.data?.map((row: any) => {
        if (accessor === "clientName") {
          if (!array.includes(row[accessor].client_name)) {
            array.push(row[accessor].client_name);
            filteredContactData.push({
              value: row[accessor].client_name,
              accessor: accessor,
            });
          }
        } else if (accessor === "ContactcreatUser") {
          if (!array.includes(row[accessor].name)) {
            array.push(row[accessor].name);
            filteredContactData.push({
              value: row[accessor].name,
              accessor: accessor,
            });
          }
        } else if (accessor === "ContactownerUser") {
          if (!array.includes(row[accessor].name)) {
            array.push(row[accessor].name);
            filteredContactData.push({
              value: row[accessor].name,
              accessor: accessor,
            });
          }
        } else if (!array.includes(row[accessor])) {
          array.push(row[accessor]);
          filteredContactData.push({
            value: row[accessor],
            accessor: accessor,
          });
        }
      });

      setDataNotFound(false);
      if (data.data?.length === 0 && value === "") {
        setfiilteredContactData([]);
      } else if (data.data?.length === 0 && value !== "") {
        setDataNotFound(true);
      } else {
        setfiilteredContactData(filteredContactData.slice(0, 10));
        // setfiilteredContactData(filteredContactData);
      }

      // } else {
      //   setfiilteredContactData([]);
      // }
    } else {
      setDataNotFound(false);
      setfiilteredContactData([]);
    }
  };

  let columnTableData: any = props?.tableData?.map((row: any) => {
    if (props?.item?.accessor == "clientName") {
      const client_name = row?.clientName?.client_name;
      return client_name;
    } else if (props?.item?.accessor == "ContactcreatUser") {
      const name = row?.ContactcreatUser?.name;
      return name;
    } else if (props?.item?.accessor == "ContactownerUser") {
      const name = row?.ContactownerUser?.name;
      return name;
    } else {
      return row[props?.item?.accessor];
    }
  });

  let fileterColumnTableData: any = [];
  columnTableData?.map((val: any, index: any) => {
    if (!fileterColumnTableData.includes(val) && index < 10) {
      return fileterColumnTableData.push(val);
    }
    return;
  });

  const oprionCheck = (e: any, value: any, accessor: any) => {
    if (
      accessor == "createdAt" ||
      accessor == "updatedAt" ||
      accessor == "next_follow_up_date" ||
      accessor == "last_follow_up_date"
    ) {
      contactTableSearchData[accessor] = value;
    } else if (!contactTableSearchData[accessor]?.includes(value.toString())) {
      contactTableSearchData[accessor]?.push(value.toString());
    } else {
      contactTableSearchData[accessor] = contactTableSearchData[
        accessor
      ].filter((ele: any) => ele != value.toString());
    }

    const node = document.querySelectorAll(
      `.current_list_${props.item.accessor} .ant-checkbox-input`
    );

    if (contactTableSearchData[accessor]?.length === node?.length) {
      setSelectAllCheck(true);
    } else {
      setSelectAllCheck(false);
    }

    let arr = contactTableSearchData?.isMultiSearch;

    if (contactTableSearchData[accessor]?.length == 1 && !e.target.checked) {
      const filtered = arr?.filter((val: any) => val != accessor);
      arr = filtered;
    } else {
      if (!arr?.includes(accessor)) {
        arr.push(accessor);
      }
    }

    if (contactTableSearchData[accessor].length > 0) {
      setDisable(false);
    } else if (contactTableSearchData[accessor].length == 0) {
      setDisable(true);
    }
    props.dispatch(setMultiSearch(arr));
  };

  const doMultiSearch = async () => {
    // props.dispatch(setPageSize(10));
    props.setPage(1);
    props.setPerPage(10);
    // return
    if (contactTableSearchData.isMultiSearch.length > 0) {
      const finalBody: any = {
        // page: props.page,
        // per_page: props.perPage,
        page: 1,
        per_page: 10,
      };

      Object.keys(contactTableSearchData).forEach((key) => {
        switch (key) {
          case "clientName":
            finalBody["client_name"] = contactTableSearchData[key];
            break;

          // case "ContactcreatUser":
          //   finalBody['ContactcreatUser'] = contactTableSearchData[key];
          //   break;

          // case "ContactownerUser":
          //   finalBody['ContactownerUser'] = contactTableSearchData[key];
          //   break;

          // case "createdAt":
          //   finalBody['createdAt'] = contactTableSearchData[key];
          //   break;

          // case "updatedAt":
          //   finalBody['updatedAt'] = contactTableSearchData[key];
          //   break;

          default:
            finalBody[key] = contactTableSearchData[key];
            break;
        }
      });

      const owner: any = localStorage.getItem("dataByOwnership");
      const isOwner = JSON.parse(owner);

      const urlBody = isOwner.OwnerUser
        ? "users/multiple-search-contact"
        : "users/multiple-search-contact-owner";

      const { data }: any = await Http.post(urlBody, finalBody, false);

      props.dispatch(setMultiSearchData(data.data));

      props.dispatch(setPagination(data.pagination));
    } else {
      props.dispatch(getAllContacts(1, 10));
    }
    setModelSearchVal("");
    setIsSearched(true);
    setfiilteredContactData([]);
    setIsThisModelVisible(!isThisModelVisible);
  };

  const clearSearchField = (accessor: any) => {
    if (isSearched) {
      props.setPage(1);
      props.setPerPage(10);
    }
    setModelSearchVal("")
    contactTableSearchData[accessor] = [];
    setSelectAllCheck(false);
    // let arr = contactTableSearchData?.isMultiSearch;

    // arr = arr.filter((val: any) => val !== accessor);
    setStartDate(moment(new Date()));
    setEndDate(moment(new Date()));

    contactTableSearchData["isMultiSearch"] =
      contactTableSearchData?.isMultiSearch.filter(
        (val: any) => val !== accessor
      );

    if (contactTableSearchData[props.item.accessor].length > 0) {
      setDisable(false);
    } else if (contactTableSearchData[props.item.accessor].length === 0) {
      setDisable(true);
    }

    // props.dispatch(setMultiSearch(arr));
    setfiilteredContactData([]);
    props.dispatch(setContacTableSearchData(contactTableSearchData));
    // setIsThisModelVisible(!isThisModelVisible);
    // doMultiSearch();
    props.dispatch(contactRefresh());
    // setIsThisModelVisible(false);
  };

  const isCheckTrue = (value: any, accessor: any) => {

    let val: any = value?.toString();

    if (contactTableSearchData[accessor]?.includes(val)) return true;
    return false;
  };

  const checkOuterClick = (e: any) => {
    const deStructure = e.target?.parentElement?.className;
    const picker = deStructure?.substring(0, 10);
    const popdeStructure = e.target?.parentElement?.className;
    const pop = popdeStructure?.substring(0, 11);
    setModelSearchVal("");

    if (
      e.target?.parentElement?.className?.includes("ant-picker-panels") ||
      e.target?.parentElement?.className?.includes("ant-picker-panel")
    )
      return;

    if (
      !e.target?.parentElement?.className?.includes("filter-icon") &&
      picker !== "ant-picker" &&
      pop !== "ant-popover"
    ) {
      if (!isSearched) {
        props.dispatch(doContactMasterReset());
        setSelectAllCheck(false);
        setDateDisable(false);
      }
      setfiilteredContactData([]);
      setDataNotFound(false);

      setIsThisModelVisible(false);
    }

    if (contactTableSearchData[props.item.accessor].length > 0) {
      setDisable(false);
    } else if (contactTableSearchData[props.item.accessor].length === 0) {
      setDisable(true);
    }
  };

  const ref = useDetectClickOutside({ onTriggered: checkOuterClick });

  const selectAll = (e: any) => {
    const node = document.querySelectorAll(
      `.current_list_${props.item.accessor} .ant-checkbox-input`
    );

    if (e?.target?.checked) {
      node.forEach((e: any) => {
        if (!e?.target?.checked) {
          if (e?.value) {
            if (
              !contactTableSearchData[props?.item?.accessor]?.includes(e.value)
            ) {
              contactTableSearchData[props?.item?.accessor]?.push(e.value);
            }
            if (
              !contactTableSearchData?.isMultiSearch?.includes(
                props.item.accessor
              )
            ) {
              contactTableSearchData?.isMultiSearch?.push(props.item.accessor);
            }
          }
        }
      });
      setSelectAllCheck(true);
    } else {
      setSelectAllCheck(false);
      node.forEach((e: any) => {
        if (e?.value) {
          contactTableSearchData[props?.item?.accessor] =
            contactTableSearchData[props?.item?.accessor].filter(
              (val: any) => val !== e.value
            );
        }
        if (
          contactTableSearchData.isMultiSearch?.includes(props.item.accessor) &&
          contactTableSearchData[props.item.accessor].length === 0
        ) {
          contactTableSearchData.isMultiSearch =
            contactTableSearchData.isMultiSearch.filter(
              (val: any) => val !== props.item.accessor
            );
        }
      });
    }

    if (contactTableSearchData[props.item.accessor].length > 0) {
      setDisable(false);
    } else if (contactTableSearchData[props.item.accessor].length === 0) {
      setDisable(true);
    }

    props.dispatch(setContacTableSearchData(contactTableSearchData));
    return;
  };

  const modelContent = () => {
    if (
      props.item.accessor === "createdAt" ||
      props.item.accessor === "updatedAt" ||
      props.item.accessor === "next_follow_up_date" ||
      props.item.accessor === "last_follow_up_date"
    ) {

      const datePickers: RangePickerProps["onChange"] = (date, dateString) => {
        if (dateString.length > 1) {
          setDateDisable(true);
        }
        dateString[0]
          ? setStartDate(moment(new Date(dateString[0])))
          : setStartDate(moment(new Date()));
        dateString[1]
          ? setEndDate(moment(new Date(dateString[1])))
          : setEndDate(moment(new Date()));
        oprionCheck("", [dateString[0], dateString[1]], props.item.accessor);
      };

      return (
        <div ref={ref}>
          <Space direction="vertical" size={12}>
            <RangePicker
              ranges={{
                Today: [moment(), moment()],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
              allowEmpty={[true, true]}
              value={[startDate, endtDate]}
              onChange={datePickers}
            />
          </Space>
          <hr style={{ marginBottom: "0px" }} />
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {
              <>
                <Button
                  disabled={!dateDisable}
                  onClick={() => doMultiSearch()}
                  style={{ marginTop: "10px", opacity: dateDisable ? 1 : 0.7 }}
                  className="global-antd-btn"
                >
                  Search
                </Button>
                <Button
                  disabled={!dateDisable}
                  onClick={() => {
                    clearSearchField(props.item.accessor);
                    // }
                  }}
                  style={{ marginTop: "10px", opacity: dateDisable ? 1 : 0.7 }}
                  className="global-antd-btn"
                  id={`${props.item.accessor}ModelSearchClear`}
                >
                  Clear
                </Button>
              </>
            }
          </div>
        </div>
      );
    } else {
      return (
        <div
          ref={ref}
        >
          <div>
            <AutoComplete
              style={{ width: 400 }}
              value={modelSearchVal}
              onSearch={(value) =>
                onContactModelSearch(value, props?.item?.accessor)
              }
              placeholder="Search"
            />
          </div>
          <br />
          {dataNotFound ? (
            ""
          ) : (
            <Checkbox
              checked={selectAllCheck}
              onChange={(e) => selectAll(e)}
              style={{ margin: "10px 0px" }}
            >
              Select All
            </Checkbox>
          )}
          {dataNotFound ? (
            <div>Data Not Found</div>
          ) : (
            <div
              className={`contactModelSearchList current_list_${props?.item?.accessor}`}
            >
              {fiilteredContactData?.map((ele: any, index: any) => {
                if (ele?.value === "--" || ele?.value === null) {
                  return;
                }

                if (ele?.value) {
                  return (
                    <Checkbox
                      value={ele.value}
                      style={{ display: "flex", margin: "0px" }}
                      checked={isCheckTrue(ele.value, ele.accessor)}
                      onChange={(e) => oprionCheck(e, ele.value, ele.accessor)}
                      className="contactCheckBoX"
                    >
                      {ele.value}
                    </Checkbox>
                  );
                }
              })}
              {fiilteredContactData?.length === 0 &&
                fileterColumnTableData?.map((ele: any, index: any) => {
                  if (ele === "--" || ele === null) {
                    return;
                  }

                  if (ele) {
                    return (
                      <Checkbox
                        value={ele}
                        style={{ display: "flex", margin: "0px" }}
                        checked={isCheckTrue(ele, props.item.accessor)}
                        className="contactCheckBoX"
                        onChange={(e) =>
                          oprionCheck(e, ele, props.item.accessor)
                        }
                      >
                        {ele}
                      </Checkbox>
                    );
                  }
                })}
              <hr style={{ marginBottom: "0px" }} />
            </div>
          )}
          {
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <Button
                disabled={disable}
                onClick={() => doMultiSearch()}
                style={{
                  marginTop: "10px",
                  opacity: !disable ? 1 : 0.7,
                }}
                className="global-antd-btn"
              >
                Search
              </Button>
              <Button
                disabled={disable}
                className="global-antd-btn clear-btn-filter"
                onClick={() => clearSearchField(props.item.accessor)}
                id={`${props.item.accessor}ModelSearchClear`}
                style={{ opacity: !disable ? 1 : 0.7 }}
              >
                Clear
              </Button>
            </div>
          }
        </div>
      );
    }
  };

  useEffect(() => {
    let ele: any = document?.querySelector(".table-responsive");
    if (isThisModelVisible) {
      ele.addEventListener("scroll", handleScroll, false);
      // Remove the event listener
    }
    return () => {
      ele.removeEventListener("scroll", handleScroll, false);
    };
  }, [isThisModelVisible]);

  const handleScroll = (event: any) => {
    setModelSearchVal("");
    if (event.target.className !== "clientModelSearchList") {
      if (isSearched) {
        setfiilteredContactData([]);
        setDataNotFound(false);
        setIsThisModelVisible(false);
      } else {


        if (props.visibleHeadModel != "") {
          let ele = document.getElementById(
            `${props.item.accessor}ModelSearchClear`
          );
          ele?.click();
          setfiilteredContactData([]);
          setDataNotFound(false);
          setIsThisModelVisible(false);
        }
      }
    }
  };

  const dispatch: any = useDispatch();

  const checkFromState: any = useSelector((state: any) => state.contact);
  const [allids, setallIds]: any = useState([])

  useEffect(() => {
    const allIds: any = checkFromState.getAllContact?.data?.data?.map(
      (ele: any) => ele.id
    );
    setallIds(allIds)
    if (checkFromState.checkSelectAll) {
      dispatch(ContactIsChecked(allIds));
    } else if (!checkFromState.checkSelectAll) {
      dispatch(ContactIsChecked([]));
    }
  }, [checkFromState.checkSelectAll]);

  const contactSelectAll = (checked: any) => {
    const node: any = document.querySelectorAll(`.contact-checkbox`);

    node?.forEach((e: any) => {
      e.click();
    });
    props.dispatch(checkSelectAll(checked));
  };

  const isSlectAllCheck = allids?.length === checkFromState?.ContactIsChecked?.length ? true : false
  return (
    <>
      {props.item.accessor === "action" ? (
        <th style={{ textAlign: "center" }} key={props.index}>
          {props.item?.header}
          <AiOutlineThunderbolt />
        </th>
      ) : props.item.accessor === "id" ? (
        <th
          style={{ textAlign: "center", paddingLeft: "10px" }}
          key={props.index}
        >
          <input
            checked={isSlectAllCheck}
            onChange={(e: any) => contactSelectAll(e.target.checked)}
            style={{ marginRight: "10px" }}
            type="checkbox"
          />
          <ContactSort
            sortData={props?.item.accessor}
            Tabledata={props?.tableData}
          />
          {props.item?.header}
        </th>
      ) : (
        <th key={props.index}>
          {
            <ContactSort
              sortData={props?.item.accessor}
              Tabledata={props?.tableData}
            />
          }
          {props.item?.header}
          <Popover
            placement="bottom"
            content={modelContent}
            title={`Search By ${props.item?.header}`}
            // trigger="click"
            visible={isThisModelVisible}
            destroyTooltipOnHide={true}
          >
            {props.item?.isFilter &&
              (contactTableSearchData[props.item?.accessor]?.length > 0 ? (
                <FilterFilled
                  onClick={() => {
                    setIsThisModelVisible(!isThisModelVisible);
                    setSelectedFilter(props?.item.accessor);
                    props.setVisibleHeadModel(props?.item.accessor);
                  }}
                  className="filter-icon"
                />
              ) : (
                <FilterOutlined
                  onClick={() => {
                    setIsThisModelVisible(!isThisModelVisible);
                    setSelectedFilter(props?.item.accessor);
                    props.setVisibleHeadModel(props?.item.accessor);
                  }}
                  className="filter-icon"
                />
              ))}
          </Popover>
        </th>
      )}
    </>
  );
};

export default ContactHeadModel;
