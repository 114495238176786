import { stat } from "fs";
import { UserType } from "../actions/user";
import { UserModel } from "../models/index";

let user: any = localStorage.getItem("user");
user = user ? JSON.parse(user) : null;

export const initState: UserModel = {
  user: user,
  token: user ? user.accessToken : "",
};

export default (state = initState, action: any) => {
  // 
  switch (action.type) {
    case UserType.SET_USER: {
      return {
        ...state,
        user: action.data,
        token: action.data.accessToken,
      };
    }
    case UserType.LOGOUT_USER: {
      localStorage.removeItem("user");
      localStorage.removeItem("access_token");
      localStorage.removeItem("clientDefaultCheckedList");
      localStorage.removeItem("ClientColumns");
      localStorage.removeItem("UserContactColumn");
      localStorage.removeItem("dataByOwnership");
      localStorage.removeItem("UserContactSettings");
      localStorage.removeItem("isSearchedByOwnership");
      return {
        ...state,
        user: null,
        token: null,
      };
    }
    case UserType.GET_USER_DATA: {

      return {
        ...state,
        UserDataList: action.data,
      };
    }

    default:
      return state;
  }
};
