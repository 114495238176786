import React, { useEffect, useState } from "react";
import { Table as BsTable } from "react-bootstrap";

import "./client.css";
import ClientMainBody from "./ClientTableParts/ClientMainBody";
import ClientTableHead from "./ClientTableParts/ClientTableHead";
import { useDispatch, useSelector } from "react-redux";
import Http from "../../services/http";
import { changePaginationData, clientTableData, clientTableStates, refreshTable } from "../../redux/actions/client";

const Table = ({ props, submitSearch, searchInput }: any) => {

    const dispatch = useDispatch();

    let data = props?.tableData
    // 


    // let hiddenTableColumns = props?.hiddenTableColumns

    let clientDefaultCheckedList = JSON.parse(localStorage.getItem("clientDefaultCheckedList") || '')


    const { clientTableStatesData, doClientMultiSearch } = useSelector((state: any) => state.client)


    const doMultiSearch = async () => {

        const finalBody: any = {
            page: 1,
            per_page: 10,
        };

        Object.keys(clientTableStatesData.body).forEach((key) => {
            finalBody[key] = clientTableStatesData.body[key];
        });

        // 

        if (clientTableStatesData?.isFilterOn?.length > 0) {

            let searchedByOwnership: any = await localStorage.getItem("isSearchedByOwnership");

            if (searchedByOwnership == "true") {
                searchedByOwnership = `multiple-search`;
            } else {
                searchedByOwnership = `multiple-search-client-owner`
            }

            Http.post(`users/${searchedByOwnership}`, finalBody, false).then(async (res: any) => {
                const { data } = await res;

                dispatch(
                    clientTableStates({
                        ...clientTableStatesData,
                        multileSearchTableData: data.data,
                        filterSearchResult: []
                    })
                );

                dispatch(clientTableData(data.data))

                dispatch(changePaginationData(data.pagination))
                // dispatch(searchPagination({ success: true, count: data?.length }));
            });
        }
        else {
            dispatch(refreshTable())
        }
    };

    // 


    useEffect(() => {

        doMultiSearch();

    }, [doClientMultiSearch])

    return (
        <>
            <BsTable hover size="sm" responsive className="client-table-md">
                <ClientTableHead
                    clientData={data}
                    clientTableStatesData={props?.clientTableStatesData}
                    submitSearch={submitSearch}
                    clientDefaultCheckedList={clientDefaultCheckedList}
                    searchInput={searchInput}
                    isSearched={props.isSearched}
                    setIsSearched={props.setIsSearched}
                />

                {/* Client Main Header */}
                <ClientMainBody
                    data={data}
                    submitSearch={props.submitSearch}
                    clientTableStatesData={props?.clientTableStatesData}
                    clientDefaultCheckedList={clientDefaultCheckedList}
                />
            </BsTable>
        </>
    );
};

export { Table };