import { Button, Form, Input, message, Modal, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { useEffect, useState } from "react";
import { MdLibraryAdd } from "react-icons/md";
import { contactRefresh } from "../../../redux/actions/contacts";
import { addContactNewNotes } from "../../../services/contacts";
import { useDispatch } from "react-redux";
import Http from "../../../services/http";


const AddNoteFromNoti = ({ dispatch, data }: any) => {
  const [isOpenModen, setIsOpenModen]: any = useState(false);
  const [note, setNote] = useState("");
  const [noteSource, setnoteSource]: any = useState("");
  const [nextFollowUpdate, setNextFollowUpdate] = useState("");
  const [nextFollowTime, setNextFollowTime] = useState("");
  const [timeZone, setTimeZone] = useState([
    "IST",
    "HST",
    "AKST",
    "PST",
    "MST",
    "CST",
    "EST",
  ]);
  const [addedTimeZone, setAddedTimeZone] = useState(timeZone[0]);
  const [selectedStandardComment, setSelectedStandardComment] = useState("");
  const [noteLables, setNoteLables] = useState([]);
  const [standardComment, setStandardComment] = useState([
    "Follow-up",
    "Follow-up on a specific Date/Time",
    "Hung-up",
    "Manager with Active Requirements",
    "Connected Manager",
    "Voicemail",
    "DND",
    "Number not in service",
    "Wants to stick with standard Channel",
    "Wrong Number",
    "Handles Offshore Requirements",
    "No number given",
    "Not involved in hiring",
    "Call Later",
    "Call is not going through",
    "No Longer with company",
    "Reference",
  ]);

  const optionsForLable: any = [];
  const lables: any = [
    "Follow-Up",
    "Email Follow-Up",
    "Submission",
    "Interview Scheduled",
    "Interview Done",
    "Introduction",
  ];

  lables.map((lab: any) => {
    optionsForLable.push({
      value: lab,
      label: lab,
    });
  });

  const clearFields = () => {
    setNote("");
    setNextFollowUpdate("");
    setNextFollowTime("");
    setSelectedStandardComment("");
    setnoteSource("");
    setNoteLables([]);
  };

  const handleChangeForLabel = (value: any) => {
    setNoteLables(value);
  };

  //   console.log("datas", data);

  const saveNotes = async () => {
    // Changes 24-03-2022
    addContactNewNotes(
      data.contact_id,
      note,
      nextFollowUpdate,
      nextFollowTime,
      addedTimeZone,
      noteLables,
      selectedStandardComment,
      noteSource
    )
      .then(({ success = false }: any) => {
        if (success) {
          message.success("Contact Note Added Succeessfully", 2);
          Http.put(`users/contact-notes-update/${data.id}`, { isDone: true }, false).then(() => {
            clearFields();
            dispatch(contactRefresh());
            setIsOpenModen(false);
          }).catch(() => {
            message.error("Error while updating Is Done", 2);
          })

        } else {
          message.error("Something went wrong, Note does not saved!", 2);
        }
      })
      .catch((e: any) => {
        message.error("Something went wrong, Note does not saved!", 2);
      });
  };

  useEffect(() => {
    clearFields();
  }, [isOpenModen]);

  return (
    <>
      <MdLibraryAdd
        style={{
          fontSize: "20px",
          color: "#0E4D92",
          cursor: "pointer",
          marginLeft: "7px",
        }}
        onClick={() => setIsOpenModen(true)}
      />
      <Modal
        title={`Add Note For (${data?.notesData?.first_name + " " + data?.notesData?.last_name
          })`}
        open={isOpenModen}
        onOk={() => setIsOpenModen(false)}
        onCancel={() => setIsOpenModen(false)}
        className="addNotesTeemps"
      >
        <div className="cForm">
          <Form onFinish={saveNotes}>
            <div className="form-row">
              <div
                className="form-group note-group"
                style={{
                  marginBottom: "0px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Form.Item label="Add Note" required>
                  <TextArea
                    required
                    rows={5}
                    className="input-field textarea"
                    // type="text"
                    value={note}
                    onChange={(e: any) => setNote(e.target.value)}
                  />
                </Form.Item>
                <div
                  className="radio-div"
                  style={{
                    justifyContent: "space-between",
                    padding: "0px 20px",
                    marginTop: "14px",
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid #d9d9d9",
                    marginLeft: "5px",
                    height: "120px",
                    width: "226px",
                    borderRadius: "8px",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "76px",
                      right: "182px",
                      display: "flex",
                      alignItems: "center",
                      color: "#043c79",
                      fontSize: "14px",
                    }}
                  >
                    {" "}
                    <span
                      style={{
                        color: "#ff4d4f",
                        fontSize: "19px",
                        fontFamily: "SimSun, sans-serif",
                        marginTop: "7px",
                        marginRight: "7px",
                      }}
                    >
                      *
                    </span>{" "}
                    Note Source:
                  </div>
                  <div>
                    <label htmlFor="incoming" style={{ cursor: "pointer" }}>
                      Incoming
                    </label>{" "}
                    <br />
                    <input
                      type="radio"
                      name="call"
                      id="incoming"
                      style={{ cursor: "pointer" }}
                      required
                      checked={noteSource == "incoming" ? true : false}
                      value={"incoming"}
                      onChange={(e: any) => setnoteSource(e.target.value)}
                    />
                  </div>
                  <div>
                    <label htmlFor="outgoing" style={{ cursor: "pointer" }}>
                      Outgoing
                    </label>{" "}
                    <br />
                    <input
                      type="radio"
                      required
                      name="call"
                      id="outgoing"
                      checked={noteSource == "outgoing" ? true : false}
                      value={"outgoing"}
                      style={{ cursor: "pointer" }}
                      onChange={(e: any) => setnoteSource(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div
                className="form-group"
                style={{ display: "flex", marginBottom: "10px" }}
              >
                <Form.Item
                  label="Next Follow-up Date"
                  style={{ display: "flex", marginBottom: "0px" }}
                // required
                >
                  <ul
                    style={{ display: "flex", gap: "7px", marginBottom: "0px" }}
                  >
                    <li>
                      <Input
                        // required
                        className="input-field"
                        style={{
                          marginBottom: "0px",
                          width: "max-content !important",
                        }}
                        type="date"
                        value={nextFollowUpdate}
                        onChange={(e: any) =>
                          setNextFollowUpdate(e.target.value)
                        }
                      />
                    </li>
                    <li>
                      <Input
                        // required
                        style={{
                          marginBottom: "0px",
                          width: "max-content !important",
                        }}
                        className="input-field"
                        type="time"
                        value={nextFollowTime}
                        onChange={(e: any) => setNextFollowTime(e.target.value)}
                      />
                    </li>
                    <li>
                      <Select
                        value={addedTimeZone}
                        style={{
                          marginBottom: "0px",
                          minWidth: "90px",
                          maxWidth: "max-content !important",
                        }}
                        onChange={(value: any) => setAddedTimeZone(value)}
                      >
                        {timeZone.map((option) => {
                          return (
                            <Select.Option
                              selected={option == addedTimeZone ? true : false}
                              value={`${option}`}
                            >
                              {option.toUpperCase()}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </li>
                  </ul>
                </Form.Item>
              </div>

              <div
                className="form-group"
                style={{ display: "flex", marginBottom: "0px" }}
              >
                <Form.Item
                  label="Change Startdard Comment"
                  style={{ display: "flex", marginBottom: "0px" }}
                >
                  <ul
                    style={{ display: "flex", gap: "7px", marginBottom: "0px" }}
                  >
                    <li>
                      <Select
                        showSearch
                        value={selectedStandardComment}
                        style={{
                          marginBottom: "0px",
                          minWidth: "260px",
                          maxWidth: "max-content !important",
                        }}
                        onChange={(value: any) =>
                          setSelectedStandardComment(value)
                        }
                      >
                        <Select.Option value={""}>Select</Select.Option>
                        {standardComment.map((option: any) => {
                          return (
                            <Select.Option value={`${option}`}>
                              {option.toUpperCase()}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </li>
                    {/* <li>
                    <Select
                      mode="tags"
                      style={{
                        width: "200px",
                      }}
                      onChange={handleChangeForLabel}
                      tokenSeparators={[","]}
                      options={optionsForLable}
                    />
                  </li> */}
                  </ul>
                </Form.Item>
                <Form.Item
                  label="Label"
                  style={{ display: "flex", marginBottom: "0px" }}
                >
                  <ul
                    style={{ display: "flex", gap: "7px", marginBottom: "0px" }}
                  >
                    <li>
                      <Select
                        className="label-scrool"
                        mode="tags"
                        style={{
                          width: "200px",
                        }}
                        value={noteLables}
                        onChange={handleChangeForLabel}
                        tokenSeparators={[","]}
                        options={optionsForLable}
                      />
                    </li>
                  </ul>
                </Form.Item>
              </div>
            </div>
            <div className="form-row btn-group">
              <div
                className="form-group btn-group"
                style={{ marginTop: "0px !important" }}
              >
                <div className="form-btn-group">
                  <Form.Item>
                    <Button
                      className="global-antd-btn"
                      type="primary"
                      htmlType="submit"
                    >
                      Save
                    </Button>
                    <Button
                      className="global-antd-btn"
                      onClick={() => clearFields()}
                      type="primary"
                      style={{
                        backgroundColor: "grey",
                        border: "none",
                        marginLeft: "35px",
                      }}
                    >
                      Clear
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddNoteFromNoti;
