import { message } from "antd";
import {
  getAllClientList,
  getFilteredClientList,
  createClientAPI,
  getClientTableData,
  // getClientTableData,
} from "../../services/client";
import { saveUserSetting } from "../../services/contacts";
import { doContactMasterReset } from './contacts';

export enum ClientType {
  CREATE_APPLICANT = "CREATE_APPLICANT",
  CREATE_APPLICANT_SUCCESS = "CREATE_APPLICANT_SUCCESS",
  CREATE_APPLICANT_FAILED = "CREATE_APPLICANT_FAILED",
  CLEAR_APPLICANT_LIST_MSG = "CLEAR_APPLICANT_LIST_MSG",
  FETCH_APPLICANT_LISTING = "FETCH_APPLICANT_LISTING",
  APPLICANT_LIST_FAILED = "APPLICANT_LIST_FAILED",
  APPLICANT_LIST_SUCCESS = "APPLICANT_LIST_SUCCESS",
  DELETE_APPLICANT_SUCCESS = "DELETE_APPLICANT_SUCCESS",
  FETCHING_APPLICANT_INFO = "FETCHING_APPLICANT_INFO",
  FETCH_APPLICANT_INFO_SUCCESS = "FETCH_APPLICANT_INFO_SUCCESS",
  FETCH_APPLICANT_INFO_FAILED = "FETCH_APPLICANT_INFO_FAILED",
  START_UPDATE_APPLICANT = "START_UPDATE_APPLICANT",
  UPDATE_APPLICANT_SUCCESS = "UPDATE_APPLICANT_SUCCESS",
  UPDATE_APPLICANT_FAILED = "UPDATE_APPLICANT_FAILED",
  PAGINATION_DATA = "PAGINATION_DATA",
  CLIENT_TABLE_DATA = "CLIENT_TABLE_DATA",
  CLIENT_TABLE_STATES = "CLIENT_TABLE_STATES",
  CHANGE_PAGINATION_DATA = "CHANGE_PAGINATION_DATA",
  SEARCH_PAGINATION = "SEARCH_PAGINATION",
  HIDDEN_TABLE_COLUMNS = "HIDDEN_TABLE_COLUMNS",
  REFRESH_TABLE = "REFRESH_TABLE",
  DO_CLIENT_MULTI_SEARCH = "DO_CLIENT_MULTI_SEARCH",
  SEARCH_BY_OWNERSHIP = "SEARCH_BY_OWNERSHIP",
  CLEAR_CLIENT_MULTI_SEARCH = "CLEAR_CLIENT_MULTI_SEARCH",
  MASTER_RESET = "MASTER_RESET"
}

export const clearData = () => async (dispatch: any) => {
  dispatch({
    type: ClientType.CLEAR_APPLICANT_LIST_MSG,
  });
};

const createClient = () => async (dispatch: any) => {
  dispatch({
    type: ClientType.CREATE_APPLICANT,
  });
};

const createClientSuccess = (data: any) => async (dispatch: any) => {
  dispatch({
    type: ClientType.CREATE_APPLICANT_SUCCESS,
    data,
  });
};

const createClientFailed = (msg: any) => async (dispatch: any) => {
  dispatch({
    type: ClientType.CREATE_APPLICANT_FAILED,
    msg,
  });
};

export const addClient = (data: any) => async (dispatch: any) => {
  try {
    dispatch(createClient());
    // let createModel: any = {
    //     ClientName: data.bannerName,
    //     type: data.type
    // };
    // if (data.type === "shop") {
    //     createModel.shopId = data.shopId;
    // }
    const result: any = await createClientAPI(data);

    if (result.success) {
      // icon upload
      dispatch(createClientSuccess(result));
      message.success("Client created successfully");
    } else {
      message.error(result.error);
      dispatch(createClientFailed(result.error));
    }
  } catch (e: any) {
    message.error(e.error);
    dispatch(createClientFailed(e.error));
  }
};

const fetchingClientList = () => (dispatch: any) => {
  dispatch({
    type: ClientType.FETCH_APPLICANT_LISTING,
  });
};

const clientListFailed = (msg: string) => (dispatch: any) => {
  dispatch({
    type: ClientType.APPLICANT_LIST_FAILED,
    data: { msg },
  });
};

const clientListSuccess = (data: any) => (dispatch: any) => {
  dispatch({
    type: ClientType.APPLICANT_LIST_SUCCESS,
    data,
  });
};

export const getClientList = (queryParam: any) => async (dispatch: any) => {
  try {
    dispatch(fetchingClientList());
    const result: any = await getAllClientList(queryParam);
    if (result.success) {
      // 
      const newArr = result.data.map((v: any, i: number) =>
        Object.assign(v, { key: v.id })
      );
      result.data.data = newArr;
      dispatch(clientListSuccess(result));
    }
  } catch (e: any) {
    // 

    message.error(e.message);
    dispatch(clientListFailed(e.message));
  }
};

export const getFilteredClients =
  (body: any, queryParam: any) => async (dispatch: any) => {
    // try {
    const result: any = await getFilteredClientList(body);

    dispatch(clientTableData(result.data.data));
    dispatch(changePaginationData(result.data?.pagination))
    // dispatch(refreshTable())
    // } catch (e: any) {
    //   dispatch(clientListFailed(e.message));
    // }
  };

export const getClientInfoData = (pagesize: any, currpage: any) => async (dispatch: any) => {
  const result: any = await getClientTableData(pagesize, currpage);

  dispatch(clientTableData(result?.data?.data));
  dispatch(tablePagination(result?.data?.pagination))
}

export const searchPagination = (data: any) => (dispatch: any) => {

  dispatch({
    type: ClientType.SEARCH_PAGINATION,
    data
  })
}

export const tablePagination = (data: any) => (dispatch: any) => {
  dispatch({
    type: ClientType.PAGINATION_DATA,
    data,
  });
};

export const clientTableData = (data: any) => async (dispatch: any) => {
  // const result: any = await getFilteredClientList(data, queryParam);
  // 

  dispatch({
    type: ClientType.CLIENT_TABLE_DATA,
    data,
  });
};

export const changePaginationData = (data: any) => async (dispatch: any) => {
  // const result: any = await getFilteredClientList(data, queryParam);

  dispatch({
    type: ClientType.PAGINATION_DATA,
    data,
  });
};

export const clientTableStates = (data: any) => async (dispatch: any) => {

  dispatch({
    type: ClientType.CLIENT_TABLE_STATES,
    data,
  });
};

export const setHiddenTableColumns = (data: any) => async (dispatch: any) => {
  // const result: any = await getFilteredClientList(data, queryParam);
  

  const saveSettings = saveUserSetting("UserClientSetting", data);

  dispatch({
    type: ClientType.HIDDEN_TABLE_COLUMNS,
    data,
  });
};

export const refreshTable = () => async (dispatch: any) => {
  // const result: any = await getFilteredClientList(data, queryParam);


  dispatch({
    type: ClientType.REFRESH_TABLE
  });
};

export const clearClientMultiSearch = () => async (dispatch: any) => {
  // const result: any = await getFilteredClientList(data, queryParam);
  // dispatch(refreshTable())
  dispatch(doContactMasterReset())
  dispatch({
    type: ClientType.CLEAR_CLIENT_MULTI_SEARCH
  });
};

export const doClientMultiSearch = () => (dispatch: any) => {
  dispatch({
    type: ClientType.DO_CLIENT_MULTI_SEARCH,
  });
}

export const doMasterReset = () => async (dispatch: any) => {
  // const result: any = await getFilteredClientList(data, queryParam);



  dispatch({
    type: ClientType.CLEAR_CLIENT_MULTI_SEARCH
  });
};

// function dispatch(arg0: (dispatch: any) => Promise<void>) {
//   throw new Error("Function not implemented.");
// }
// const deleteBannerSuccess = (id: number) => async (dispatch: any) => {
//     dispatch({
//         type: ClientType.DELETE_APPLICANT_SUCCESS,
//         id
//     });
// };

// export const removeItem = (id: number) => async (dispatch: any) => {
//     try {
//         const result: any = await deleteBanner(id);
//         if (result.success) {
//             dispatch(deleteBannerSuccess(id));
//             message.success("Banner deleted successfully");
//         } else {
//             message.error(result.message);
//         }

//     } catch (e: any) {
//         message.error(e.message);
//     }
// };

// const getBannerInfo = () => (dispatch: any) => {
//     dispatch({
//         type: ClientType.FETCHING_APPLICANT_INFO
//     });
// };

// const getBannerInfoSuccess = (data: any) => (dispatch: any) => {
//     dispatch({
//         type: ClientType.FETCH_APPLICANT_INFO_SUCCESS,
//         data
//     });
// };

// const getBannerInfoFailed = (msg: any) => (dispatch: any) => {
//     dispatch({
//         type: ClientType.FETCH_APPLICANT_INFO_FAILED,
//         msg
//     });
// };

// export const fetchBannerInfo = (id: number) => async (dispatch: any) => {
//     try {
//         dispatch(getBannerInfo());
//         const result: any = await getBannerinfoAPI(id);
//         if (result.success) {
//             dispatch(getBannerInfoSuccess(result.data));
//         }
//     } catch (e: any) {
//         message.error(e.message);
//         dispatch(getBannerInfoFailed(e.message));
//     }
// };

// const updateBannerStart = () => async (dispatch: any) => {
//     dispatch({
//         type: ClientType.START_UPDATE_APPLICANT
//     });
// };

// const updateBannerSuccess = (data: any) => async (dispatch: any) => {
//     dispatch({
//         type: ClientType.UPDATE_APPLICANT_SUCCESS,
//         data
//     });
// };

// const updateBannerFailed = (msg: any) => async (dispatch: any) => {
//     dispatch({
//         type: ClientType.UPDATE_APPLICANT_FAILED,
//         msg
//     });
// };

// export const updateBanner = (data: any, id: number) => async (dispatch: any) => {
//     try {

//         dispatch(updateBannerStart());
//         let createModel: any = {
//             bannerName: data.bannerName,
//             type: data.type
//         };
//         if (data.type === "shop") {
//             createModel.shopId = data.shopId;
//         }
//         const result: any = await updateBannerAPI(createModel, id);

//         if (result.success) {

//             if (data.bannerImage) {
//                 const formDataBannerImage = new FormData();
//                 formDataBannerImage.append("image", data.bannerImage);
//                 formDataBannerImage.append("bannerId", id.toString());
//                 formDataBannerImage.append("field", "bannerImage");
//                 await uploadAPI(formDataBannerImage);
//             }

//             if (data.type !== "shop" && data.url) {
//                 const formDataURL = new FormData();
//                 formDataURL.append("image", data.url);
//                 formDataURL.append("bannerId", id.toString());
//                 formDataURL.append("field", "url");
//                 await uploadAPI(formDataURL);
//             }

//             // icon upload
//             dispatch(updateBannerSuccess(result));
//             message.success("Banner updated successfully");
//         } else {
//             message.error(result.message);
//             dispatch(updateBannerFailed(result.message));
//         }

//     } catch (e: any) {
//         message.error(e.message);
//         dispatch(updateBannerFailed(e.message));
//     }
// };
