import EditIcon from "@mui/icons-material/Edit";
import { Button, Space, Switch, Table, TableColumnsType } from "antd";
import React, { useEffect, useState } from "react";
import Pagination from "../../components/Pagination/Pagination";
import { connect, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { FiEdit3 } from "react-icons/fi";
import { getUserData, UserIsActive } from "../../redux/actions/user";
import EditUserModal from "./CreateUserModal/EditUserModal";
import UserModal from "./CreateUserModal/UserModal";
import UserPasswordModal from "./CreateUserModal/UserPasswordModal";
import "./users.css";
import UserPagi from "./CreateUserModal/UserPagi";
import { doContactMasterReset } from './../../redux/actions/contacts';

interface Props {
  UserDataList: any;
  getUserData: Function;
  UserIsActive: Function;
}

const Users = (props: Props) => {
  const [usermodal, setUserModal] = useState(false);
  const [Editmodal, setEditModal] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [changeUserPassword, setChangeUserPassword] = useState("");
  const [changePasswordUserName, setChangePasswordUserName] = useState("");

  const [edituserdetails, setEdituserdetails] = useState({});
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const dispatch = useDispatch()

  // Clear Contact Page
  useEffect(() => {
    dispatch(doContactMasterReset())
  }, [])

  useEffect(() => {
    document.title = 'Users - Client Module';
  }, [])

  useEffect(() => {
    getTableData();
  }, [usermodal, Editmodal, page, perPage]);

  const getTableData = () => {
    props.getUserData(perPage, page);
  };

  const changeIsActive = async (record: any) => {
    const body = record.isActive === 0 ? { isActive: 1 } : { isActive: 0 };
    const resp = await props.UserIsActive(body, record.id);
    if (resp) {
      getTableData();
    }
  };

  const editUser = (record: any) => {
    setEditModal(true);
    setEdituserdetails(record);
  };

  const changePassword = (id: any) => {
    setPasswordModal(true);
    setChangeUserPassword(id);
  };

  const columns: any = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (text: any, record: any) => (
        <a style={{ color: record.isActive === 0 ? "grey" : "" }}>{text}</a>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, record: any) => (
        <span style={{ color: record.isActive === 0 ? "grey" : "" }}>
          {text}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text: any, record: any) => (
        <span style={{ color: record.isActive === 0 ? "grey" : "" }}>
          {text}
        </span>
      ),
    },
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      render: (text: any, record: any) => (
        <span style={{ color: record.isActive === 0 ? "grey" : "" }}>
          {text}
        </span>
      ),
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (_: any, record: any) => (
        <Space size="middle">
          <span style={{ color: record.isActive === 0 ? "grey" : "" }}>
            {record.role === "0"
              ? "Admin"
              : record.role === "1"
                ? "Team Lead"
                : "Account Manager"}
          </span>
        </Space>
      ),
    },
    {
      title: "Active Status",
      dataIndex: "isActive",
      key: "isActive",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Switch
            className="switch-btn"
            checkedChildren={"On"}
            unCheckedChildren={"Off"}
            checked={record.isActive === 1 ? true : false}
            onChange={() => changeIsActive(record)}
          />
        </Space>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_: any, record: any) => (
        <Space size="middle">
          <button
            className="custom-btn"
            onClick={() => {
              editUser(record);
            }}
            style={{ color: record.isActive === 0 ? "grey" : "" }}
            disabled={record.isActive === 0 ? true : false}
          >
            <FiEdit3
              style={{
                color: record.isActive === 0 ? "grey" : "",
                fontSize: "18px",
              }}
            />
            <span style={{ color: record.isActive === 0 ? "grey" : "" }}>
              Edit
            </span>
          </button>
          <button
            className="custom-btn"
            onClick={() => {
              changePassword(record.id);
              setChangePasswordUserName(record.name); // Changes // 23-03-2023
            }}
            disabled={record.isActive === 0 ? true : false}
          >
            <span style={{ color: record.isActive === 0 ? "grey" : "" }}>
              Change Password
            </span>
          </button>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setUserModal(true);
  };

  const isAdmin = JSON.parse(localStorage.getItem("user")!);
  return (
    <>
      {isAdmin.role === "0" ? (
        <div className="wrapper-table">
          <div className="user-div">
            <div className="search-bar user-top-bar">
              {/* <h1
                style={{
                  position: "relative",
                  right: "36%",
                  top:"10%",
                  fontFamily: "Mulish",
                  color: "#0e4d92",
                  marginBottom:"0px"
                }}
              >
                Users
              </h1> */}
              <div className="create-btn">
                <Button onClick={showModal}>Create User</Button>
              </div>
            </div>
            <EditUserModal
              editUser={editUser}
              setEditModal={setEditModal}
              Editmodal={Editmodal}
              edituserdetails={edituserdetails}
            />
            <div className="user-modal-form">
              <UserModal setUserModal={setUserModal} usermodal={usermodal} />
            </div>
            <UserPasswordModal
              userName={changePasswordUserName} // Changes // 23-03-2023
              passwordModal={passwordModal}
              setPasswordModal={setPasswordModal}
              changeUserPassword={changeUserPassword}
            />
            <div className="user-table-div">
              <Table
                className="user-real-table"
                columns={columns}
                dataSource={props.UserDataList?.data}
                pagination={false}
              />
            </div>
            <div className="table-footer">
              <UserPagi
                paginationData={props.UserDataList?.pagination}
                pagiDetails={{
                  page: page,
                  setPage: setPage,
                  perPage: perPage,
                  setPerPage: setPerPage,
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <Redirect to={"/dashboard"} />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
  UserDataList: state.user.UserDataList,
});

const mapDispatchToProps = (dispatch: any) => ({
  getUserData: (perPage: any, page: any) =>
    dispatch(getUserData(perPage, page)),
  UserIsActive: (body: any, id: any) => dispatch(UserIsActive(body, id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
