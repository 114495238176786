import { ReportModel } from "../models";
import { ReportType } from "../actions/report";

export const initState: ReportModel = {
  userData: [],
  lebelCount: [],
  totalNextFollowUp: [],
};

export default (state = initState, action: any) => {
  switch (action.type) {
    case ReportType.GET_USERWISE_DATA: {
      return {
        ...state,
        userData: action.data,
      };
    }

    case ReportType.GET_LABEL_COUNT: {
      return {
        ...state,
        lebelCount: action.data,
      };
    }

    case ReportType.GET_TOTAL_NEXTFOLLOWUP: {
      return {
        ...state,
        totalNextFollowUp: action.data,
      };
    }

    default:
      return state;
  }
};
