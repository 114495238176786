import axios from 'axios';
import { config } from 'process';
import Http from './http';


export const getAllClientList = (queryParam: any) => {
    return Http.get(`client/getClient?limit=${queryParam.limit}&page=${queryParam.page}`, false);
}


export const getFilteredClientList = async (body: any) => {

    const isSearchedByOwnership = localStorage.getItem("isSearchedByOwnership")

    if (isSearchedByOwnership == "true") {
        return await Http.post(`users/search-client`, body, false);
    } else {
        return await Http.post(`users/client-by-user-search`, body, false);
    }

}

export const createClientAPI = (model: any) => {
    return Http.post(`client/createClient`, model, false);
}

export const getClientTableData = (pagesize: any, currpage: any) => {
    // return Http.get(`users/list_client?per_page=10&page=1`);
    const isSearchedByOwnership = localStorage.getItem("isSearchedByOwnership")

    if (isSearchedByOwnership == "true") {
        return Http.get(`users/list_client?per_page=${pagesize}&page=${currpage}`, false);
    } else {
        return Http.get(`users/client_list_byuser?per_page=${pagesize}&page=${currpage}`, false);
    }
}

export const getClientInfoData = (pagesize: any, currpage: any) => {
    // return Http.get(`users/list_client?per_page=10&page=1`);
    const isSearchedByOwnership = localStorage.getItem("isSearchedByOwnership")

    if (isSearchedByOwnership == "true") {
        return Http.get(`users/list_client?per_page=${pagesize}&page=${currpage}`, false);
    } else {
        return Http.get(`users/client_list_byuser?per_page=${pagesize}&page=${currpage}`, false);
    }
}

export const getUserTableData = (pagesize: any, page: any) => {
    return Http.get(`user/user-list?per_page=${pagesize}&page=${page}`, false);
}

export const setUserActive = (body: any, id: any) => {
    return Http.put(`user/active-deactive-user/${id}`, body, true)
}

export const getClientColumnData = () => {
    return JSON.parse(localStorage.getItem("ClientColumns") || "{}");
};

export const addClientNewNotes = (client_id: any, notes: any) => {
    return Http.post(`users/add-client-notes`, { client_id, notes }, false);
};

export const updateClientNewNotes = (id: any, notes: any) => {
    return Http.put(`users/update-client-notes/${id}`, { notes }, false);
};

// export const uploadAPI = (model: any) => {
//     return Http.post(`banner/upload`, model);
// }

// export const getAllBanner = (body: any = {}) => {
//     return Http.post(`banner/list/admin`, body);
// }

// export const deleteBanner = (id: number) => {
//     return Http.delete(`banner/${id}`);
// }

// export const getBannerinfoAPI = (id: number) => {
//     return Http.get(`banner/${id}/info`);
// }

// export const updateBannerAPI = (model: any, id: number) => {
//     return Http.put(`banner/${id}/update`, model);
// }