import React from "react";
import { Route, Redirect } from "react-router-dom";

function AuthRoute(props: any) {
  let { path, user, component: Component } = props;
  // 

  // 

  return (
    <Route
      path={path}
      exact
      render={() => {
        return user ? <Redirect to="/dashboard" /> : <Component />;  
      }}
    ></Route>
  );
}

export default AuthRoute;
