import { Button, Form, Input, Modal, Select } from "antd";
import axios from "axios";
import React, { useState } from "react";
import Http from "../../../services/http";
import "./style.css";

const UserModal = (props: any) => {
  const [userdata, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    number: "",
  });

  const [confirmPassword, setConfirmPassword] = useState("");

  const [role, setRole] = useState([
    {
      id: "0",
      name: "Admin",
    },
    {
      id: "1",
      name: "Team Lead",
    },
    {
      id: "2",
      name: "Account Manager",
    },
  ]);
  const handleOk = () => {
    props.setUserModal(false);
  };

  const handleCancel = () => {
    props.setUserModal(false);
  };


  const userFormSubmit = async (e: any) => {
    // e.preventDefault(); 
    // Changes // 24-03-2023
    if (confirmPassword === "") {
      alert("Password not valid");
    } else {
      if (userdata.password === confirmPassword) {
        await Http.post(`user/register`, userdata, true).then(async (res: any) => {
          console.log(res);
          if (res.success) {
            props.setUserModal(false);
          }
        })
          .catch((err) => {
            // 
          });
      } else {
        alert("Password is not same");
      }
    };
  }
  return (
    <>
      <div className="user-modal-div">
        <Modal
          className="user-real-modal"
          title="Add User"
          visible={props.usermodal}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="user-modal-div-form">
            <Form onFinish={userFormSubmit}>
              <div className="user-form">
                <div className="form-row-user">
                  <div className="user-row-div">
                    <Form.Item label="Name" required>
                      <Input
                        className="input-field"
                        type="text"
                        placeholder="Enter UserName"
                        value={userdata.name}
                        required
                        onChange={(e) =>
                          setUserData({ ...userdata, name: e.target.value })
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className="user-row-div">
                    <Form.Item label="Email" required>
                      <Input
                        className="input-field"
                        type="email"
                        placeholder="Enter Email ID"
                        value={userdata.email}
                        required
                        onChange={(e) =>
                          setUserData({ ...userdata, email: e.target.value })
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="form-row-user">
                  <div className="user-row-div">
                    <Form.Item label="Password" required>
                      <Input
                        className="input-field"
                        type="password"
                        placeholder="Enter Password"
                        required
                        value={userdata.password}
                        onChange={(e) =>
                          setUserData({ ...userdata, password: e.target.value })
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className="user-row-div">
                    <Form.Item label="Confirm Password" required>
                      <Input
                        className="input-field"
                        type="password"
                        required
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e: any) =>
                          setConfirmPassword(e.target.value)
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="form-row-user">
                  <div className="user-row-div">
                    <Form.Item label="Phone" required>
                      <Input
                        className="input-field"
                        type="text"
                        placeholder="Enter Phone Number"
                        value={userdata.number}
                        required
                        onChange={(e) =>
                          setUserData({ ...userdata, number: e.target.value })
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className="user-row-div">
                    <Form.Item label="Role" required>
                      <Select
                        placeholder="Select"
                        value={userdata.role}
                        onChange={(e: any) =>
                          setUserData({ ...userdata, role: e })
                        }
                      >
                        {role.map((item: any, index: any) => {
                          return (
                            <Select.Option required key={index} value={item.id}>
                              {item.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="form-row-user user-btn-group">
                  <Form.Item>
                    <Button className="global-antd-btn" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default UserModal;
