import {
  CloseCircleOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import "../../pages/client/client.css";
import Http from "../../services/http";
import { SearchOutlined } from "@mui/icons-material";
import {
  Dropdown,
  Button,
  Space,
  Modal,
  Upload,
  message,
  Switch,
  Select,
  Form,
} from "antd";
import { Tooltip } from "@mui/material";
import { TiExport } from "react-icons/ti";
import { IoTrashBin } from "react-icons/io5";
import { CgSelectO } from "react-icons/cg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useRef, useState } from "react";
import { firstMenu, secondMenu } from "../Contact/ContactParts/Menus";
import ImportContactModal from "./ImportContactModal";
//   import { HideShowTableColmns } from "./TableParts";
import { useDispatch, useSelector } from "react-redux";
import ContactInputMenuDropDown from "./ContactParts/ContactInputMenuDropDown";
import {
  clearContactMultipleSearch,
  ContactIsChecked,
  contactRefresh,
  doContactMasterReset,
  getAllContacts,
  searchContacts,
  setContacTableSearchData,
  setMultiSearchData,
  setPagination,
} from "../../redux/actions/contacts";
import ExportContact from "./ContactParts/ExportContact";
import HideContactColumn from "./ContactParts/HideContactColumn";
import { BiRefresh, BiTransfer } from "react-icons/bi";
import { useDetectClickOutside } from "react-detect-click-outside";
import TrasferOwnership from "./ContactParts/TrasferOwnership";
const { confirm } = Modal;

const ContactTopSearch = ({ props }: any) => {
  const dispatch: any = useDispatch();
  const wrapperRef = useRef(null);
  const [isImportOpen, setIsImportOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { searchVal, setSearchVal, searchBy, setSearchBy } = props;
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [standardComment, setStandardComment] = useState([
    "Follow-up",
    "Follow-up on a specific Date/Time",
    "Hung-up",
    "Manager with Active Requirements",
    "Connected Manager",
    "Voicemail",
    "DND",
    "Number not in service",
    "Wants to stick with standard Channel",
    "Wrong Number",
    "Handles Offshore Requirements",
    "No number given",
    "Not involved in hiring",
    "Call Later",
    "Call is not going through",
    "No Longer with company",
    "Reference",
  ]);
  const [comment, setComment] = useState("");
  const [userList, setUserList] = useState([]);
  const [sendOwnerId, setSendOwnerId] = useState("");
  const contactSearchParams = useSelector((state: any) => state.contact);

  const menu = (
    <div className="dropdown-content-any-btn">
      <div className="dropdown-content-list-1">
        <ul>
          {firstMenu.map((ele: any, index: any) => {
            return (
              <li key={index}>
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    setSearchBy(ele.value);
                    setVisibleDropdown(!visibleDropdown);
                  }}
                >
                  {ele.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="dropdown-content-list-2 dropdown-content-list-bd-lf">
        <ul>
          {secondMenu.map((ele: any, index: any) => {
            return (
              <li key={index}>
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    setVisibleDropdown(!visibleDropdown);
                    setSearchBy(ele.value);
                  }}
                >
                  {ele.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );



  const importMenu = (
    <>
      <ImportContactModal
        isImportOpen={isImportOpen}
        setIsImportOpen={setIsImportOpen}
      />
      <ul className="import-export-btn">
        <li>
          {/* <Link to="/client/import">Import</Link> */}
          <button
            style={{ backgroundColor: "white", color: "#0e4d92" }}
            onClick={() => setIsImportOpen(true)}
          >
            Import
          </button>
        </li>
        <li>
          <ExportContact props={props}/>
        </li>
      </ul>
    </>
  );

  useEffect(() => {
    masterReset();
  }, []);

  useEffect(() => {
    getUserData();
  }, []);

  const clearChangeStatusFields = () => {
    setIsModalOpen(false);
    setComment("");
    setSendOwnerId("");
  };

  const getUserData = async () => {
    const result: any = await Http.get(
      `user/user-list?per_page=${1000}&page=${1}`,
      true
    );
    setUserList(result.data.data);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (
        searchVal.length > 0 &&
        contactSearchParams.contactTableSearchData.isMultiSearch.length === 0
      ) {
        const body = {
          searchBy,
          searchRecord: searchVal,
          page: 1,
          per_page: 10,
        };
        dispatch(searchContacts(body));
      } else if (
        contactSearchParams.contactTableSearchData.isMultiSearch.length > 0
      ) {
        doMultiSearch();
      } else {
        dispatch(getAllContacts(1, 10));
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchVal, searchBy]);

  const doMultiSearch = async () => {
    // props.dispatch(setPageSize(10));
    props.setPage(1);
    props.setPerPage(10);
    // return
    if (contactSearchParams.contactTableSearchData.isMultiSearch.length > 0) {
      const finalBody: any = {
        ...contactSearchParams.contactTableSearchData,
        searchBy: searchBy,
        searchRecord: searchVal,
        page: 1,
        per_page: 10,
      };

      const owner: any = localStorage.getItem("dataByOwnership");
      const isOwner = JSON.parse(owner);

      const urlBody = isOwner.OwnerUser
        ? "users/multiple-search-contact"
        : "users/multiple-search-contact-owner";

      const { data }: any = await Http.post(urlBody, finalBody, false);

      dispatch(setMultiSearchData(data.data));

      dispatch(setPagination(data.pagination));
    } else {
      dispatch(getAllContacts(1, 10));
    }
  };

  const isowner: any = localStorage.getItem("dataByOwnership");
  const bool = JSON.parse(isowner);
  const onSwitchChange = () => {
    props.setPerPage(10);
    props.setPage(1);
    if (bool.OwnerUser) {
      const data = JSON.stringify({ OwnerUser: false });
      localStorage.setItem("dataByOwnership", data);
    } else {
      const data = JSON.stringify({ OwnerUser: true });
      localStorage.setItem("dataByOwnership", data);
    }
    dispatch(clearContactMultipleSearch());
    dispatch(contactRefresh());
    // window.location.reload();
  };

  const masterReset = () => {
    // props.setSearchInput("");
    setSearchVal("");
    setSearchBy("");
    props.setPerPage(10);
    props.setPage(1);
    props.setIsSearched(!props.isSearched);
    dispatch(doContactMasterReset());

    dispatch(contactRefresh());
    // props.setIsSearched(!props.isSearched);
  };

  const checkOuterClick = (e: any) => {
    setVisibleDropdown(false);
  };

  const ref: any = useDetectClickOutside({
    onTriggered: checkOuterClick,
    triggerKeys: ["Enter", "Escape"],
  });

  const deleteAllMessage = () => {
    return (
      <p>
        Are you sure you want to delete{" "}
        <b style={{ color: "red" }}>
          {contactSearchParams?.ContactIsChecked?.length}{" "}
        </b>
        selected Contacts ?
      </p>
    );
  };

  const confirmDelete = () => {
    confirm({
      // title: `Are you sure you want to delete ${contactSearchParams?.ContactIsChecked?.length} selected Contacts ?`,
      title: deleteAllMessage(),
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      className: "clientDeletebtn",
      maskClosable: true,
      async onOk() {
        deleteSelected();
      },
      onCancel() { },
    });
  };

  const deleteSelected = async () => {
    const Ids = {
      ids: contactSearchParams?.ContactIsChecked,
    };
    const result: any = await Http.post("users/multiple-delete", Ids, true);

    if (result.success) {
      dispatch(ContactIsChecked([]));
      dispatch(contactRefresh());
    }
  };

  const changeSelectedStatus = async () => {
    const Ids = {
      ids: contactSearchParams?.ContactIsChecked,
      standard_comment: comment,
      ownership: sendOwnerId,
    };
    const result: any = await Http.post("users/multiple-update", Ids, true);

    if (result.success) {
      dispatch(ContactIsChecked([]));
      clearChangeStatusFields();
      dispatch(contactRefresh());
    }
  };

  const user: any = localStorage.getItem("user");
  const { role }: any = JSON.parse(user);

  return (
    <div className="search-bar">
      <div className="search-bar-left">
        <div className="search-any-btn" ref={wrapperRef}>
          <form
            ref={ref}
            className="input-box"
            onSubmit={(e) => e.preventDefault()}
          >
            <SearchOutlined
              style={{
                fontSize: "35px",
                color: "#0e4d92",
                paddingLeft: "15px",
              }}
            />
            <input
              type="text"
              name="search"
              // defaultValue=""
              value={searchVal}
              onChange={(e) => {
                setSearchVal(e.target.value);
              }}
              placeholder="Search Contact"
              required
            />
            {searchVal !== "" && (
              <CloseCircleOutlined
                onClick={(e: any) => {
                  setSearchVal("");
                  setSearchBy("");
                }}
                className="filter-btn"
              />
            )}
            <Dropdown overlay={menu} visible={visibleDropdown}>
              <Button
                onClick={() => setVisibleDropdown(!visibleDropdown)}
                style={{ textTransform: "capitalize" }}
              >
                <Space onClick={() => setVisibleDropdown(!visibleDropdown)}>
                  {searchBy == "ContactownerUser"
                    ? "Ownership"
                    : searchBy == "ContactcreatUser"
                      ? "Created By"
                      : searchBy
                        ? searchBy.replace("_", " ")
                        : "Search Any"}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </form>
        </div>
      </div>
      <div className="top-search-group">
        <>
          {contactSearchParams?.ContactIsChecked?.length > 0 && role === "0" ? (
            <Tooltip title={"Delete All "} placement="top" arrow>
              <div className="reset_btn_div">
                {
                  <IoTrashBin
                    onClick={() => confirmDelete()}
                    style={{
                      display: "flex",
                      color: "red",
                      fontSize: "1.3rem",
                      // paddingBottom: "6px",
                      margin: "5px 0.7rem 0rem 0rem",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  />
                }
              </div>
            </Tooltip>
          ) : (
            ""
          )}
          {contactSearchParams?.ContactIsChecked?.length > 0 ? (
            <>
              <Tooltip title={"Change Status"} placement="top" arrow>
                <div className="reset_btn_div">
                  {
                    <CgSelectO
                      onClick={() => setIsModalOpen(true)}
                      style={{
                        display: "flex",
                        color: "#0E4D92",
                        fontSize: "1.3rem",
                        // paddingBottom: "6px",
                        margin: "5px 0.7rem 0rem 0rem",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    />
                  }
                </div>
              </Tooltip>
              <Modal
                title="Change Status"
                open={isModalOpen}
                onOk={clearChangeStatusFields}
                onCancel={clearChangeStatusFields}
                className="hide-column change-status"
                style={{ height: "230px", overflow: "hidden" }}
              >
                <div className="two-states">
                  <Form.Item label="Standard Comment">
                    <Select
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      value={comment}
                      onChange={(e: any) => setComment(e)}
                    >
                      {standardComment.map((item: any) => {
                        return (
                          <Select.Option key={item.id} value={item}>
                            {item}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item label="Ownership">
                    <Select
                      showSearch
                      filterOption={(input: any, option: any) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      value={sendOwnerId}
                      onChange={(e: any) => setSendOwnerId(e)}
                    >
                      {userList.map((item: any) => {
                        return (
                          <Select.Option key={item.id} value={item.id}>
                            {item.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <Form.Item
                  label=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    className="global-antd-btn"
                    type="primary"
                    onClick={() => changeSelectedStatus()}
                  >
                    Submit
                  </Button>
                  <Button
                    className="global-antd-btn"
                    onClick={clearChangeStatusFields}
                    type="primary"
                    style={{
                      backgroundColor: "grey",
                      border: "none",
                      marginLeft: "35px",
                    }}
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Modal>
            </>
          ) : (
            ""
          )}
          {contactSearchParams.contactTableSearchData.isMultiSearch.length >
            0 ? (
            <Tooltip title={"Reset All Filters"} placement="top" arrow>
              <div className="reset_btn_div">
                {
                  <BiRefresh
                    onClick={() => {
                      masterReset();
                    }}
                    style={{
                      display: "flex",
                      color: "#0E4D92",
                      fontSize: "1.45rem",
                      // paddingBottom: "6px",
                      margin: "5px 0.7rem 0rem 0rem",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  />
                  // : ''
                }
              </div>
            </Tooltip>
          ) : (
            ""
          )}
        </>

        <div className="button-right">
          <div className="top-three-btn">
            {/* <Tooltip title="Import/Export files" placement="left"> */}
            <Dropdown
              className="first-of-top-three"
              overlay={importMenu}
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  <div
                    className="import-export-deopdown"
                    style={{ gap: "none" }}
                  >
                    <TiExport style={{ color: "#0e4d92", fontSize: "20px" }} />
                    <ExpandMoreIcon style={{ color: "#0e4d92" }} />
                  </div>
                </Space>
              </a>
            </Dropdown>
            {/* </Tooltip> */}
          </div>
        </div>
        <div className="clients-btn">
          <ContactInputMenuDropDown />
        </div>
        <div
          className="clients-btn"
          style={{
            display: "flex",
            color: "#0E4D92",
            fontSize: "1.35rem",
            paddingBottom: "6px",
            margin: "6px 12px 0rem 0px",
            alignItems: "center",
          }}
        >
          <HideContactColumn />
        </div>
        <div className="clients-btn">
          {
            role == "0" &&
            <TrasferOwnership />
          }
        </div>
        <Tooltip
          title={!bool.OwnerUser ? "Show All Data" : "Show Owner Data"}
          placement="top"
          arrow
        >
          <Switch
            style={{
              display: "flex",
              color: "#0E4D92",
              fontSize: "1.35rem",
              paddingBottom: "6px",
              margin: "0rem 2rem 0rem 0.1rem",
              alignItems: "center",
            }}
            checked={bool.OwnerUser}
            onChange={onSwitchChange}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default ContactTopSearch;
