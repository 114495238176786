import {
  getLebelCountAPI,
  getTotalNextFollowUpAPI,
  getUserDataAPI,
} from "../../services/report";

export enum ReportType {
  GET_USERWISE_DATA = "GET_USERWISE_DATA",
  GET_LABEL_COUNT = "GET_LABEL_COUNT",
  GET_TOTAL_NEXTFOLLOWUP = "GET_TOTAL_NEXTFOLLOWUP",
}

export const getUserWiseData = (data: any) => async (dispatch: any) => {
  const result: any = await getUserDataAPI(data);

  dispatch({
    type: ReportType.GET_USERWISE_DATA,
    data: result?.data,
  });
};

export const getLebelCount = (data: any) => async (dispatch: any) => { 

  const result: any = await getLebelCountAPI(data);

  dispatch({
    type: ReportType.GET_LABEL_COUNT,
    data: result?.data,
  });
};

export const getTotalNextFollowUp = (data: any) => async (dispatch: any) => {
  const result: any = await getTotalNextFollowUpAPI(data);

  dispatch({
    type: ReportType.GET_TOTAL_NEXTFOLLOWUP,
    data: result?.data,
  });
};
