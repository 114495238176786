import { Button, Form, Input, message, Modal, Select, Space } from "antd";
import Table from "react-bootstrap/Table";
import React, { useEffect, useState } from "react";
import { BiCommentDots } from "react-icons/bi";
import { FiEdit } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";
import Http from "../../../services/http";
import { contactRefresh } from "../../../redux/actions/contacts";
import { useDispatch } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import {
  addContactNewNotes,
  updateContactNewNotes,
} from "../../../services/contacts";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "@mui/material";
import getConvertedTime from "../getConvertedTime";
const { confirm } = Modal;

const ContactNotesModal = ({ data, standard_comment, managerName }: any) => {
  const dispatch = useDispatch();
  const [isNoteOpen, setIsNoteOpen] = useState(false);
  const [isNoteTableModel, setIsNoteTableModel] = useState(false);
  const [noteSource, setnoteSource]: any = useState("");
  const [note, setNote] = useState("");
  const [nextFollowUpdate, setNextFollowUpdate] = useState("");
  const [nextFollowTime, setNextFollowTime] = useState("");
  const [nextFollowZone, setNextFollowZone] = useState([
    "IST",
    "HST",
    "AKST",
    "PST",
    "MST",
    "CST",
    "EST",
  ]);
  const [selectedStandardComment, setSelectedStandardComment] = useState("");

  const [standardComment, setStandardComment] = useState([
    "Follow-up",
    "Follow-up on a specific Date/Time",
    "Hung-up",
    "Manager with Active Requirements",
    "Connected Manager",
    "Voicemail",
    "DND",
    "Number not in service",
    "Wants to stick with standard Channel",
    "Wrong Number",
    "Handles Offshore Requirements",
    "No number given",
    "Not involved in hiring",
    "Call Later",
    "Call is not going through",
    "No Longer with company",
    "Reference",
  ]);

  // const [timeZone, setTimeZone] = useState(["IST", "HST", "AKST", "PST", "MST", "CST", "EST"]);
  const [addedTimeZone, setAddedTimeZone] = useState(nextFollowZone[0]);

  const [itemForUpdate, setItemForUpdate] = useState<any>("");

  const [noteLables, setNoteLables] = useState([]);
  const [selectedNoteLables, setSelectedNoteLables] = useState([]);

  // useEffect(()=>{
  //   const convertedTime = getConvertedTime("2022-11-21", "21:00:00", addedTimeZone);
  //

  // }, [])

  const optionsForLable: any = [];
  useEffect(() => {
    if (noteLables?.length === 0) return;
    setSelectedNoteLables(noteLables);
  }, [noteLables]);

  const lables: any = [
    "Follow-Up",
    "Email Follow-Up",
    "Submission",
    "Interview Scheduled",
    "Interview Done",
    "Introduction",
  ];

  lables.map((lab: any) => {
    optionsForLable.push({
      value: lab,
      label: lab,
    });
  });

  const handleChangeForLabel = (value: any) => {
    setNoteLables(value);
  };

  const addNote = (item: any, noteLables: any) => {
    setNoteLables(noteLables);
    setItemForUpdate(item);
    setNote(item?.notes);
    setnoteSource(item?.noteSource);
    setNextFollowUpdate(item?.next_follow_up_date);
    setNextFollowTime(item?.time);
    setSelectedStandardComment(standard_comment);
    setAddedTimeZone(item?.timezone ? item?.timezone : nextFollowZone[0]);
    setIsNoteOpen(true);
  };

  const deleteNote = (id: any) => {
    confirm({
      title: `Are you sure you want to delete this note?`,
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      className: "clientDeletebtn",
      maskClosable: true,
      async onOk() {
        const result = Http.delete(`users/contact-notes-deleted/${id}`).then(
          () => {
            dispatch(contactRefresh());
          }
        );
      },
      onCancel() { },
    });
  };

  const randomColors: any = {
    "Follow-Up": "#FF7B7B",
    "Email Follow-Up": "#B399D4",
    "Interview Scheduled": "#73C883",
    Submission: "#F79862",
    Introduction: "#49AFEA",
    "Interview Done": "#88A38B",
  };

  const clearNoteFields = () => {
    // setIsNoteOpen(false)
    setNoteLables([]);
    setNote("");
    setNextFollowUpdate("");
    setNextFollowTime("");
    setAddedTimeZone(nextFollowZone[0]);
    setSelectedStandardComment("");
    setnoteSource("");
    // setItemForUpdate("");
  };

  const updateNotes = () => { // Changes // 24-03-2023
    updateContactNewNotes(
      itemForUpdate?.id,
      note,
      nextFollowUpdate,
      nextFollowTime,
      addedTimeZone,
      noteLables,
      selectedStandardComment,
      noteSource
    )
      .then(({ success = false }: any) => {
        if (success) {
          message.success("Contact Note Added Succeessfully", 2);
          setIsNoteOpen(false);
          dispatch(contactRefresh());
        } else {
          message.error("Something went wrong, Note does not saved!", 2);
        }
      })
      .catch((e: any) => {
        message.error("Something went wrong, Note does not saved!", 2);
      });
  };

  const addNotes = (
    <Modal
      title="Edit Note"
      open={isNoteOpen}
      onOk={() => setIsNoteOpen(false)}
      onCancel={() => setIsNoteOpen(false)}
    >
      <div className="cForm">
        <Form onFinish={updateNotes}>
          <div className="form-row">
            <div
              className="form-group note-group"
              style={{
                marginBottom: "0px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Form.Item label="Add Note" required>
                <TextArea
                  required
                  rows={5}
                  className="input-field textarea"
                  value={note}
                  onChange={(e: any) => setNote(e.target.value)}
                />
              </Form.Item>
              <div
                className="radio-div"
                style={{
                  justifyContent: "space-between",
                  padding: "0px 20px",
                  marginTop: "14px",
                  display: "flex",
                  alignItems: "center",
                  border: "1px solid #d9d9d9",
                  marginLeft: "5px",
                  height: "120px",
                  width: "226px",
                  borderRadius: "8px",
                  backgroundColor: "#f2f2f2",
                }}
              >
                <div>
                  <label htmlFor="incoming" style={{ cursor: "pointer" }}>
                    Incoming
                  </label>{" "}
                  <br />
                  <input
                    type="radio"
                    name="call"
                    required
                    id="incoming"
                    style={{ cursor: "pointer" }}
                    checked={noteSource == "incoming" ? true : false}
                    value={"incoming"}
                    onChange={(e: any) => setnoteSource(e.target.value)}
                  />
                </div>
                <div>
                  <label htmlFor="outgoing" style={{ cursor: "pointer" }}>
                    Outgoing
                  </label>{" "}
                  <br />
                  <input
                    type="radio"
                    name="call"
                    required
                    id="outgoing"
                    checked={noteSource == "outgoing" ? true : false}
                    value={"outgoing"}
                    style={{ cursor: "pointer" }}
                    onChange={(e: any) => setnoteSource(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div
              className="form-group"
              style={{ display: "flex", marginBottom: "2px" }}
            >
              <Form.Item
                label="Next Follow-up Date"
                style={{ display: "flex", marginBottom: "0px" }}
              >
                <ul
                  style={{ display: "flex", gap: "7px", marginBottom: "0px" }}
                >
                  <li>
                    <Input
                      // required
                      style={{
                        marginBottom: "0px",
                        width: "max-content !important",
                      }}
                      className="input-field"
                      type="date"
                      defaultValue=""
                      value={nextFollowUpdate}
                      onChange={(e: any) => setNextFollowUpdate(e.target.value)}
                    />
                  </li>
                  <li>
                    <Input
                      // required
                      style={{
                        marginBottom: "0px",
                        width: "max-content !important",
                      }}
                      className="input-field"
                      type="time"
                      defaultValue=""
                      value={nextFollowTime}
                      onChange={(e: any) => setNextFollowTime(e.target.value)}
                    />
                  </li>
                  <li>
                    <Select
                      value={addedTimeZone}
                      style={{
                        marginBottom: "0px",
                        minWidth: "90px",
                        maxWidth: "max-content !important",
                      }}
                      onChange={(value: any) => setAddedTimeZone(value)}
                    >
                      {nextFollowZone.map((option) => {
                        return (
                          <Select.Option
                            selected={option == addedTimeZone ? true : false}
                            value={`${option}`}
                          >
                            {option.toUpperCase()}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </li>
                  <li>
                    {/* <Select style={{ marginBottom: "0px", minWidth: "90px", maxWidth: "max-content !important" }} onChange={(value: any) => setTimeZone(value)}> */}
                    {/* <Select value={nextFollowZone} onChange={(val) => setNextFollowZone(val)} style={{ marginBottom: "0px", minWidth: "90px", maxWidth: "max-content !important" }}>
                      <Select.Option value="hts">HST</Select.Option>
                      <Select.Option value="akdt">AKDT</Select.Option>
                    </Select> */}
                  </li>
                </ul>
              </Form.Item>
            </div>

            <div
              className="form-group"
              style={{ display: "flex", marginBottom: "0px" }}
            >
              <Form.Item
                label="Change Startdard Comment"
                style={{ display: "flex", marginBottom: "0px" }}
              >
                <ul
                  style={{ display: "flex", gap: "7px", marginBottom: "0px" }}
                >
                  <li>
                    <Select
                      showSearch
                      value={selectedStandardComment}
                      style={{ marginBottom: "0px", width: "250px" }}
                      onChange={(value: any) =>
                        setSelectedStandardComment(value)
                      }
                    >
                      {/* <Select.Option value="" selected={true}>--</Select.Option> */}
                      {standardComment.map((option) => {
                        return (
                          <Select.Option value={`${option}`}>
                            {option.toUpperCase()}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </li>
                </ul>
              </Form.Item>

              <Form.Item
                label="Label"
                style={{ display: "flex", marginBottom: "0px" }}
              >
                <ul
                  style={{ display: "flex", gap: "7px", marginBottom: "0px" }}
                >
                  <li>
                    <Select
                      className="label-scrool"
                      mode="tags"
                      style={{
                        width: "200px",
                      }}
                      value={noteLables}
                      onChange={handleChangeForLabel}
                      tokenSeparators={[","]}
                      options={optionsForLable}
                    />
                  </li>
                </ul>
              </Form.Item>
            </div>
          </div>
          <div
            className="form-row btn-group"
            style={{ marginTop: "5px", marginBottom: "0px" }}
          >
            <div
              className="form-group btn-group"
              style={{ marginTop: "0px", marginBottom: "0px" }}
            >
              <div className="form-btn-group">
                <Form.Item>
                  <Button
                    className="global-antd-btn"
                    type="primary"
                    htmlType="submit"
                  >
                    Save
                  </Button>
                  <Button
                    className="global-antd-btn"
                    onClick={clearNoteFields}
                    type="primary"
                    style={{
                      backgroundColor: "grey",
                      border: "none",
                      marginLeft: "35px",
                    }}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );

  return (
    <>
      <BiCommentDots
        onClick={() => setIsNoteTableModel(true)}
        style={{ color: "green", cursor: "pointer" }}
      />
      <Modal
        className="contact-modal-div note-modal"
        destroyOnClose
        title={`All Contact Notes Of ${managerName}`} // 24-03-2023 // Changes
        visible={isNoteTableModel}
        onCancel={() => setIsNoteTableModel(false)}
      >
        <div className="note-div">
          <Table striped="columns">
            <thead>
              <tr>
                <th>No</th>
                <th>Notes</th>
                <th>Last Follow-up date</th>
                <th>Next Follow-up date</th>
                <th>Labels</th>
                <th>TimeZone</th>
                <th>Created On</th>
                <th>Updated At</th>
                <th>Created By</th>
                <th>Updated By</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item: any, index: any) => {
                index += 1;
                const timeA = new Date(item?.createdAt);
                const timeU = new Date(item?.updatedAt);
                const timeL = new Date(item?.last_follow_up_date);
                const timeN: any = item?.next_follow_up_date
                  ? new Date(item?.next_follow_up_date)
                  : "";
                const timeT: any =
                  item?.time != null
                    ? new Date(`2022-10-24,${item?.time}`)
                    : "";

                return (
                  <tr>
                    <td>{index}</td>
                    <td style={{ maxWidth: "200px", maxHeight: "200px", overflow: "scroll" }}>{item?.notes}</td>
                    <td>
                      {new Intl.DateTimeFormat("en-In", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(timeL)}
                    </td>
                    <td>
                      {timeT && timeN
                        ? `${new Intl.DateTimeFormat("en-In", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        }).format(timeN)}, ${new Intl.DateTimeFormat(
                          "en-In",
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          }
                        ).format(timeT)}`
                        : timeN != ""
                          ? `${new Intl.DateTimeFormat("en-In", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          }).format(timeN)}`
                          : "--"}
                    </td>
                    <td>
                      {item?.noteLables &&
                        item?.noteLables?.split(",")?.map((label: any) => {
                          // console.log("AAAAAAAAAA", item?.noteSource);
                          return (
                            <>
                              <button
                                className="border p-1 m-1"
                                style={{
                                  color: "white",
                                  fontSize: "12px",
                                  background: randomColors[label]
                                    ? randomColors[label]
                                    : "#435564",
                                  borderRadius: "7px",
                                  cursor: "auto",
                                }}
                              >
                                {label}
                              </button>
                            </>
                          );
                        })}
                      {item?.noteSource && (
                        <button
                          className="border p-1 m-1"
                          style={{
                            color: "white",
                            fontSize: "12px",
                            backgroundColor:
                              item?.noteSource == "incoming"
                                ? "#1da518"
                                : "#f91d1d",
                            borderRadius: "7px",
                            cursor: "auto",
                          }}
                        >
                          {item?.noteSource}
                        </button>
                      )}
                    </td>
                    <td style={{ textTransform: "uppercase" }}>
                      {timeT ? item?.timezone : "--"}
                    </td>
                    <td>
                      {new Intl.DateTimeFormat("en-In", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(timeA)}
                    </td>
                    <td>
                      {new Intl.DateTimeFormat("en-In", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }).format(timeU)}
                    </td>
                    {/* <td>{item?.updatedAt}</td> */}
                    <td>{item?.notesCreatedUser?.name}</td>
                    <td>{item?.notesUpdatedUser ? item?.notesUpdatedUser?.name : "--"}</td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "#0e4d92",
                        }}
                      >
                        <FiEdit
                          onClick={() => {
                            addNote(item, item?.noteLables?.split(","));
                          }}
                          style={{
                            fontSize: "20px",
                            margin: "0px 2.5px 0px 2.5px",
                            cursor: "pointer",
                          }}
                        />
                        <AiOutlineDelete
                          onClick={() => {
                            deleteNote(item.id);
                          }}
                          style={{
                            fontSize: "22px",
                            margin: "0px 2.5px 0px 2.5px",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Modal>
      {addNotes}
    </>
  );
};

export default ContactNotesModal;
