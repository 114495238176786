import React, { useEffect, useState } from "react";
import "../../style/style.css";
// import "./dashboard.css";
import "./CDMStyle.css";
import { Button, DatePicker, Pagination, PaginationProps, Tooltip } from "antd";
import { GoPrimitiveDot } from "react-icons/go";
import { useDispatch } from "react-redux";
import Http from "../../services/http";
import { Select } from "antd";
import exportFromJSON from "export-from-json";
import UserActivities from "./UserActivities";
import ConnectedManagers from "./ConnectedManagers";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Popover } from "antd";
import { Space } from "antd";
import moment from "moment";
import { RangePickerProps } from "antd/lib/date-picker";
import { doContactMasterReset } from './../../redux/actions/contacts';
import { clearClientMultiSearch, doMasterReset } from "../../redux/actions/client";
import { doClientMultiSearch } from './../../redux/actions/client';

const AllNotesView = () => {
  const [pagiData, setPagiData] = useState<any>({
    total_records: null,
    total_perpage: 5,
    total_page: null,
    current_page: 1,
    next_page: 2,
    previous_page: null,
  });
  const [allNotes, setAllNotes] = useState([]);
  const [limit, setLimit] = useState("weekly");

  const [created_by_notes, set_created_by_notes] = useState("");
  const [startDate, setStartDate] = useState<any>("");
  const [endtDate, setEndDate] = useState<any>("");

  const [userOptions, setUserOptions] = useState([]);

  const [contactNotesLoader, setContactNotesLoader] = useState(false);

  const [filteredOptions, setFilteredOptions] = useState([]);
  const dispatch = useDispatch()
  //

  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const getNotes = (page: any, per_page: any) => {
    let body: any = {
      per_page,
      page,
      limit,
      created_by_notes: selectedItems,
      createdAt: [],
    };

    if (startDate != "" && endtDate != "") {
      body = {
        ...body,
        createdAt: [startDate, endtDate],
      };
    }

    Http.post(`users/user-noteslist`, body, false).then((response: any) => {
      const data = response?.data?.data || [];
      const pagination = response?.data?.pagination;

      setAllNotes(data);
      if (pagination) {
        setPagiData(pagination);
      } else {
        setPagiData({
          total_records: null,
          total_perpage: 10,
          total_page: null,
          current_page: 1,
          next_page: 2,
          previous_page: null,
        });
      }
      return;
    });
  };

  useEffect(() => {
    getNotes(1, 10);
  }, [selectedItems, endtDate]);

  useEffect(() => {
    getNotes(pagiData?.current_page, pagiData?.total_perpage);
  }, [limit]);

  useEffect(() => {
    Http.get(`user/user-list?per_page=1000&page=1`, false).then((res: any) => {
      const users = res?.data?.data || [];
      const fO = users?.map(({ name }: any) => {
        if (!selectedItems?.includes(name)) return name;
      });
      setFilteredOptions(fO);
    });
  }, []);

  // Clear Client/Contact Page
  useEffect(() => {
    dispatch(doContactMasterReset())
    dispatch(clearClientMultiSearch())
  }, [])

  const exportNotes = () => {
    setContactNotesLoader(true)
    let body: any = {
      created_by_notes: selectedItems,
      createdAt: [],
    };

    if (startDate != "" && endtDate != "") {
      body = {
        ...body,
        createdAt: [startDate, endtDate],
      };
    }

    Http.post(`users/export-contactnotes`, body, true).then((response: any) => {
      const { data = null } = response;

      const date = new Date();

      const fileName = `Contact Notes - ${date}`;
      const exportType = "csv";
      exportFromJSON({ data, fileName, exportType });
      setContactNotesLoader(false)
    });
  };

  const Pagi = () => {
    const onChange: PaginationProps["onChange"] = (page, pageSize) => {
      getNotes(page, pageSize);
    };

    return (
      <Pagination
        responsive
        showSizeChanger
        // showQuickJumper
        pageSize={pagiData?.total_perpage}
        pageSizeOptions={[10, 25, 50, 100]}
        onShowSizeChange={onChange}
        onChange={onChange}
        defaultCurrent={1}
        current={pagiData?.current_page}
        total={pagiData?.total_records}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
      />
    );
  };

  const datePickers = (date: any, dateString: any) => {
    dateString[0]
      ? setStartDate(new Date(dateString[0])?.toJSON()?.slice(0, 10))
      : setStartDate("");
    dateString[1]
      ? setEndDate(new Date(dateString[1])?.toJSON()?.slice(0, 10))
      : setEndDate("");
  };

  const { RangePicker } = DatePicker;

  const randomColors: any = {
    "Follow-Up": "#FF7B7B",
    "Email Follow-Up": "#B399D4",
    "Interview Scheduled": "#73C883",
    Submission: "#F79862",
    Introduction: "#49AFEA",
    "Interview Done": "#88A38B",
  };

  return (
    <>
      <div className="managment-bar" style={{ flexBasis: "70%" }}>
        <div className="manage-box cdm-card">
          <div className="cdm-card-header">
            <div></div>
            <div>
              <p style={{ marginBottom: "0" }}>All Contact Notes</p>
            </div>
            <div className="notesLimitSelector">
              <Button
                loading={contactNotesLoader}
                title={`${contactNotesLoader ? "Please wait while we load your data...." : ""}`}
                onClick={() => exportNotes()}
                className="mr-2 global-antd-btn"
              >
                Export
              </Button>
              <Select
                defaultValue="weekly"
                style={{ width: 120 }}
                onChange={(val) => setLimit(val)}
                options={[
                  {
                    value: "weekly",
                    label: "Weekly",
                  },
                  {
                    value: "monthly",
                    label: "Monthly",
                  },
                  {
                    value: "all",
                    label: "All",
                  },
                ]}
              />
            </div>
          </div>
          <div className="cdm-card-box"></div>
          <div>
            {/* <div className="card-header"> */}
            <div className="searchFields">
              {/* <div> */}
              {/* <input
                  type="text"
                  className="searchByUserName"
                  placeholder="Seach By User Name"
                  onChange={(e) => set_created_by_notes(e.target.value)}
                /> */}

              <Select
                mode="multiple"
                placeholder="Search By Users"
                value={selectedItems}
                onChange={setSelectedItems}
                style={{ width: "50%" }}
                options={filteredOptions.map((item) => ({
                  value: item,
                  label: item,
                }))}
              />
              {/* </div> */}
              <div>
                <Space direction="vertical" size={12}>
                  <RangePicker
                    // ranges={{
                    //   Today: [moment(), moment()],
                    //   "This Month": [
                    //     moment().startOf("month"),
                    //     moment().endOf("month"),
                    //   ],
                    // }}
                    // allowEmpty={[true, true]}
                    // value={[startDate, endtDate]}
                    onChange={datePickers}
                    allowClear
                  />
                </Space>
              </div>
            </div>
            {/* </div> */}
            <div className="card-block">
              <ul>
                {allNotes?.map((note: any, index: any) => {
                  const content = () => {
                    return (
                      <>
                        <div className="popover-content">
                          <ul>
                            {note?.time !== "00:00:00" && (
                              <li>
                                <label
                                  style={{ marginBottom: "0px" }}
                                  className="title"
                                >
                                  Next Follow Up :-
                                </label>
                                <div className="next-follow-up">
                                  <span className="date">
                                    <label>Date: </label>{" "}
                                    {note?.next_follow_up_date}
                                  </span>
                                  <span className="time">
                                    <label>Time: </label>{" "}
                                    {note?.next_follow_up_date && note?.time}
                                    {note?.next_follow_up_date &&
                                      note?.timezone}
                                  </span>
                                </div>
                              </li>
                            )}
                            {note?.last_follow_up_date && (
                              <li>
                                <label
                                  className="title"
                                  style={{ marginBottom: "0px" }}
                                >
                                  Last Follow Up:{" "}
                                </label>
                                <span> {note?.last_follow_up_date}</span>
                              </li>
                            )}
                            <li>
                              <label
                                className="title"
                                style={{ marginBottom: "0px" }}
                              >
                                Created At:{" "}
                              </label>
                              <span> {note?.createdAt}</span>
                            </li>
                            <li>
                              <label
                                className="title"
                                style={{ marginBottom: "0px" }}
                              >
                                Updated At:{" "}
                              </label>
                              <span> {note?.updatedAt}</span>
                            </li>
                          </ul>
                        </div>
                      </>
                    );
                  };
                  return (
                    <li key={index}>
                      <Popover
                        placement="leftTop"
                        content={content}
                        title={`Created For ${note?.notesData?.first_name} ${note?.notesData?.last_name}`}
                        trigger="hover"
                      >
                        <span className="highlighted-userName">
                          <div>
                            <i
                              style={{
                                color: "#0E4D92",
                                fontWeight: "800",
                                fontSize: "13px",
                              }}
                            >
                              Created By :- &nbsp;
                            </i>
                            {note?.notesCreatedUser?.name}{" "}
                            {
                              note?.noteSource &&
                              <Tooltip className="inc-out" title={note?.noteSource}>
                                {" "}
                                <GoPrimitiveDot
                                  className="inc-out"
                                  style={{
                                    color:
                                      note?.noteSource == "incoming"
                                        ? "green"
                                        : "red",
                                    fontSize: "18px",
                                  }}
                                />{" "}
                              </Tooltip>
                            }
                          </div>
                          <div className="text-end">
                            {note?.noteLables &&
                              note?.noteLables
                                ?.split(",")
                                ?.map((label: any) => {
                                  return (
                                    <button
                                      className="border p-1 m-1"
                                      style={{
                                        color: "white",
                                        fontSize: "12px",
                                        background: randomColors[label]
                                          ? randomColors[label]
                                          : "#435564",
                                        borderRadius: "7px",
                                        cursor: "auto",
                                      }}
                                    >
                                      {label}
                                    </button>
                                  );
                                })}
                          </div>
                        </span>

                        <span className="notes-view">
                          "<span className="m-b-5">{note?.notes}</span>"
                        </span>
                      </Popover>
                    </li>
                  );
                })}
                {allNotes?.length === 0 && <li>No Record Found</li>}
              </ul>
            </div>
            <div className="notes-pagination">{Pagi()}</div>
          </div>
        </div>
      </div>
    </>
  );
};

function Dashboard() {
  const userData = JSON.parse(localStorage.getItem("user")!);

  useEffect(() => {
    document.title = "Dashboard - Client Module";
  }, []);

  return (
    <div className="cdm-body">
      <div className="cdm-dashboard">
        {/* <div className="dash-header">
          <div className="dash-header-title">Candidate's Detail Management</div>
        </div> */}
        <div className="dash-body">
          <div className="managment-bar" style={{ flexBasis: "30%" }}>
            <div className="manage-box cdm-card">
              {/* <p
                style={{ cursor: "pointer" }}
              >
                Submissions Today
              </p> */}
              <div className="cdm-card-box">
                <ConnectedManagers />
              </div>
            </div>
          </div>
          <AllNotesView />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
