import React from 'react'
import { DatePicker, Pagination, Modal } from "antd";
import Table from "react-bootstrap/Table";

const TotalNotesModel = ({ entireData, isNoteTableModel, setIsNoteTableModel }: any) => {
  return (
    <>
      <Modal
        className="contact-modal-div note-modal"
        destroyOnClose
        title="Total Next Follow-up Notes"
        visible={isNoteTableModel}
        onCancel={() => setIsNoteTableModel(false)}
      >
        <div className="note-div">
          <Table striped="columns">
            <thead>
              <tr>
                <th>Id</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Client</th>
                <th>Reporting Manager</th>
                <th>Note</th>
                <th>Next Follow-Update</th>
                <th>Updated At</th>
              </tr>
            </thead>
            <tbody>
               {entireData && entireData?.map((e: any, index: any) => {
                index += 1;
                return (
                  <>
                    <tr key={index}>
                      <td>{e.id}</td>
                      <td>{e?.notesData?.first_name}</td>
                      <td>{e?.notesData?.last_name}</td>
                      <td>{e?.notesData?.email}</td>
                      <td>{e?.notesData?.clientName?.client_name}</td>
                      <td>{e?.notesData?.ReportingManagerData?.first_name ? e?.notesData?.ReportingManagerData?.first_name + " " + e?.notesData?.ReportingManagerData?.last_name : "--"}</td>
                      <td>{e?.notes}</td>
                      <td>{e?.next_follow_up_date + " " + e?.time + " " + e?.timezone}</td>
                      <td>{e?.updatedAt}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Modal>
    </>
  )
}

export default TotalNotesModel;