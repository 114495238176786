import React, { useEffect, useState } from "react";
import {
  AiOutlineSearch,
  AiOutlineUnorderedList,
  AiFillFileText,
  AiOutlineSortDescending,
  AiOutlineSortAscending,
} from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { useSortBy, useTable } from "react-table";
import { DatePicker, Pagination, Modal } from "antd";
import { AiOutlineDelete } from "react-icons/ai";
import { BsGraphUp } from "react-icons/bs";
import { IoMdCloseCircle, IoMdCloseCircleOutline } from "react-icons/io";
import Totalnextfollowchart from "./Charts/Totalnextfollowchart";
import { useDispatch, useSelector } from "react-redux";
import { getTotalNextFollowUp } from "../../../redux/actions/report";
import moment from "moment";
import TotalNotesModel from "./Models/TotalNotesModel";
import "./style/Totalnextfollow.css";
import ReadNotes from "./Models/ReadNotes";
import NotReadNotes from "./Models/NotReadNotes";
const { RangePicker } = DatePicker;

const columns: any = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Manager Name",
    accessor: "name",
  },
  {
    Header: "Next Follow-up",
    accessor: "notesCount",
  },
  {
    Header: "Read",
    accessor: "markDoneCount",
  },
  {
    Header: "Non-Read",
    accessor: "markNotDoneCount",
  },
];

function TotalNextFollow() {
  const [gridList, setGridList] = useState(true);
  const [user, setUser]: any = useState("");
  const [startDate, setStartDate]: any = useState("");
  const [isNoteTableModel, setIsNoteTableModel] = useState(false);
  const [isReadModel, setIsReadModel]: any = useState(false);
  const [isNotReadModel, setIsNotReadModel]: any = useState(false);
  const [entireData, setEntireData]: any = useState([]);
  const [entireDataRead, setEntireDataRead]: any = useState([]);
  const [entireDataNotRead, setEntireNotDataRead]: any = useState([]);
  const [endDate, setEndDate]: any = useState("");
  const [page, setPage]: any = useState("1");
  const [perPage, setPerPage]: any = useState("10");
  const [sortOrder, setOrder] = useState(true);
  const [sortField, setSortField] = useState("");

  const dispatch: any = useDispatch();

  const totalFollow: any = useSelector(
    (state: any) => state?.report?.totalNextFollowUp
  );
  const sendData: any = {
    startDate: startDate,
    endDate: endDate,
    searchByUser: user,
    page: page,
    perPage: perPage,
    sortField: sortField,
    sortOrder: sortOrder ? "DESC" : "ASC",
  };
  useEffect(() => {
    dispatch(getTotalNextFollowUp(sendData));
  }, [user, startDate, endDate, page, perPage, sortField, sortOrder]);
  const data: any = totalFollow?.data ? totalFollow?.data : [];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  const getSetDateData = (e: any, data: any) => {
    setStartDate(data[0]);
    setEndDate(data[1]);
  };

  const openBox = (data: any) => {
    setIsNoteTableModel(true);
    setEntireData(data);
  };

  const openBox1 = (data: any) => {
    setIsReadModel(true);
    setEntireDataRead(data);
  };

  const openBox2 = (data: any) => {
    setIsNotReadModel(true);
    setEntireNotDataRead(data);
  };

  const listChange = () => {
    setGridList(false);
    setStartDate("");
    setEndDate("");
    setUser("");
  };

  const menuChange = () => {
    setGridList(true);
    setStartDate("");
    setEndDate("");
    setUser("");
  };

  return (
    <>
      <TotalNotesModel
        entireData={entireData}
        isNoteTableModel={isNoteTableModel}
        setIsNoteTableModel={setIsNoteTableModel}
      />
      <ReadNotes
        setIsReadModel={setIsReadModel}
        isReadModel={isReadModel}
        entireDataRead={entireDataRead}
      />
      <NotReadNotes
        setIsNotReadModel={setIsNotReadModel}
        isNotReadModel={isNotReadModel}
        entireDataNotRead={entireDataNotRead}
      />
      <div className="col">
        <div className="upper-bar">
          <div></div>
          <p className="title">Total Next Follow-up</p>
          {gridList ? (
            <BsGraphUp
              className="grid-icon-user"
              onClick={() => listChange()}
            />
          ) : (
            <AiOutlineUnorderedList
              className="grid-icon-user"
              onClick={() => menuChange()}
            />
          )}
        </div>
        {gridList ? (
          <div className="all-table animate__animated animate__flipInY">
            <div className="top-bar-userwise">
              <div className="search-bar-user-wise">
                <AiOutlineSearch
                  className="search-icon-user-wise"
                  style={{
                    fontSize: "20px",
                    color: "#0E4D92",
                    backgroundColor: "#F0F0F0",
                    height: "100%",
                    marginLeft: "10px",
                  }}
                />
                <input
                  type="text"
                  className="search-wise-user"
                  placeholder="Search..."
                  value={user}
                  onChange={(e: any) => setUser(e.target.value)}
                />
              </div>
              <div className="forCrossIcon" style={{ position: "relative" }}>
                <RangePicker
                  className="pagi-datepicker"
                  ranges={{
                    Today: [moment(), moment()],
                  }}
                  onChange={getSetDateData}
                />
                {startDate !== "" || endDate !== "" ? (
                  <IoMdCloseCircle
                    style={{
                      pointerEvents: "none",
                      color: "#939393",
                      fontSize: "18px",
                      position: "absolute",
                      top: "7px",
                      right: "7px",
                      cursor: "pointer",
                    }}
                  />
                ) : null}
              </div>
            </div>
            <div className="table-of-userwise">
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr
                      {...headerGroup.getHeaderGroupProps()}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {headerGroup.headers.map((column:any) => (
                        // <th {...column.getHeaderProps()}>
                        //   {column.render("Header")}
                        // </th>
                        <th {...column.getHeaderProps()}>
                          {column.id !== "notesCount" &&
                          column.id !== "markDoneCount" &&
                          column.id !== "markNotDoneCount" ? (
                            <>
                              {sortOrder && sortField === column.id ? (
                                <AiOutlineSortDescending
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setOrder(!sortOrder);
                                    setSortField(column.id);
                                  }}
                                />
                              ) : (
                                <AiOutlineSortAscending
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setOrder(!sortOrder);
                                    setSortField(column.id);
                                  }}
                                />
                              )}
                            </>
                          ) : null}

                          {column.render("Header")}
                          {/* <span>
                            {console.log(column)
                            }
                            { column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                            }
                          </span> */}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className="tr-for-totalNextFollow"
                      >
                        {row.cells.map((cell: any) => {
                          if (cell.column.id === "notesCount") {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="td-nextFollow"
                              >
                                {cell.row.original.notesCount.length}{" "}
                                {cell.row.original.notesCount.length != 0 ? (
                                  <AiFillFileText
                                    onClick={() =>
                                      openBox(cell.row.original.notesCount)
                                    }
                                    className="icon-for-totalNextFollow"
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : null}
                              </td>
                            );
                          } else if (cell.column.id === "markDoneCount") {
                            // console.log("Read",cell.row.original?.markDoneCount);

                            return (
                              <td
                                {...cell.getCellProps()}
                                className="td-nextFollow"
                              >
                                {cell.row.original.markDoneCount.length}
                                {cell.row.original.markDoneCount.length != 0 ? (
                                  <AiFillFileText
                                    onClick={() =>
                                      openBox1(cell.row.original?.markDoneCount)
                                    }
                                    style={{ cursor: "pointer" }}
                                    className="icon-for-totalNextFollow"
                                  />
                                ) : null}
                              </td>
                            );
                          } else if (cell.column.id === "markNotDoneCount") {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="td-nextFollow"
                              >
                                {cell.row.original.markNotDoneCount.length}
                                {cell.row.original.markNotDoneCount.length !=
                                0 ? (
                                  <AiFillFileText
                                    onClick={() =>
                                      openBox2(
                                        cell.row.original.markNotDoneCount
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                    className="icon-for-totalNextFollow"
                                  />
                                ) : null}
                              </td>
                            );
                          } else {
                            return (
                              <td
                                {...cell.getCellProps()}
                                className="td-nextFollow"
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="footer-bar-userwise">
              <Pagination
                className="pagi-user-wise"
                current={totalFollow?.pagination?.current_page}
                total={totalFollow?.pagination?.total_records}
                onChange={(e: any) => setPage(e)}
              />
            </div>
          </div>
        ) : (
          <Totalnextfollowchart
            totalFollow={totalFollow}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
          />
        )}
      </div>
    </>
  );
}

export default TotalNextFollow;
