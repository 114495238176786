import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Upload,
} from "antd";
import "./style.css";
import React, { useEffect, useRef, useState } from "react";
import ClienContact from "../ClientContact/ClienContact";
import Http from "../../services/http";
import axios from "axios";
import { getClientInfoData } from "../../services/client";
import { contactRefresh } from "../../redux/actions/contacts";
import { useDispatch } from "react-redux";
import { refreshTable } from "../../redux/actions/client";
const { TextArea } = Input;
let index = 0;

const ClientFormComponent = (props: any) => {
  const [client, setClient] = useState("");
  const [url, setUrl] = useState("");
  const [userList, setUserList] = useState([]);
  const [sentUser, setSentUser] = useState("");
  const [country, setCountry] = useState([]);
  const [sentCountry, setSentCountry] = useState("");
  const [state, setState] = useState([]);
  const [sentState, setSentState] = useState("");
  const [city, setCity] = useState([]);
  const [sentCity, setSentCity] = useState("");
  const [industry, setIndustry] = useState(["IT", "Finance", "Retail"]);
  const [sentIndustry, setSentIndustry] = useState("");
  const [name, setName] = useState("");
  const [amount, setAmount] = useState(["Net10", "Net20"]);
  const [sentAmount, setSentAmount] = useState("");
  const [cName, setCName] = useState("");
  const [category, setCategory] = useState(["Direct", "Tier 1"]);
  const [sentCategory, setSentCategory] = useState("");
  const [cStatusName, setCStatusName] = useState("");
  const [clientStatus, setClientStatus] = useState([
    "Negotiating",
    "Active",
    "Inactive",
  ]);
  const [sentClientStatus, setSentClientStatus] = useState("");
  const [notes, setNotes] = useState("");
  const [files, setFiles] = useState([]);

  const inputRef: any = useRef(null);
  const dispatch = useDispatch();

  const token = localStorage.getItem("access_token") || "";

  useEffect(() => {

    Http.get("user/user-list?per_page=1000&page=1", false).then(
      async (res: any) => {
        const { data } = await res.data;
        setUserList(data);
      }
    );

    Http.get("users/country_list", false).then(async (res: any) => {
      const countries = await res.countries;
      setCountry(countries);
    });
  }, []);

  const onNameChange = (event: any) => {
    setName(event.target.value);
    setCName(event.target.value);
    setCStatusName(event.target.value);
  };

  const addAmount = (e: any) => {
    e.preventDefault();
    setAmount([...amount, name || `New item ${index++}`]);
    setName("");
    setCName("");
  };

  const addCategory = (e: any) => {
    e.preventDefault();
    setCategory([...category, cName || `New item ${index++}`]);
  };

  const addClient = (e: any) => {
    setClientStatus([...clientStatus, cStatusName || `New item ${index++}`]);
  };

  const countryChange = (e: any) => {

    Http.post("users/get_states_by_country", { country_id: e }, false).then(
      async (res: any) => {
        const { states } = await res;
        setState(states);
      }
    );

    const filtredArray: any = country.filter((elem: any) => {
      return elem.id === e;
    });
    setSentCountry(filtredArray[0]?.name);
  };

  const changeState = (e: any) => {

    if (!e) {
      return setSentState(e)
    }

    Http.post("users/get_cities_by_state", { state_id: e }, false).then(
      async (res: any) => {
        const { city } = res;
        setCity(city);
      }
    );

    const filterdArray2: any = state.filter((elem: any) => {
      return elem.id == e;
    });
    setSentState(filterdArray2[0].name);
  };

  const formSubmit = async (e: any) => {
    // 
    const formData = new FormData();

    formData.append("client_name", client);
    formData.append("website_url", url);
    formData.append("ownership", sentUser);
    formData.append("country", sentCountry);
    formData.append("state", sentState);
    formData.append("city", sentCity);
    formData.append("payment_terms", sentAmount);
    formData.append("category", sentCategory);
    formData.append("client_status", sentClientStatus);
    formData.append("industry", sentIndustry);
    formData.append("notes", notes);
    formData.append("document", files[0]);

    props.setClientTab(formData);

    if (formData) {

      Http.post("users/add_client", formData, false).then(async (result: any) => {
        const { data } = await result;

        if (result.success === true) {
          dispatch(refreshTable())
          props.setClientId(data.id);

          message.success(result?.message);
          clearFields();
          props.setIsClientModelVisible(false);
          props.setIsContactModalOpen(true);
          dispatch(getClientInfoData(10, 1))
        } else {
          message.error(result?.message);
          // setClientTab(null);
        }
      }).catch((error: any) => {
        if (error) {
          if (error?.status === 400) {
            message.error(error.data?.message);
          }
        }
      });
    }
  };



  const clearFields = () => {
    setClient("");
    setUrl("");
    setSentUser("");
    setSentCountry("");
    setSentState("");
    setSentCity("");
    setSentAmount("");
    setSentCategory("");
    setSentClientStatus("");
    setSentIndustry("");
    setNotes("");
    inputRef.current.value = null;
  };



  return (
    <>
      <div className="cForm">
        <Form onFinish={formSubmit}>
          <div className="form-row">
            <div className="form-group">
              <Form.Item label="Client" required>
                <Input
                  className="input-field"
                  type="text"
                  required
                  value={client}
                  onChange={(e: any) => {
                    const result = e.target.value.replace(/[^a-z, 1-9]/gi, '')
                    setClient(result);
                  }}
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item
                label="Website"
                required
                rules={[
                  {
                    required: true,
                  },
                  {
                    type: "url",
                    warningOnly: true,
                  },
                  { message: "Website must like 'https://www.example.com'" },
                  {
                    type: "string",
                    min: 6,
                  },
                ]}
              >

                <Input
                  required
                  className="input-field"
                  type="url"
                  placeholder="https://www.example.com"
                  // pattern="*"
                  value={url}
                  onChange={(e) => {
                    let val = e.target.value.toLowerCase().replace("http://", "https://")
                    if (val == "https:/") {
                      val = "https://"
                    }

                    if (val == "http:/") {
                      val = "http://"
                    }

                    if (!val.includes(`https://`) && val != "") {
                      val = "https://" + e.target.value
                    }


                    setUrl(val)
                  }
                  }
                />

                {/* <Input
                  className="input-field"
                  placeholder="https://www.example.com"
                  required
                  type="url"
                  value={url}
                  onChange={(e: any) => setUrl(e.target.value)}
                /> */}
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="Ownership" required>
                <Select
                  showSearch
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(e: any) => setSentUser(e)}
                  value={sentUser}
                >

                  {userList?.map((item: any) => {
                    return (
                      <Select.Option value={item.id}>{item.name}</Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="form-row">
            {" "}
            <div className="form-group">
              <Form.Item label="Country">
                <Select
                  showSearch
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  // filterSort={(optionA, optionB) =>
                  //   optionA.children
                  //     .toLowerCase()
                  //     .localeCompare(optionB.children.toLowerCase())
                  // }
                  value={sentCountry}
                  onChange={(e: any) => countryChange(e)}
                >
                  <Select.Option value={""}>Select</Select.Option>
                  {country.map((item: any) => {
                    // 
                    return (
                      <Select.Option value={item.id}>{item.name}</Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="State">
                <Select
                  showSearch
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  // filterSort={(optionA, optionB) =>
                  //   optionA.children
                  //     .toLowerCase()
                  //     .localeCompare(optionB.children.toLowerCase())
                  // }
                  value={sentState}
                  onChange={(e: any) => changeState(e)}
                >
                  <Select.Option value={""}>Select</Select.Option>
                  {state.map((item: any) => {
                    return (
                      <Select.Option value={item.id}>{item.name}</Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="City">
                <Select
                  showSearch
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  value={sentCity}
                  onChange={(e: any) => setSentCity(e)}
                >
                  <Select.Option value={""}>Select</Select.Option>
                  {city.map((item: any) => {
                    return (
                      <Select.Option value={item.name}>
                        {item.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="form-row">
            {" "}
            <div className="form-group">
              <Form.Item label="Payment Terms">
                <Select
                  placeholder="Select"
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <Space
                        style={{
                          padding: "0 8px 4px",
                        }}
                      >
                        <Input
                          placeholder="Please enter item"
                          ref={inputRef}
                          value={name}
                          onChange={onNameChange}
                        />
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={addAmount}
                        ></Button>
                      </Space>
                    </>
                  )}
                  value={sentAmount}
                  onChange={(e: any) => setSentAmount(e)}
                >
                  <Select.Option value={""}>Select</Select.Option>
                  {amount.map((item, index) => (
                    <Select.Option key={index} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="Category">
                <Select
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <Space
                        style={{
                          padding: "0 8px 4px",
                        }}
                      >
                        <Input
                          placeholder="Please enter item"
                          ref={inputRef}
                          value={name}
                          onChange={onNameChange}
                        />
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={addCategory}
                        ></Button>
                      </Space>
                    </>
                  )}
                  value={sentCategory}
                  onChange={(e: any) => setSentCategory(e)}
                >
                  <Select.Option value={""}>Select</Select.Option>
                  {category.map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="Client Status">
                <Select
                  showSearch
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  placeholder="Select"
                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: "8px 0",
                        }}
                      />
                      <Space
                        style={{
                          padding: "0 8px 4px",
                        }}
                      >
                        <Input
                          placeholder="Please enter item"
                          ref={inputRef}
                          value={name}
                          onChange={onNameChange}
                        />
                        <Button
                          type="text"
                          icon={<PlusOutlined />}
                          onClick={addClient}
                        ></Button>
                      </Space>
                    </>
                  )}
                  value={sentClientStatus}
                  onChange={(e: any) => setSentClientStatus(e)}
                >
                  <Select.Option value={""}>Select</Select.Option>
                  {clientStatus.map((item) => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="form-row">
            {" "}
            <div className="form-group">
              <Form.Item label="Industry">
                <Select
                  showSearch
                  placeholder="Select"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children
                      .toLowerCase()
                      .localeCompare(optionB.children.toLowerCase())
                  }
                  value={sentIndustry}
                  onChange={(e: any) => setSentIndustry(e)}
                >
                  <Select.Option value={""}>Select</Select.Option>
                  {industry.map((item) => {
                    return <Select.Option value={item}>{item}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className="form-group note-group">
              <Form.Item label="Notes">
                <TextArea
                  className="input-field textarea"
                  rows={2}
                  value={notes}
                  onChange={(e: any) => setNotes(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="form-group">
              <Form.Item label="Attachment" valuePropName="fileList">
                <div className="upload-div">
                  <button>
                    <input
                      ref={inputRef}
                      type="file"
                      onChange={(e: any) => setFiles(e.target.files)}
                    />
                  </button>
                </div>
              </Form.Item>
            </div>
          </div>
          <div className="form-row btn-group" style={{ marginTop: '10px' }}>
            <div className="form-group ">
              <div className="form-btn-group" >
                <Form.Item>
                  <Button className="global-antd-btn" type="primary" htmlType="submit">
                    Submit
                  </Button>
                  <Button
                    onClick={clearFields}
                    className="global-antd-btn"
                    type="primary"
                    style={{
                      backgroundColor: "grey",
                      border: "none",
                      marginLeft: "35px",
                    }}
                  >
                    Clear
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ClientFormComponent;