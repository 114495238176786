import React, { useEffect } from "react";
import "./Report.css";
import Userwise from "./Components/Userwise";
import LabelPlus from "./Components/LabelPlus";
import NoRecord from "./Components/NoRecord";
import RegularTouch from "./Components/RegularTouch";
import TotalNextFollow from "./Components/TotalNextFollow";
import AttampManager from "./Components/AttampManager";
import { useDispatch } from 'react-redux';
import { doContactMasterReset } from './../../redux/actions/contacts';
import { clearClientMultiSearch } from "../../redux/actions/client";

function Report() {

  const dispatch = useDispatch()

  // Clear Client/Contact Page
  useEffect(() => {
    dispatch(doContactMasterReset())
    dispatch(clearClientMultiSearch())
  }, [])
  
  return (
    <>
      <div className="report-body">
        <div className="report-dashboard">
          <div className="container-fluid text-center">
            <div className="row first-row d-flex justify-content-around">
              <Userwise />
              <LabelPlus />
            </div>
          </div>
          <div className="container-fluid">
            <div className="row first-row">
              <NoRecord />
              {/* <RegularTouch /> */}
              <TotalNextFollow />
            </div>
          </div>
          <div className="container-fluid">
            <div className="row first-row" style={{justifyContent:"start"}}>              
              <AttampManager />
              {/* <AttampManager /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Report;
