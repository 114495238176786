import React, { Component, useEffect, useState } from "react";
import { getClientColumnData } from "../../services/client";

// let clientColumns = [
//   {
//     header: "ID",
//     accessor: "id", // accessor is the "key" in the data
//     // isFilter: true,
//   },
//   {
//     header: "Client Name",
//     accessor: "client_name",
//     isFilter: true,
//   },
//   {
//     header: "Website",
//     accessor: "website_url",
//     isFilter: true,
//   },
//   {
//     header: "Category",
//     accessor: "category",
//     isFilter: true,
//   },
//   {
//     header: "Industry",
//     accessor: "industry",
//     isFilter: true,
//   },
//   {
//     header: "City",
//     accessor: "city",
//     isFilter: true,
//   },
//   {
//     header: "State",
//     accessor: "state",
//     isFilter: true,
//   },
//   {
//     header: "Country",
//     accessor: "country",
//     isFilter: true,
//   },
//   {
//     header: "Payment Terms",
//     accessor: "payment_terms",
//     isFilter: true,
//   },
//   {
//     header: "OwnerShip",
//     accessor: "ClientownersUser",
//     // isFilter: true,
//   },
//   {
//     header: "Created By",
//     accessor: "createUser",
//     // isFilter: true,
//   },
//   {
//     header: "Created At",
//     accessor: "createdAt",
//     // isFilter: true,
//   },
//   {
//     header: "Updated At",
//     accessor: "updatedAt",
//     // isFilter: true,
//   },
//   {
//     header: "Notes",
//     accessor: "notes",
//     isFilter: true,
//   },
//   {
//     header: "Action",
//     accessor: "action",
//   },
// ];
// setHiddenTableColumns(clientColumns);

// let clientColumns = JSON.parse(localStorage.getItem("ClientColumns") || '')

// let clientColumns = JSON.parse(localStorage.getItem("ClientColumns") || `{}`)

let clientColumns = getClientColumnData();

// localStorage.setItem("ClientColumns", JSON.stringify(clientColumns))




const contactColumns = [
  {
    header: "Client ID",
    accessor: "client_id", // accessor is the "key" in the data
  },
  {
    header: "First Name",
    accessor: "first_name",
  },
  {
    header: "Last Name",
    accessor: "last_name",
  },
  {
    header: "Email",
    accessor: "email",
  },
  {
    header: "Phone",
    accessor: "phone_1",
  },
  {
    header: "Skills Support",
    accessor: "skills_support",
  },
  {
    header: "Country",
    accessor: "country",
  },
  {
    header: "State",
    accessor: "state",
  },
  {
    header: "City",
    accessor: "city",
  },
  {
    header: "Create At",
    accessor: "createdAt",
  },
  {
    header: "Create By",
    accessor: "created_by",
  },
  {
    header: "Create On",
    accessor: "created_on",
  },
  {
    header: "Lead Source",
    accessor: "lead_source",
  },
];


const ContactTableHead = () => {
  return (
    <thead style={{ backgroundColor: "#0E4D92" }}>
      {contactColumns.map((col: any, index: any) => {
        return <th>{col.header}</th>;
      })}
    </thead>
  );
};

export {
  clientColumns,
  contactColumns,
  ContactTableHead,
};
