// import './style.css';
import { Button } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { forgotPassword } from '../../../redux/actions/login';
import { LoginModel } from '../../../redux/models';
const MaskImage = require("../../../assets/images/MaskGroup2.png")

interface LoginProps {
    login: LoginModel,
    userLogin: Function,
    forgotPassword: Function
}

function Forgot({ login, forgotPassword }: LoginProps) {
    const [email, setEmail] = useState('');
    const history = useHistory();
    const submitForgotPassword = async (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        const data = {
            email
        }

        forgotPassword(data);
        // 

        // if (login.data) {
        //     
        //     setEmail('');
        //     history.push('/')
        // }
    }
    return (
        <div className="wrapper">
            <div className="signup-block">
                <div className="signup-form-block">
                    <div className="signup-left-block">
                        <div className="wise-img">
                            <img src={MaskImage} alt="wiseskulls-img" />
                        </div>
                        <div className="ats-text">
                            <p>ATS</p>
                        </div>
                    </div>
                    <div className="signup-right-block">
                        <div className="signup-wrapper">
                            <div className="sign-text sign-text-bold">
                                <h3>Forgot Password</h3>
                            </div>
                            <div className="form">
                                <form onSubmit={submitForgotPassword}>
                                    <div className="form">
                                        <div className="input">
                                            <input type="email" onChange={e => setEmail(e.target.value)} placeholder="name@domain.com" value={email} required />
                                        </div>
                                    </div>
                                    <div className="btn-cls">
                                    {/* <pre>{login}</pre> */}
                                    <Button className="signin-btn submit-btn" loading={login.isLoading} htmlType='submit'>Submit</Button>
                                    <Link className="signin-btn cancel-btn" to='/'>Cancel</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    login: state.login
})

const mapDispatchToProps = (dispatch: any) => ({
    forgotPassword: (data: any) => dispatch(forgotPassword(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Forgot);