import React from "react";
import { useState, useEffect } from "react";
import Http from "./../../services/http";

const ConnectedManagers = () => {
  const [allData, setAllData] = useState<any>({});

  useEffect(() => {
    getNotes();
  }, []);

  const getNotes = () => {
    Http.get(`users/contactnotes-count`, false).then((response: any) => {
      const data = response?.data || {};

      setAllData(data);
      return;
    });
  };

  return (
    <div className="over-down cdm-card">
      {/* <p>Manage Requirments</p> */}
      <div className="cdm-card-box" style={{ width: "100%", height: "100%" }}>
        <div className="header_total_sub">
          <p>Targeted Managers</p>
        </div>
        <div className="body_total_sub">
          <h1>{allData?.dailyCount}</h1>
          <h3>Total Calls Connected</h3>
          <h3>Today</h3>
        </div>
        <div className="top_five_header">
          <p>Top Managers Targeted by in the list</p>
        </div>
        <div className="footer_total_sub">
          <ul>
            {allData?.topUserCount?.map((data: any, index: any) => {
              return (
                <li style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', color: '#0E4D92' }} key={index}>
                  <div className="index"><span>{index + 1})</span>
                    <span className="name"> {data?.name}</span>
                  </div>
                  <span className="count">{data?.count}</span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ConnectedManagers;
