import React from "react";
import { Pagination as Pagi } from "antd";
import type { PaginationProps } from "antd";

const UserPagi = ({ paginationData, pagiDetails }: any) => {

  const startIndex =
    paginationData?.total_perpage * paginationData?.current_page -
    paginationData?.total_perpage +
    1;
  const endIndex = paginationData?.total_perpage * paginationData?.current_page;
  const showTotal: PaginationProps["showTotal"] = (total) =>
    `Showing ${startIndex} to ${endIndex} of ${total} entries`;

  return (
    <>
      <Pagi
        showQuickJumper
        showSizeChanger
        onChange={(currentPage: any, pageSize: any) => {
          pagiDetails.setPage(currentPage); pagiDetails.setPerPage(pageSize)
        }}
        current={paginationData?.current_page}
        total={paginationData?.total_records}
        showTotal={showTotal}
      />
    </>
  );
};

export default UserPagi;
