import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import { getContactData } from "../../../redux/actions/contacts";

const SortComponent = ({ sortData, Tabledata }: any) => {

  const dispatch = useDispatch();
  const [sortOrder, setSortOrder] = useState("asc");

  const sortColumn = (item: any) => {
    const Data = Tabledata;

    if (item === "ContactownerUser" || item === "ContactcreatUser") {
      if (sortOrder === "asc") {
        const sortedData = [...Data]?.sort((a, b) =>
          a?.[item]?.name?.toLowerCase() > b?.[item]?.name?.toLowerCase() ? 1 : -1
        );
        const newData = {
          data: {
            data: sortedData,
            pagination: Tabledata?.pagination,
          },
        };
        dispatch(getContactData(newData));
        setSortOrder("desc");
      } else {
        const sortedData = [...Data]?.sort((a, b) =>
          b?.[item]?.name?.toLowerCase() > a?.[item]?.name?.toLowerCase() ? 1 : -1
        );
        const newData = {
          data: {
            data: sortedData,
            pagination: Tabledata?.pagination,
          },
        };
        dispatch(getContactData(newData));
        setSortOrder("asc");
      }
    }
    if (item === "clientName") {
      if (sortOrder === "asc") {
        const sortedData = [...Data]?.sort((a, b) =>
          a?.[item]?.client_name?.toLowerCase() > b?.[item]?.client_name?.toLowerCase() ? 1 : -1
        );
        const newData = {
          data: {
            data: sortedData,
            pagination: Tabledata?.pagination,
          },
        };
        dispatch(getContactData(newData));
        setSortOrder("desc");
      } else {
        const sortedData = [...Data]?.sort((a, b) =>
          b?.[item]?.client_name?.toLowerCase() > a?.[item]?.client_name?.toLowerCase() ? 1 : -1
        );
        const newData = {
          data: {
            data: sortedData,
            pagination: Tabledata?.pagination,
          },
        };
        dispatch(getContactData(newData));
        setSortOrder("asc");
      }
    }
    if (item === "id") {
      if (sortOrder === "asc") {
        const sortedData = [...Data]?.sort(
          (a: any, b: any) => a?.[item] - b?.[item]
        );
        const newData = {
          data: {
            data: sortedData,
            pagination: Tabledata?.pagination,
          },
        };
        dispatch(getContactData(newData));
        setSortOrder("desc");
      } else {
        const sortedData = [...Data]?.sort(
          (a: any, b: any) => b?.[item] - a?.[item]
        );
        const newData = {
          data: {
            data: sortedData,
            pagination: Tabledata?.pagination,
          },
        };
        dispatch(getContactData(newData));
        setSortOrder("asc");
      }
    } else {
      if (sortOrder === "asc") {
        const sortedData = [...Data]?.sort((a, b) =>
          a?.[item]?.toLowerCase() > b?.[item]?.toLowerCase() ? 1 : -1
        );
        const newData = {
          data: {
            data: sortedData,
            pagination: Tabledata?.pagination,
          },
        };
        dispatch(getContactData(newData));
        setSortOrder("desc");
      } else {
        const sortedData = [...Data]?.sort((a, b) =>
          b?.[item]?.toLowerCase() > a?.[item]?.toLowerCase() ? 1 : -1
        );
        const newData = {
          data: {
            data: sortedData,
            pagination: Tabledata?.pagination,
          },
        };
        dispatch(getContactData(newData));
        setSortOrder("asc");
      }
    }
  };

  return (
    <>
      {sortOrder === "asc" ? (
        <SortAscendingOutlined
          style={{
            fontSize: "15px",
            // marginLeft: "5px",
            position: "relative",
            top: "-2px",
            marginLeft:"5px",
          }}
          onClick={() => sortColumn(sortData)}
        />
      ) : (
        <SortDescendingOutlined
          style={{
            fontSize: "15px",
            marginRight: "5px",
            position: "relative",
            top: "-2px",
            marginLeft:"5px",
          }}
          onClick={() => sortColumn(sortData)}
        />
      )}
    </>
  );
};

export default SortComponent;
