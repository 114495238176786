import React from 'react';
import { Route, Redirect } from 'react-router-dom';

function ProtectedRoute(props: any) {
	let { path, user, component: Component } = props;

	return (
		<Route path={path} exact render={() => {
			return user ? <Component /> : <Redirect to="/" />
		}} ></Route>
	);
}

export default ProtectedRoute;

