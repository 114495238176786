import React from "react";
import { Line } from "react-chartjs-2";
import { DatePicker, Pagination } from "antd";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import moment from "moment";
import { IoMdCloseCircle } from "react-icons/io";
import { minHeight } from "@mui/system";
const { RangePicker } = DatePicker;

function Labelcountchart({ userData, setDatePicker, datePicker, lebelCountData }: any) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );  



  const options: any = {
    animation: {
      duration: 10,
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#0E4D92",
          font: {
            family: "Mulish",
            size: 13,
            weight: "600",
          },
        },
      },
      y: {
        beginAtZero: true,
        border: {
          display: false,
        },
        ticks: {
          color: "#0E4D92",
          font: {
            family: "Mulish",
            size: 13,
            weight: "600",
          },
        },
      },
    },
  };


  const labels:any = lebelCountData.map((e:any) => e.label)
  const lebelsData:any = lebelCountData.map((e:any) => e.count)

  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "",
        data: lebelsData,
        borderColor: "#146FC8",
        backgroundColor: "rgba(26, 111, 196, 0.5)",
      },
    ],
  };

  const dateFun = (e:any, ele:any) =>{
    setDatePicker(ele);
  }

  return (
    <div
      className="animate__animated animate__flipInY"
      style={{ padding: "0px 20px 15px 20px" }}
    >
      <div
        style={{
          textAlign: "end",
          padding: "0px 5px 0px 0px",
          marginTop: "3px",
        }}
      >
        <div className="forCrossIcon" style={{ position: "relative" }}>
          {/* <RangePicker
            className="pagi-datepicker"
            ranges={{
              Today: [moment(), moment()],
            }}
            onChange={dateFun}
          /> */}
          {datePicker[0] !== "" || datePicker[1] !== "" ? (
            <IoMdCloseCircle
              style={{
                pointerEvents: "none",
                color: "#939393",
                fontSize: "18px",
                position: "absolute",
                top: "7px",
                right: "7px",
                cursor: "pointer",
              }}
            />
          ) : null}
        </div>
      </div>
      <Line options={options} data={data} style={{ minHeight: "282px", minWidth: "565px", marginTop:"25px" }} />
    </div>
  );
}

export default Labelcountchart;
