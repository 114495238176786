import { Button, Dropdown, Modal } from "antd";
import React, { useState } from "react";
import ClientFormComponent from "../../FormCom/ClientFormComponent";
import ClientContact from "../../ClientContact/ClienContact";
import { useDispatch } from "react-redux";
import {
  contactRefresh,
  getAllContacts,
} from "../../../redux/actions/contacts";
import { useDetectClickOutside } from "react-detect-click-outside";
import { Tooltip } from "@mui/material";

const ContactInputMenuDropDown = ({ props }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientTab, setClientTab] = useState(null);
  const [clientId, setClientId] = useState("");
  const dispatch = useDispatch();


  return (
    <>
      <Tooltip title="Add Contact" placement="top" arrow>
        <div className="menu-dropdown" >
          <button className="th-title-btn" onClick={() => setIsModalOpen(true)}>New</button>
        </div>
      </Tooltip>
      <div className="contact-modal">
        <Modal
          className="contact-modal-div"
          visible={isModalOpen}
          title="Add Contact"
          // destroyOnClose={() => setIsModalOpen(false)}
          onOk={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
          style={{ width: 1000 }}
        >
          <ClientContact
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
            getTableData={contactRefresh}
            setClientTab={setClientTab}
            clientTab={clientTab}
            setClientId={setClientId}
            clientId={clientId}
          />
        </Modal>
      </div>
    </>
  );
};

export default ContactInputMenuDropDown;
