import React from "react";
import { Table as BsTable } from "react-bootstrap";

import { ContactsTableBody } from "./TableParts";
// import "../../pages/client/style.css"
import { Pagination as Pagi, PaginationProps } from "antd";
import "./ConatctTable.css";
import ContactsTableHead from "./ContactParts/ContactsTableHead";
import { useSelector } from "react-redux";

const Table = (props: any) => {
  // const pagiData = props.data.data?.pagination;

  const pagiData = useSelector((state: any) => state.contact.getPaginationData);

  const startIndex =
    pagiData?.total_perpage * pagiData?.current_page -
    pagiData?.total_perpage +
    1;

  let endIndex = pagiData?.total_perpage * pagiData?.current_page;

  if (endIndex > pagiData?.total_records) {
    endIndex = pagiData?.total_records;
  }

  const showTotal: PaginationProps["showTotal"] = (total) =>
    `Showing ${startIndex || 0} to ${endIndex || 0} of ${total || 0} entries`; // Changes 24-03-2023

  return (
    <>
      <BsTable hover size="sm" responsive className="contact-table-md">
        <ContactsTableHead
          tableData={props.data.data?.data}
          page={props.page}
          perPage={props.perPage}
          setPerPage={props.setPerPage}
          setPage={props.setPage}
          searchVal={props.searchVal}
          isSearched={props.isSearched}
          setIsSearched={props.setIsSearched}
        />
        <ContactsTableBody data={props.data.data?.data} />
      </BsTable>
      <div className="table-footer">
        <Pagi
          className="contact-pagination-main"
          showQuickJumper
          showSizeChanger
          current={pagiData?.current_page}
          total={pagiData?.total_records}
          defaultPageSize={props.perPage}
          pageSizeOptions={["10", "20", "50", "100"]}
          pageSize={props.perPage}
          onChange={(currentPage: any, pageSize: any) => {
            props.setPage(currentPage);
            props.setPerPage(pageSize);
          }}
          showTotal={showTotal}
        />
      </div>
    </>
  );
};

export default Table;
