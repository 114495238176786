import React, { useEffect, useState } from "react";
import { Pagination as Pagi } from "antd";
import type { PaginationProps } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { changePaginationData, clientTableData, clientTableStates, getClientInfoData, getFilteredClients, refreshTable } from "../../redux/actions/client";
import Http from "../../services/http";


const Pagination = ({ paginationData, searchInput, searchField, setCustomPageSize, customPageSize }: any) => {

  const { clientTableStatesData, doClientMultiSearch } = useSelector((state: any) => state.client)

  useEffect(() => {
    setCustomPageSize(10)
  }, [doClientMultiSearch])

  const doMultiSearch = (currentPage: any, pageSize: any) => {

    const finalBody: any = {
      page: currentPage,
      per_page: pageSize,
    };

    Object.keys(clientTableStatesData.body).forEach((key) => {
      finalBody[key] = clientTableStatesData.body[key];
    });

    let searchedByOwnership: any = localStorage.getItem("isSearchedByOwnership");

    if (searchedByOwnership == "true") {
      searchedByOwnership = `multiple-search`;
    } else {
      searchedByOwnership = `multiple-search-client-owner`
    }


    if (clientTableStatesData?.isFilterOn?.length > 0) {
      Http.post(`users/${searchedByOwnership}`, finalBody, false).then((res: any) => {
        const { data } = res;

        dispatch(
          clientTableStates({
            ...clientTableStatesData,
            multileSearchTableData: data.data,
          })
        );

        dispatch(clientTableData(data.data))

        dispatch(changePaginationData(data.pagination))
        // dispatch(searchPagination({ success: true, count: data?.length }));
      });
    } else {
      dispatch(refreshTable())
    }

    // setIsThisModeVisible(!isThisModelVisible)
  };

  const startIndex =
    paginationData?.total_perpage * paginationData?.current_page -
    paginationData?.total_perpage +
    1;
  let endIndex = paginationData?.total_perpage * paginationData?.current_page;

  if (endIndex > paginationData?.total_records) {
    endIndex = paginationData?.total_records;
  }

  const showTotal: PaginationProps["showTotal"] = (total) =>
    `Showing ${startIndex} to ${endIndex} of ${total} entries`;
  const dispatch = useDispatch();
  // 

  return (
    <>
      <Pagi
        showQuickJumper
        showSizeChanger
        // pageSizeOptions={["1", "20", "50", "100"]}
        pageSize={customPageSize}
        onChange={(currentPage: any, pageSize: any) => {

          if (searchInput == "" && clientTableStatesData.isFilterOn.length == 0) {
            setCustomPageSize(pageSize)
            dispatch(getClientInfoData(pageSize, currentPage))
          } else if (clientTableStatesData?.isFilterOn?.length > 0) {
            setCustomPageSize(pageSize)
            doMultiSearch(currentPage, pageSize);
          } else {
            setCustomPageSize(pageSize)
            const body = {
              searchBy: searchField,
              searchRecord: searchInput,
              per_page: pageSize,
              page: currentPage
            }
            dispatch(getFilteredClients(body, false))
          }
        }
        }
        current={paginationData?.current_page}
        total={paginationData?.total_records}
        showTotal={showTotal}
      />
    </>
  );
};

export default Pagination;
