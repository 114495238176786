import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import moment from "moment";
import { DatePicker, Pagination } from "antd";
import { IoMdCloseCircle } from "react-icons/io";
const { RangePicker } = DatePicker;

function Totalnextfollowchart({totalFollow, setStartDate, setEndDate, startDate, endDate}:any) {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  const options: any = {
    animation: {
      duration: 10,
    },
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#0E4D92",
          font: {
            family: "Mulish",
            size: 13,
            weight: "600",
          },
        },
      },
      y:{
        beginAtZero: true,
        border: {
          display: false,
        },
        ticks: {
          stepSize:1,
          color: "#0E4D92",
          font: {
            family: "Mulish",
            size: 13,
            weight: "600",
          },
        },
      }
    },
  };

  const labels:any = totalFollow?.data?.map((e:any) =>e?.name);
  const countData:any = totalFollow?.data?.map((e:any) => e?.notesCount?.length)
  const countData1:any = totalFollow?.data?.map((e:any) => e?.markDoneCount?.length)
  const countData2:any = totalFollow?.data?.map((e:any) => e?.markNotDoneCount?.length)


  const data = {
    labels,
    datasets: [
      {
        fill: true,
        label: "Total",
        data: countData,
        borderColor: "#146FC8",
        // backgroundColor: "rgba(26, 111, 196, 0.5)",
      },
      {
        fill: true,
        label: "Read",
        data: countData1,
        borderColor: "red",
        // backgroundColor: "rgba(26, 111, 196, 0.5)",
      },
      {
        fill: true,
        label: "Non Read",
        data: countData2,
        borderColor: "green",
        // backgroundColor: "rgba(26, 111, 196, 0.5)",
      },
    ],
  };

  const dateFun = (e:any, ele:any) =>{
    setStartDate(ele[0]);
    setEndDate(ele[1]);
  }

  return (
    <div className="animate__animated animate__flipInY" style={{ padding: "0px 20px 15px 20px" }}>
      <div
        style={{
          textAlign: "end",
          padding: "0px 5px 0px 0px",
          marginTop: "3px",
        }}
      >
        <div className="forCrossIcon" style={{ position: "relative" }}>
          <RangePicker
            className="pagi-datepicker"
            ranges={{
              Today: [moment(), moment()],
            }}
            onChange={dateFun}
          />
          {startDate !== "" || endDate !== "" ? (
            <IoMdCloseCircle
              style={{
                pointerEvents: "none",
                color: "#939393",
                fontSize: "18px",
                position: "absolute",
                top: "7px",
                right: "7px",
                cursor: "pointer",
              }}
            />
          ) : null}
        </div>
      </div>
      <Line options={options} data={data} style={{ minHeight: "282px", minWidth: "565px" }} />
    </div>
  );
}

export default Totalnextfollowchart;
