import {
  getAllContactsList,
  getSearchedContactsList,
} from "../../services/contacts";

export enum ContactType {
  GET_ALL_CONTACT = "GET_ALL_CONTACT",
  SET_MUlTI_SEARCH_DATA = "SET_MUlTI_SEARCH_DATA",
  SET_PAGINATION = "SET_PAGINATION",
  CONTACT_REFRESH = "CONTACT_REFRESH",
  SEARCH_CONTACTS = "SEARCH_CONTACTS",
  CONTACT_TABLE_SEARCH_DATA = "CONTACT_TABLE_SEARCH_DATA",
  Is_MultiSearch = "Is_MultiSearch",
  CLEAR_CONTACT_REFRESH_SEARCH = "CLEAR_CONTACT_REFRESH_SEARCH",
  SET_IS_FILTER_ON = "SET_IS_FILTER_ON",
  SET_PAGE_SIZE = "SET_PAGE_SIZE",
  CONTACT_IS_CHECKED ="CONTACT_IS_CHECKED",
  CHECK_SELECT_ALL = "CHECK_SELECT_ALL"
}

export const getAllContacts =
  (page: any, perPage: any) => async (dispatch: any) => {
    const data: any = await getAllContactsList(page, perPage);
    dispatch(setPagination(data.data.pagination));

    dispatch({
      type: ContactType.GET_ALL_CONTACT,
      data: data,
    });
  };

export const searchContacts = (body: any) => async (dispatch: any) => {
  const { data }: any = await getSearchedContactsList(body);

  dispatch(setPagination(data.pagination));
  dispatch(clearContactMultipleSearch());
  // 

  dispatch({
    type: ContactType.SEARCH_CONTACTS,
    data: data.data,
  });
};

export const setMultiSearchData = (data: any) => async (dispatch: any) => {
  // const data = await getSearchedContactsList(searchBy, searchval);

  dispatch({
    type: ContactType.SEARCH_CONTACTS,
    data,
  });
};

export const setMultiSearch = (data: any) => async (dispatch: any) => {
  // const data = await getSearchedContactsList(searchBy, searchval);

  dispatch({
    type: ContactType.Is_MultiSearch,
    data,
  });
};

export const setPagination = (data: any) => async (dispatch: any) => {
  // const pagiData = await getAllContactsList();
  dispatch(ContactIsChecked([]))
  dispatch({
    type: ContactType.SET_PAGINATION,
    data,
  });
};

export const setContacTableSearchData =
  (data: any) => async (dispatch: any) => {
    // const pagiData = await getAllContactsList();

    dispatch({
      type: ContactType.CONTACT_TABLE_SEARCH_DATA,
      data,
    });
  };


export const setIsFilterData = (data: any) => (dispatch: any) => {


  dispatch({
    type: ContactType.SET_IS_FILTER_ON,
    data: data
  });
};
export const contactRefresh = () => (dispatch: any) => {

  dispatch({
    type: ContactType.CONTACT_REFRESH,
  });
};

export const getContactData = (data: any) => (dispatch: any) => {
  dispatch({
    type: ContactType.GET_ALL_CONTACT,
    data: data,
  });
};

export const clearContactMultipleSearch = () => (dispatch: any) => {
  dispatch({
    type: ContactType.CLEAR_CONTACT_REFRESH_SEARCH,
  });
};


export const setPageSize = (data: any) => (dispatch: any) => {

  
  dispatch({
    type: ContactType.SET_PAGE_SIZE,
    data
  })
}

export const doContactMasterReset = () => async (dispatch: any) => {
  // const result: any = await getFilteredClientList(data, queryParam);


  dispatch({
    type: ContactType.CLEAR_CONTACT_REFRESH_SEARCH
  });
};

export const ContactIsChecked = (data:any) => async (dispatch :any) => {

  dispatch({
    type: ContactType.CONTACT_IS_CHECKED,
    data
  })
}

export const checkSelectAll = (data:any) => async (dispatch:any) => {
  dispatch({
    type: ContactType.CHECK_SELECT_ALL,
    data
  })
}
