import { combineReducers } from "redux";
import clientReducer from "./client";
import contactReducer from "./contacts";
import userReducer from "./user";
import loginReducer from "./login";
import report from "./report";

export default combineReducers({
	client: clientReducer,
	contact: contactReducer,
	user: userReducer,
	login: loginReducer,
	report: report,
});