import { Modal } from "antd";
import React, { useState } from "react";
import { TbFileDatabase } from "react-icons/tb";
import { Table } from "react-bootstrap";
import { getContactColumnData } from "../../../services/contacts";

const ShowReportingManager = ({ item }: any) => {
  const [hyperModal, setHyperModal] = useState(false);

  const hyperClick = () => {
    setHyperModal(true);
  };




  return (
    <>
      <span
        style={{ cursor: "pointer", opacity: item?.iconDisplay && item?.iconNumber === item?.index ? 1 : 0 }}
        onClick={() => {
          hyperClick();
        }}
      >
        {<TbFileDatabase style={{ marginBottom: "2px" }} />}
      </span>
      <Modal
        destroyOnClose
        title={`${item?.item?.first_name} Name's Details`}
        className="importClient"
        // style={{ width: 1000 }}
        visible={hyperModal}
        onOk={() => setHyperModal(false)}
        onCancel={() => setHyperModal(false)}
      >
        <Table striped="columns" className="preview-table">
          <thead>
            <tr>
              <th>No</th>
              <th>Accessor</th>
              <th>Data</th>
            </tr>
          </thead>
          <tbody>
            {getContactColumnData().map((e: any, index: any) => {


              if (e.accessor === "action" || e.accessor === "ReportingManagerData" || e.accessor === "ContactcreatUser" || e.accessor === "ContactownerUser" || e.accessor === "clientName" || e.accessor === "contactNotes") {
                return;
              }
              return (
                <tr>
                  <td>{`${index + 1})`}</td>
                  <td  className="preview-header"> <b>{e.header}</b></td>
                  <td>{item?.item[e?.accessor] || "--"}</td>
                </tr>
              );
              // }
            })}
          </tbody>
        </Table>
      </Modal>
    </>
  );
};

export default ShowReportingManager;
