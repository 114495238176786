import { ClientListModel } from '../models/index';
import { ClientType } from '../actions/client';

/* eslint-disable */
let user: any = localStorage.getItem("user");
user = user ? JSON.parse(user) : null;

export const initState: ClientListModel = {
    isLoading: true,
    isUpdating: false,
    isCreating: false,
    selectedClient: undefined,
    count: 0,
    list: [],
    // isShopLoaded: false,
    isListLoaded: false,
    clientTableData: [],
    pagiDetails: {
        currentPage: 1,
        pageSize: 10
    },
    paginationData: {},
    searchPagination: {},
    clientTableStatesData: {
        body: {
            id: [],
            client_name: [],
            website_url: [],
            industry: [],
            category: [],
            country: [],
            state: [],
            city: [],
            payment_terms: [],
            notes: [],
            createdAt: [],
            updatedAt: [],
            ClientownersUser: [], // Ask to Akram
            createUser: [],
            client_status: [] // Ask to Akram
            // selectCountry: [],
            // selectCategory: [],
            // selectIndustry: [],
            // selectState: [],
            // selectCity: [],
            // paymentTerms: [],
            // notes: [],
        },
        filterSearchResult: [],
        multileSearchTableData: [],
        isFilterOn: [],
    },
    refreshTable: true,
    hiddenTableColumns: [],
    doClientMultiSearch: false,
    searchByOwmership: false,
    masterReset: false,
}

export default (state = initState, action: any) => {

    switch (action.type) {

        case ClientType.FETCH_APPLICANT_LISTING: {
            return {
                ...state,
                isLoading: true,
                selectedClient: undefined
            }
        }
        case ClientType.APPLICANT_LIST_SUCCESS: {
            return {
                ...state,
                list: action.data.data,
                count: action.data.count,
                isLoading: false,
                isListLoaded: true,
                selectedClient: undefined
            }
        }
        case ClientType.APPLICANT_LIST_FAILED: {
            return {
                ...state,
                list: [],
                count: 0,
                isLoading: false,
                selectedClient: undefined
            }
        }

        case ClientType.CREATE_APPLICANT: {
            return {
                ...state,
                isCreating: true,
                selectedClient: undefined,
                msg: null
            }
        }

        case ClientType.CREATE_APPLICANT_SUCCESS: {
            return {
                ...state,
                msg: 'CREATE_APPLICANT_SUCCESS',
                isCreating: false,
                selectedClient: undefined
            }
        }

        case ClientType.CREATE_APPLICANT_FAILED: {
            return {
                ...state,
                isCreating: false,
                selectedClient: undefined,
                msg: action.data
            }
        }

        case ClientType.CLEAR_APPLICANT_LIST_MSG: {
            return {
                ...state,
                isListLoaded: false,
                msg: undefined,
                list: [],
                isLoading: true
            }
        }

        case ClientType.DELETE_APPLICANT_SUCCESS: {
            const { list } = state;
            // 
            const index = list.findIndex((x) => x.id === action.id);
            // 
            // 
            if (index > -1) {
                // 
                list.splice(index, 1);
            }
            // 
            return {
                ...state,
                list: [...list]
            }
        }

        case ClientType.FETCHING_APPLICANT_INFO: {
            return {
                ...state,
                isLoading: true
            }
        }
        case ClientType.FETCH_APPLICANT_INFO_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                selectedBanner: action.data
            }
        }
        case ClientType.FETCH_APPLICANT_INFO_FAILED: {
            return {
                ...state,
                isLoading: false,
                selectedBanner: undefined
            }
        }

        case ClientType.START_UPDATE_APPLICANT: {
            return {
                ...state,
                msg: null,
                isUpdating: true
            };
        }

        case ClientType.UPDATE_APPLICANT_SUCCESS: {
            return {
                ...state,
                msg: "BANNER_UPDATE_SUCCESS",
                isUpdating: false,
                selectedShop: null
            }
        }

        case ClientType.PAGINATION_DATA: {

            // 

            return {
                ...state,
                paginationData: action.data
            }
        }

        case ClientType.CLIENT_TABLE_DATA: {

            // if(state.clientTableStatesData.multileSearchTableData)

            return {
                ...state,
                clientTableData: action.data
            }
        }
        // case ClientType.CHANGE_PAGINATION_DATA:{

        //     return{
        //         ...state,
        //         pagiDetails : action.data
        //     }
        // }

        case ClientType.SEARCH_PAGINATION: {

            return {
                ...state,
                searchPagination: action.data
            }
        }

        case ClientType.CLIENT_TABLE_STATES: {

            // 


            return {
                ...state,
                clientTableStatesData: action.data
            }
        }

        case ClientType.HIDDEN_TABLE_COLUMNS: {

            // 


            return {
                ...state,
                hiddenTableColumns: action.data
            }
        }
        case ClientType.REFRESH_TABLE: {
            return {
                ...state,
                refreshTable: !state.refreshTable
            }
        }
        case ClientType.DO_CLIENT_MULTI_SEARCH: {
            return {
                ...state,
                doClientMultiSearch: !state.doClientMultiSearch,
            };
        }

        case ClientType.SEARCH_BY_OWNERSHIP: {
            return {
                ...state,
                searchByOwmership: !state.searchByOwmership,
            };
        }

        case ClientType.CLEAR_CLIENT_MULTI_SEARCH: {
            return {
                ...state,
                clientTableStatesData: {
                    body: {
                        id: [],
                        client_name: [],
                        website_url: [],
                        industry: [],
                        category: [],
                        country: [],
                        ClientownersUser: [],
                        createUser: [],
                        state: [],
                        city: [],
                        payment_terms: [],
                        client_status: [],
                        notes: [],
                        createdAt: [],
                        updatedAt: [],
                    },
                    filterSearchResult: [],
                    multileSearchTableData: [],
                    isFilterOn: [],
                },
            };
        }

        case ClientType.MASTER_RESET: {
            return {
                ...state,
                masterReset: !state.masterReset
            }
        }

        default:
            return state
    }
}