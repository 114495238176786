import React from "react";
import { DatePicker, Pagination, Modal } from "antd";
import Table from "react-bootstrap/Table";

const AttampTableNote = ({
  entireData,
  isTableModel,
  setIsTableModel,
}: any) => {
  console.log("RRRRRR", entireData);
  return (
    <>
      <Modal
        className="contact-modal-div note-modal"
        destroyOnClose
        title="All Attempt Notes"
        visible={isTableModel}
        onCancel={() => setIsTableModel(false)}
      >
        <div className="note-div">
          <Table striped="columns">
            <thead>
              <tr style={{ whiteSpace: "nowrap" }}>
                <th>Id</th>
                <th>Notes</th>
                <th>Last Follow-up date</th>
                <th>Next Follow-up date</th>
                <th>Labels</th>
                <th>TimeZone</th>
                <th>Created On</th>
                <th>Updated At</th>
                <th>Created By</th>
                <th>Updated By</th>
              </tr>
            </thead>
            <tbody>
              {entireData?.map((e: any, index: any) => {
                index += 1;
                return (
                  <>
                    <tr key={index} style={{ whiteSpace: "nowrap" }} className="tr-for-attempNotes">
                      <td>
                        {index}
                      </td>
                      <td>{e?.notes ? e?.notes : "--"}</td>
                      <td>
                        {e?.last_follow_up_date ? e?.last_follow_up_date : "--"}
                      </td>
                      <td>
                        {e?.next_follow_up_date ? e?.next_follow_up_date : "--"}
                      </td>
                      <td className="att-lebels">{e?.noteLables ? e?.noteLables : "--"}</td>
                      <td>{e?.timezone ? e?.timezone : "--"}</td>
                      <td>{e?.createdAt ? e?.createdAt : "-"}</td>
                      <td>{e?.updatedAt ? e?.updatedAt : "--"}</td>
                      <td>
                        {e?.notesCreatedUser?.name
                          ? e?.notesCreatedUser?.name
                          : "--"}
                      </td>
                      <td>
                        {e?.notesUpdatedUser?.name ? e?.notesUpdatedUser?.name : "--"}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </Modal>
    </>
  );
};

export default AttampTableNote;
