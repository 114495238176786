import { useDispatch } from "react-redux";
import { contactRefresh } from "../redux/actions/contacts";
import Http from "./http";

export const getAllContactsList = async (page: any, perPage: any) => {
  const isowner: any = localStorage.getItem("dataByOwnership");
  const bool = JSON.parse(isowner);
  if (bool.OwnerUser) {
    return await Http.get(
      `users/contactlist?per_page=${perPage}&page=${page}`,
      false
    );
  } else {
    return await Http.get(
      `users/contact_listby_user?per_page=${perPage}&page=${page}`,
      false
    );
  }
};

export const getSearchedContactsList = async (body: any) => {
  const isowner: any = localStorage.getItem("dataByOwnership");
  const bool = JSON.parse(isowner);
  if (!bool.OwnerUser) {
    return await Http.post(`users/contact-list-by-owner-user`, body, false);
  } else
    return await Http.post(`users/serchcontact`, body, false);
};

export const saveUserSetting = async (name: any, value: any) => {

  localStorage.setItem(name, JSON.stringify(value))

  const body = {
    setting_name: name,
    setting_data: value,
  };

  // localStorage.setItem(name, JSON.stringify(value));
  return await Http.post(`users/user_settings`, body, false);
};

export const getUserSetting = async (name: any, value: any) => {
  const body = {
    setting_name: name,
    setting_data: JSON.stringify(value),
  };
  return await Http.post(`users/user_settings`, body, false);
};

export const getContactColumnData = () => {
  return JSON.parse(localStorage.getItem("UserContactColumn") || "{}");
};

export const addContactNewNotes = (contact_id: any, notes: any, next_follow_up_date: any, time: any, timezone: any, noteLables: any, standard_comment: any, noteSource:any) => {
  return Http.post(`users/add-contact-notes`, { contact_id, notes, next_follow_up_date, time, timezone, noteLables: noteLables?.toString(), standard_comment, noteSource }, false);
};

export const updateContactNewNotes = (note_id: any, notes: any, next_follow_up_date: any, time: any, timezone: any, noteLables: any, standard_comment: any, noteSource:any) => {
  return Http.put(`users/contact-notes-update/${note_id}
  `, { notes, next_follow_up_date, noteSource, time, timezone, noteLables: noteLables?.toString(), standard_comment }, false);
};