import { Button, Form, Input, Modal } from "antd";
import React, { useState } from "react";
import Http from "../../../services/http";

const UserPasswordModal = (props: any) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const passwordObject = { password: password };
  const handleOk = () => {
    props.setPasswordModal(false);
    setPassword("")
    setConfirmPassword("")
  };

  const handleCancel = () => {
    props.setPasswordModal(false);
    setPassword("")
    setConfirmPassword("")
  };

  const userPasswordSubmit = async () => {

    if (password === "") {
      alert("Password not valid");
    } else {
      if (password === confirmPassword) {
        Http.put(
          `user/userUpdatePassword/${props.changeUserPassword}`,
          passwordObject,
          true
        )
          .then((res: any) => {
            console.log(res);

            if (res.success) {
              props.setPasswordModal(false);
            }
          })
          .catch((err) => {
            //
          });
      } else {
        alert("Password is not same");
      }
    }

  };

  return (
    <div className="user-modal-div">
      <Modal
        className="changePassword"
        title={`Change Password Of (${props?.userName})`} // Changes // 23-03-2023
        visible={props.passwordModal}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
      >
        <div className="user-modal-div-form">
          <Form onFinish={userPasswordSubmit}>
            <div className="user-form">
              <div className="form-row-user">
                <div className="user-row-div">
                  <Form.Item label="Password" required>
                    <Input
                      className="input-field"
                      type="password"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Item>
                </div>
                <div className="user-row-div">
                  <Form.Item label="Confirm Password" required>
                    <Input
                      className="input-field"
                      type="password"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="form-row-user">
                <div className="form-row-user user-btn-group">
                  <Form.Item>
                    <Button className="global-antd-btn" htmlType="submit">
                      Submit
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export default UserPasswordModal;
