import React from "react";
import { Switch, Link, Redirect } from "react-router-dom";
import { Layout, Menu } from "antd";
// const SubMenu =  Menu.SubMenu;

import PublicRoute from "./PublicRoute";
import ProtectedRoute from "./protectedRoute";
import AuthRoute from "./authRoute";

import HeaderView from "../components/sidebar/index";
import "./style.css";
import Login from "../pages/auth/login";
import Dashboard from "../pages/dashboard";
import Users from "../pages/Users";
import Report from "../pages/Report/Report";
import Sidebar from "../components/sidebar/index";
import Client from "../pages/client";
import Forgot from "../pages/auth/forgot";
import ResetPassword from "../pages/auth/resetPassword";
import Import from "../components/Import";
import Contacts from "../pages/contacts/Contacts";
import { useSelector } from "react-redux";
// import Logout from "../pages/Logout/Logout";

const { Content, Sider } = Layout;

function Routing(props: any) {
  const userData = JSON.parse(localStorage.getItem("user")!);

  //
  return (
    <React.Fragment>
      <Switch>
        <React.Fragment>
          <AuthRoute path="/" user={props.user} component={Login} />
          <AuthRoute path="/login" user={props.user} component={Login} />
          {props.user ? <Sidebar /> : ""}
          {/* ALL PRIVATE ROUTE WILL SHOW HERE */}
          <ProtectedRoute
            path="/dashboard"
            user={props.user}
            component={Dashboard}
          />
          <ProtectedRoute path="/client" user={props.user} component={Client} />
          <ProtectedRoute
            path="/contacts"
            user={props.user}
            component={Contacts}
          />
          <ProtectedRoute
            path="/client/import"
            user={props.user}
            component={Import}
          />
          <ProtectedRoute path="/users" user={props.user} component={Users} />
          <ProtectedRoute
            path="/report"
            user={props.user}
            component={
              userData?.role == "0"  ? Report : Dashboard
            }
          />
          {/* <ProtectedRoute path="/logout" user={props.user} component={Logout} /> */}
          <PublicRoute path="/forgot-password" component={Forgot} />
          <PublicRoute path="/reset-password" component={ResetPassword} />
          {/* </Content> */}
          {/* </Layout> */}
          {/* </Layout> */}
          {/* </Layout> */}
        </React.Fragment>
      </Switch>
    </React.Fragment>
  );
}

export default Routing;
