import { LoginType } from '../actions/login';
import { LoginModel } from '../models/index';

export const initState: LoginModel = {
	isLoading: false,
	error: '',
	msg: '',
	data: null,
	loginMessage: false
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initState, action: any) => {
	switch (action.type) {
		case LoginType.START_USER_LOGIN: {
			return {
				...state,
				isLoading: true
			}
		}
		case LoginType.START_FORGOT_PASSWORD: {
			return {
				...state,
				isLoading: true
			}
		}
		case LoginType.USER_LOGIN_SUCCESS: {
			return {
				...state,
				isLoading: false,
				data: action.data
			}
		}
		case LoginType.FORGOT_PASSWORD_SUCCESS: {
			return {
				...state,
				isLoading: false,
				data: action.data
			}
		}
		case LoginType.USER_LOGIN_FAILED: {
	
			return {
				...state,
				isLoading: false,
				msg: action.message
			}
		}
		case LoginType.FORGOT_PASSWORD_FAILED: {
			return {
				...state,
				isLoading: false,
				msg: action.message
			}
		}
		
		// eslint-disable-next-line no-duplicate-case
		case LoginType.LOGIN_NOTIFICATION: {
			
			return {
				...state,
				loginMessage: true,
			}
		}


		default:
			return state
	}
}