import "animate.css";
import React, { useEffect, useState } from "react";
import { IoMdCloseCircle, IoMdCloseCircleOutline } from "react-icons/io";
import { AiOutlineSearch, AiOutlineSortAscending, AiOutlineSortDescending } from "react-icons/ai";
import { BsFillGridFill, BsGraphUp } from "react-icons/bs";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { useTable } from "react-table";
import { DatePicker, Pagination } from "antd";
import Userwisechart from "./Charts/Userwisechart";
import { useDispatch, useSelector } from "react-redux";
import Http from "./../../../services/http";
import { getUserWiseData } from "../../../redux/actions/report";
import moment from "moment";
import { IoCloseCircleOutline } from "react-icons/io5";
import Loader from "./Charts/Loader/Loader";
import { LoadingOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;

const columns: any = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Total Notes",
    accessor: "notesCount",
  },
];

function Userwise() {
  const [gridList, setGridList] = useState(true);
  const [page, setPage]: any = useState(1);
  const [perPage, setPerPage]: any = useState(10);
  const [notesCountData, setNotesCountData] = useState([]);
  const [tableData, setTableData] = useState(false);
  const [sortOrder, setOrder] = useState(true);
  const [sortField, setSortField] = useState("")
  const [userName, setUserName] = useState("");
  const [datePicker, setDatePicker]: any = useState(["", ""]);
  // const [record, setRecord]: any = useState(false);
  const dispatch: any = useDispatch();
  const userData: any = useSelector((state: any) => state.report.userData);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data: userData?.data || [] });

  const ObjOfUser: any = {
    createdAt: datePicker,
    userName: userName,
    page: page,
    perPage: perPage,
    sortField: sortField,
    sortOrder: sortOrder ? "DESC" : "ASC"
  };

  useEffect(() => {
    dispatch(getUserWiseData(ObjOfUser));
  }, [page, userName, datePicker, sortField, sortOrder]);

  const dateFun = (e: any, ele: any) => {
    setDatePicker(ele);
  };

  useEffect(() => {
    if (userData?.data?.length !== 0) {
      setTableData(true);
    }
  }, []);

  return (
    <>
      <div className="col">
        <div className="upper-bar">
          <div></div>
          <p className="title">Userwise Notes</p>
          {gridList ? (
            <BsGraphUp
              className="grid-icon-user"
              onClick={() => setGridList(false)}
            />
          ) : (
            <AiOutlineUnorderedList
              className="grid-icon-user"
              onClick={() => setGridList(true)}
            />
          )}
        </div>
        {gridList ? (
          <div className="all-table animate__animated animate__flipInY">
            <div className="top-bar-userwise">
              <div className="search-bar-user-wise">
                <AiOutlineSearch
                  className="search-icon-user-wise"
                  style={{
                    fontSize: "20px",
                    color: "#0E4D92",
                    backgroundColor: "#F0F0F0",
                    height: "100%",
                    marginLeft: "10px",
                  }}
                />
                <input
                  type="text"
                  className="search-wise-user"
                  placeholder="Search..."
                  value={userName}
                  onChange={(e: any) => setUserName(e.target.value)}
                />
              </div>
              <div className="forCrossIcon" style={{ position: "relative" }}>
                <RangePicker
                  className="pagi-datepicker"
                  ranges={{
                    Today: [moment(), moment()],
                  }}
                  onChange={dateFun}
                />
                {datePicker[0] !== "" || datePicker[1] !== "" ? (
                  <IoMdCloseCircle
                    style={{
                      pointerEvents: "none",
                      color: "#939393",
                      fontSize: "18px",
                      position: "absolute",
                      top: "7px",
                      right: "7px",
                      cursor: "pointer",
                    }}
                  />
                ) : null}
              </div>
            </div>
            <div className="table-of-userwise">
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => {
                        return (
                          <>
                            <th {...column.getHeaderProps()}>
                              {column.id !== "notesCount" ? (
                                <>
                                  {sortOrder && sortField === column.id ? (
                                    <AiOutlineSortDescending
                                      style={{ cursor: "pointer" }}
                                      onClick={() => { setOrder(!sortOrder); setSortField(column.id) }}
                                    />
                                  ) : (
                                    <AiOutlineSortAscending
                                      style={{ cursor: "pointer" }}
                                      onClick={() => { setOrder(!sortOrder); setSortField(column.id) }}
                                    />
                                  )}
                                </>
                              ) : null}

                              {column.render("Header")}
                            </th>
                          </>
                        );
                      })}
                    </tr>
                  ))}
                </thead>
                {tableData ? (
                  <tbody {...getTableBodyProps()}>
                    <>
                      {true
                        ? rows.map((row) => {
                          prepareRow(row);
                          return (
                            <>
                              <tr {...row.getRowProps()}>
                                {row.cells.map((cell) => {
                                  return (
                                    <td {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  );
                                })}
                              </tr>
                            </>
                          );
                        })
                        : "No Record Found"}
                    </>
                  </tbody>
                ) : (
                  <table>
                    <tbody>
                      <tr
                        style={{
                          borderBottom: "none",
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <LoadingOutlined
                          style={{
                            fontSize: 35,
                            color: "#0E4D92",
                          }}
                          spin
                        />
                      </tr>
                    </tbody>
                  </table>
                )}
              </table>
            </div>
            <div className="footer-bar-userwise">
              <Pagination
                className="pagi-user-wise"
                current={userData?.pagination?.current_page}
                total={userData?.pagination?.total_records}
                onChange={(e: any) => setPage(e)}
              // showSizeChanger
              // onShowSizeChange={onShowSizeChange}
              // style={{zIndex:"1000"}}
              />
            </div>
          </div>
        ) : (
          <Userwisechart
            userData={userData}
            setDatePicker={setDatePicker}
            datePicker={datePicker}
          />
        )}
      </div>
    </>
  );
}

export default Userwise;
