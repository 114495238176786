import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { clientTableData, clientTableStates } from "../../../redux/actions/client";
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";

const SortComponent = ({ sortData, data, clientTableStatesData }: any) => {
  const dispatch = useDispatch();
  const [sortOrder, setSortOrder] = useState("asc");

  // 


  const sortColumn = (item: any) => {
    if (clientTableStatesData?.multileSearchTableData?.length === 0) {
      const Data = data;
      if (
        item === "ClientownersUser" ||
        item === "createUser"
      ) {
        if (sortOrder === "asc") {
          const sortedData = [...Data]?.sort((a, b) =>
            a?.[item]?.name?.toLowerCase() > b?.[item]?.name?.toLowerCase() ? 1 : -1
          );
          dispatch(clientTableData(sortedData));
          setSortOrder("desc");
        } else {
          const sortedData = [...Data]?.sort((a, b) =>
            a?.[item]?.name.toLowerCase() > b?.[item]?.name.toLowerCase() ? 1 : -1
          );
          dispatch(clientTableData(sortedData));
          setSortOrder("asc");
        }
      }
      if (item === "id") {
        if (sortOrder === "asc") {
          const sortdata = [...Data].sort(
            (a: any, b: any) => a?.[item] - b?.[item]
          );
          dispatch(clientTableData(sortdata));
          setSortOrder("desc");
        } else {
          const sortdata = [...Data].sort(
            (a: any, b: any) => b?.[item] - a?.[item]
          );
          dispatch(clientTableData(sortdata));
          setSortOrder("asc");
        }
      } else {

        if (
          item === "ClientownersUser" ||
          item === "createUser"
        ) {
          if (sortOrder === "asc") {
            const sortdata = [...Data].sort((a, b) =>
              a?.[item]?.name?.toLowerCase() > b?.[item]?.name?.toLowerCase() ? 1 : -1
            );
            dispatch(clientTableData(sortdata));
            setSortOrder("desc");
          } else {
            const sortdata = [...Data].sort((a, b) =>
              b?.[item]?.name?.toLowerCase() > a?.[item]?.name?.toLowerCase() ? 1 : -1
            );
            dispatch(clientTableData(sortdata));
            setSortOrder("asc");
          }
        } else {

          if (sortOrder === "asc") {
            const sortdata = [...Data].sort((a, b) =>
              a?.[item]?.toLowerCase() > b?.[item]?.toLowerCase() ? 1 : -1
            );
            dispatch(clientTableData(sortdata));
            setSortOrder("desc");
          } else {
            const sortdata = [...Data].sort((a, b) =>
              b?.[item]?.toLowerCase() > a?.[item]?.toLowerCase() ? 1 : -1
            );
            dispatch(clientTableData(sortdata));
            setSortOrder("asc");
          }
        }

      }
    } else {
      const Data = clientTableStatesData?.multileSearchTableData;
      

      if (item === "id") {
        if (sortOrder === "asc") {
          const sortdata = [...Data].sort(
            (a: any, b: any) => a?.[item] - b?.[item]
          );

          dispatch(clientTableData(sortdata));
          setSortOrder("desc");
        } else {
          const sortdata = [...Data].sort(
            (a: any, b: any) => b?.[item] - a?.[item]
          );
          dispatch(clientTableData(sortdata));
          setSortOrder("asc");
        }
      } else {
        if (sortOrder === "asc") {
          const sortdata = [...Data].sort((a, b) =>
            a?.[item]?.toLowerCase() > b?.[item]?.toLowerCase() ? 1 : -1
          );
          dispatch(clientTableData(sortdata));
          setSortOrder("desc");
        } else {
          const sortdata = [...Data].sort((a, b) =>
            b?.[item]?.toLowerCase() > a?.[item]?.toLowerCase() ? 1 : -1
          );
          dispatch(clientTableData(sortdata));
          setSortOrder("asc");
        }
      }
    }
  };

  return (
    <>
      {sortOrder === "asc" ? (
        <SortAscendingOutlined
          style={{
            fontSize: "15px",
            marginRight: "5px",
            position: "relative",
            top: "-2px",
          }}
          onClick={() => sortColumn(sortData)}
        />
      ) : (
        <SortDescendingOutlined
          style={{
            fontSize: "15px",
            marginRight: "5px",
            position: "relative",
            top: "-2px",
          }}
          onClick={() => sortColumn(sortData)}
        />
      )}
    </>
  );
};

export default SortComponent;
